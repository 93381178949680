<template>
  <div class="bg-grey">
    <div class="row">
      <div class="col-12">
        <h2 class="pb-4 grey">Patient Information</h2>
        <div style="max-width:600px;margin-right:auto">
          <b-row class="py-2 align-items-center">
            <b-col sm="4">
              <label>First Name:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                type="text"
                placeholder="Enter First Name"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="py-2 align-items-center">
            <b-col sm="4">
              <label>Last Name:</label>
            </b-col>
            <b-col sm="8">
              <b-form-input
                type="text"
                placeholder="Enter Last Name"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="py-2 align-items-center">
            <b-col sm="4">
              <label>Age:</label>
            </b-col>
            <b-col sm="4">
              <b-form-input
                type="number"
                placeholder="Enter Age"
              ></b-form-input>
            </b-col>
          </b-row>
          <b-row class="py-2 align-items-center">
            <b-col sm="4">
              <label>DOB:</label>
            </b-col>
            <b-col sm="4">
              <b-form-datepicker
                type="text"
                placeholder="Select Date"
              ></b-form-datepicker>
            </b-col>
          </b-row>
          <b-row class="py-2 align-items-center">
            <b-col sm="4">
              <label>Gender:</label>
            </b-col>
            <b-col sm="4">
              <select class="about-select form-control">
                <option value="">Gender</option>
                <option value="">Male</option>
                <option value="">Female</option>
                <option value="">Any</option>
              </select>
            </b-col>
          </b-row>
          <b-row class="py-2 align-items-center">
            <b-col sm="4">
              <label>Zip Code:</label>
            </b-col>
            <b-col sm="4">
              <b-form-input
                type="number"
                placeholder="Enter a Zip Code"
              ></b-form-input>
            </b-col>
          </b-row>
        </div>
        <div class="row">
          <div class="col-12 pt-4 d-flex justify-content-end">
            <button class="moreInfo-btn mr-2">
              Cancel
            </button>
            <router-link to="/step-1"
              ><button class="moreInfo-btn">Next</button></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PatientsInfo",
};
</script>
