<template>
	<div class="col-12 mt-2">
		<b-button v-b-toggle.collapse-11 class="accordion-btn listing-btn"
			>More Information <i class="fa fa-chevron-down ml-3"></i
		></b-button>
		<b-collapse id="collapse-11" class="mt-3">
			<b-card>
				<table class="table table-striped">
					<tbody>
						<!-- <tr v-if="data.responsible_parties.length > 0 && data.responsible_parties[0].name_title != '' ">
							<td class="table-data-heading">
								Responsible Party:
							</td>
							<td class="table-data">
								{{ data.responsible_parties[0].name_title }}
							</td>
						</tr> -->
						<tr v-if="data.nct_id != '' ">
							<td class="table-data-heading">ClinicalTrials.gov Identifier:</td>
							<td class="table-data">
								{{ data.nct_id }}
							</td>
						</tr>
						<tr v-if="data.itn_id != null ">
							<td class="table-data-heading">ClinicalTrials ITN Identifier:</td>
							<td class="table-data">
								{{ data.itn_id }}
							</td>
						</tr>
						<tr v-if="data.org_study_id != '' ">
							<td class="table-data-heading">Other Study ID Numbers:</td>
							<td class="table-data">
								{{ data.org_study_id }}
							</td>
						</tr>
						<tr v-if="data.study_first_posted != '' ">
							<td class="table-data-heading">
								First Posted:
							</td>
							<td class="table-data">
								{{ data.study_first_posted }}
							</td>
						</tr>
						<tr v-if="data.last_update_posted != '' ">
							<td class="table-data-heading">
								Last Update Posted:
							</td>
							<td class="table-data">
								{{ data.last_update_posted }}
							</td>
						</tr>
						<tr v-if="data.verification_date != '' ">
							<td class="table-data-heading">Last Verified:</td>
							<td class="table-data">
								{{ data.verification_date }}
							</td>
						</tr>
						<!-- <tr>
							<td class="table-data-heading">Plan to Share IPD:</td>
							<td class="table-data">
								No
							</td>
						</tr>
						<tr>
							<td class="table-data-heading">
								Plan Description:
							</td>
							<td class="table-data">
								The only clinical data available to researchers will be
								anonymised. Permission will be sought at consent to retain data
								sets for up to 15 years at the end of the study to use for this
								study or other ethically approved studies.
							</td>
						</tr> -->
						<tr v-if="data.oversight_infos.length > 0 && data.oversight_infos[0].is_fda_regulated_drug != '' ">
							<td class="table-data-heading">
								Studies a U.S. FDA-regulated Drug Product:
							</td>
							<td class="table-data" v-if="data.oversight_infos.length > 0">
								{{ data.oversight_infos[0].is_fda_regulated_drug }}
							</td>
						</tr>
						<tr v-if="data.oversight_infos.length > 0 && data.oversight_infos[0].is_fda_regulated_device != '' ">
							<td class="table-data-heading">
								Studies a U.S. FDA-regulated Device Product:
							</td>
							<td class="table-data" v-if="data.oversight_infos.length > 0">
								{{ data.oversight_infos[0].is_fda_regulated_device }}
							</td>
						</tr>
						<tr v-if="data.keywords && data.keywords.length > 0">
							<td class="table-data-heading">
								Keywords:
							</td>
							<td class="table-data">
								<span v-for="(keyword,index) in data.keywords" v-bind:key="keyword">
									{{ keyword.keyword}}<span v-if="index != data.keywords.length - 1">, </span>
								</span>
							</td>
						</tr>
					</tbody>
				</table>
			</b-card>
		</b-collapse>
	</div>
</template>
<script>
export default {
	props: ["data"],
};
</script>
