<template>
	<div>
    <b-button
    v-b-modal.location_investigator
    centered
    class="facility-btn"
    >Add Location Investigator
    </b-button>
    <b-modal
        id="location_investigator"
        centered
        title="Add Location Investigator"
        ok-only
    >
        <p v-if="errors.length">
                <b>Please correct the following error(s):</b>
                <ul class="ErrorClass">
                        <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                </ul>
        </p>
        <b-form-group label="Last Name:">
            <b-form-input
                type="text"
                placeholder="Last Name"
                v-model="location_investigator_last_name"
                autocomplete="off"
            ></b-form-input>
        </b-form-group>
        <b-form-group label="Role:">
            <b-form-input
                type="text"
                placeholder="Add Role"
                v-model="location_investigator_role"
                autocomplete="off"
            ></b-form-input>
        </b-form-group>
        <b-button
            class="add-facility-btn"
            v-on:click="additem()"
            >ADD</b-button
        >
    </b-modal>
    <div class="overflow-auto w-100">
		<table class="table mt-3 bg-white">
        <tbody>
            <tr>
                <th>Last Name</th>
                <th>Role</th>
                <th class="w-10">Action</th>
            </tr>
        </tbody>
        <tbody>
            <tr v-for="(data,index) in allInvestigators" v-bind:key="index">
                <td class="form-table">
                        <div class="inner-data">
							{{ data.last_name}}
						</div>
                </td>
                <td class="form-table">
                        <div class="inner-data">
							{{ data.role}}
						</div>
                </td>
                <td class="form-table">
                        <div class="d-flex">
							<button class="btn btn-danger btn-sm"
                        v-on:click="removeRow(index)">
                                <i style="color:white;" class="fa fa-trash"></i>
                        </button>
                        <button class="btn btn-secondary btn-sm"
                        v-b-modal.location_investigator
                        v-on:click="openModal(index,data)">
                                <i style="color:white;" class="fa fa-pencil"></i>
                        </button>
						</div>
                </td>
            </tr>
        </tbody>
    </table>
	</div>
	</div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
		props: ["LocationId","Locations"],
		name:"LocationInvestigator",
		data() {
				return {
						location_investigator_index: null,
						location_investigator_id: "",
						location_investigator_role: "",
						location_investigator_last_name: "",
						errors: [],
						isError: false,
				}
		},
		mounted() {
				if(this.LocationId){
				console.log("this.LocationId", this.LocationId);

						let tempObj = {
								locationId: this.LocationId,
								locations: this.Locations
						}
						this.$store.dispatch("getInvestigators", tempObj)
				}
		},
		computed: mapGetters(["allInvestigators"]),
		created(){
				if(this.LocationId){
						let tempObj = {
								locationId: this.LocationId,
								locations: this.Locations
						}
						this.$store.dispatch("getInvestigators", tempObj)
				}
		},
	methods: {
						additem() {
								this.errors = [];
								this.isError = false;
								if(this.location_investigator_last_name == "" && this.location_investigator_role == "") {
												this.errors.push('Last Name and Role fields are required.');
												this.isError = true;
								}
								if(this.isError == false) {
												if (this.location_investigator_index == null && this.location_investigator_id == "") {
																var location_investigatorTempObj = {  
																				last_name: this.location_investigator_last_name ? this.location_investigator_last_name : "",
																				role: this.location_investigator_role ? this.location_investigator_role : "",
																};
																this.$store.dispatch("addInvestigator", location_investigatorTempObj);
												} else {
																this.allInvestigators[this.location_investigator_index] = {
																				id: this.location_investigator_id ? this.location_investigator_id : "",
																				last_name: this.location_investigator_last_name ? this.location_investigator_last_name : "",
																				role: this.location_investigator_role ? this.location_investigator_role : "",
																};
												}

												this.location_investigator_index = null;
												this.location_investigator_id = "";
												this.location_investigator_last_name = "";
												this.location_investigator_role = "";
												this.$bvModal.hide('location_investigator')
								}
						},
						
						removeRow(index) {
										this.$store.dispatch("removeInvestigator", index)
						},
						
						openModal(index,data) {
										this.location_investigator_index = index;
										this.location_investigator_id = data.id ? data.id : "";
										this.location_investigator_last_name = data.last_name;
										this.location_investigator_role = data.role;
						}
	},
};
</script>
