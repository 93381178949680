import Vue from 'vue'
import axios from 'axios'
import Vuex from 'vuex'
import conditions from "./modules/conditions";
import location_facilities from './modules/location_facilities';
import location_facilities_addresses from './modules/location_facilities_addresses';
import location_contacts from './modules/location_contacts';
import location_contacts_backup from './modules/location_contacts_backup';
import location_investigators from './modules/location_investigators';
import locations from './modules/locations';
import sponsors from './modules/sponsors';
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        baseUrl: 'http://iuvax-backend.mashghol.com/api/',
        isLoading: false,
        clinicalStudyData: null,
        searchData: null,
        searchDataResult: [],
        allClinicalStudyData: [],
        clinicalStudyCount: 0,
        SearchNctIdentifier: '',
        SearchItnIdentifier: '',

        eligibility_id: "",
        criteria: "",
        gender: "",
        maximum_age: "",
        minimum_age: "",
        healthy_volunteers: "",
        study_pop: "",
        sampling_method: "",
        gender_based: "",
        gender_description: "",
        
        clinicalStudy_id: "",
        org_study_id: "",
        brief_title: "",
        source: "",
        verification_date: "",
        study_first_submitted: "",
        study_first_submitted_qc: "",
        study_first_posted: "",
        last_update_submitted: "",
        last_update_submitted_qc: "",
        last_update_posted: "",
        official_title: "",
        brief_summary: "",
        detailed_description: "",
        overall_status: "",
        phase: "",
        study_type: "",
        has_expanded_access: "",
        why_stopped: "",
        start_date: "",
        completion_date: "",
        primary_completion_date: "",
        enrollment: "",
        biospec_retention: "",
        biospec_description: "",
        last_known_status: "",
        acronym: "",
        results_first_submitted: "",
        results_first_submitted_qc: "",
        results_first_posted: "",
        disposition_first_submitted: "",
        disposition_first_submitted_qc: "",
        disposition_first_posted: "",
        target_duration: "",

        studyDesign_index: null,
        studyDesign_id: "",
        intervention_model: "",
        primary_purpose: "",
        masking: "",
        observational_model: "",
        time_perspective: "",
        allocation: "",
        masking_description: "",
        intervention_model_description: "",
        study_design_info: [],

        responsible_party_type_index: null,
        responsible_party_id: "",
        responsible_party_type: "",
        investigator_affiliation: "",
        investigator_full_name: "",
        investigator_title: "",
        name_title: "",
        organization: "",
        responsible_party: [],

        overall_official_index: null,
        overall_official_id: "",
        overall_official_last_name: "",
        overall_official_role: "",
        overall_official_affiliation: "",
        overall_official: [],

        overSightInfo_index: null,
        overSightInfoID: "",
        has_dmc: "",
        is_unapproved_device: "",
        is_ppsd: "",
        is_fda_regulated_drug: "",
        is_fda_regulated_device: "",
        is_us_export: "",
        oversight_info: [],
        
        study_doc_index: null,
        study_doc_id: "",
        study_doc: "",
        doc_type: "",
        doc_url: "",
        doc_comment: "",
        study_docs: [],

        expanded_access_index: null,
        expanded_access_id: "",
        expanded_access_type_intermediate: "",
        expanded_access_type_treatment: "",
        expanded_access_type_individual: "",
        expanded_access_info: [],

        provided_document_index: null,
        provided_document_id: "",
        document_type: "",
        document_has_protocol: "",
        document_has_icf: "",
        document_has_sap: "",
        document_date: "",
        document_url: "",
        provided_document: [],

        patient_data_index: null,
        patient_data_id: "",
        ipd_description: "",
        ipd_time_frame: "",
        ipd_url: "",
        ipd_access_criteria: "",
        patient_data: [],

        ipd_info_type_index: null,
        ipd_info_type_id: "",
        ipd_info_type_text: "",
        ipd_info_type_patient: "",
        ipd_info_type: [],

        participant_flow_index: null,
        recruitment_details: "",
        pre_assignment_details: "",
        participant_flow: [],

        limitations_and_caveat_index: null,
        limitations_and_caveat_id: "",
        limitations_and_caveats: "",
        limitations_and_caveat: [],

        reference_index: null,
        reference_id: "",
        reference_citation: "",
        reference_PMID: "",
        reference: [],

        results_reference_index: null,
        results_reference_id: "",
        results_reference_citation: "",
        results_reference_PMID: "",
        results_reference: [],

        primary_outcome_index: null,
        primary_outcome_id: "",
        primary_measure: "",
        primary_time_frame: "",
        primary_description: "",
        primary_outcome: [],

        secondary_outcome_index: null,
        secondary_outcome_id: "",
        secondary_measure: "",
        secondary_time_frame: "",
        secondary_description: "",
        secondary_outcome: [],

        other_outcome_index: null,
        other_outcome_id: "",
        other_measure: "",
        other_time_frame: "",
        other_description: "",
        other_outcome: [],

        certain_agreement_index: null,
        certain_agreement_id: "",
        pi_employee: "",
        restrictive_agreement: "",
        certain_agreement: [],
        
        condition_index: null,
        condition_id: "",
        condition_name: "",
        condition: [],

        overall_contact_index: null,
        overall_contact_id: "",
        overall_contact_last_name: "",
        overall_contact_phone: "",
        overall_contact_email: "",
        overall_contact_phone_ext: "",
        overall_contact: [],

        overall_contact_backup_index: null,
        overall_contact_backup_id: "",
        overall_contact_backup_last_name: "",
        overall_contact_backup_phone: "",
        overall_contact_backup_email: "",
        overall_contact_backup_phone_ext: "",
        overall_contact_backup: [],

        point_of_contact_index: null,
        point_of_contact_id: "",
        point_of_contact_name_or_title: "",
        point_of_contact_organization: "",
        point_of_contact_phone: "",
        point_of_contact_email: "",
        point_of_contact: [],

        arm_group_index: null,
        arm_group_id: "",
        arm_group_label: "",
        arm_group_type: "",
        arm_group_description: "",
        arm_group: [],

        intervention_index: null,
        intervention_id: "",
        intervention_name: "",
        intervention_type: "",
        intervention_description: "",
        intervention_arm_group: "",
        intervention: [],

        reported_event_index: null,
        reported_event_description: "",
        reported_event_time_frame: "",
        reported_event: [],

        serious_event_index: null,
        serious_event_default_assessment: "",
        serious_event_default_vocabulary: "",
        serious_event_reported_event: "",
        serious_event: [],

        other_event_index: null,
        other_event_default_assessment: "",
        other_event_default_vocabulary: "",
        other_event_frequency_threshold: "",
        other_event_reported_event: "",
        other_event: [],
        
        lead_agency_id: "",

        collabartor_agency_id: "",

        allAgencies: [],
        sponsor_index: null,
        sponsor_id: "",
        agncy: "",
        agncy_id: "",
        sponsor_type: "",
        agency_name: "",
        agency_class: "",
        sponsors: [],

        location_index: null,
        location_id: "",
        location_status: "",
        location: [],
        
        location_facility_count: 0,
        location_facility_first_index: "",
        location_facility: [],
        location_facility_index: null,
        location_facility_id: "",
        facility_name: "",

        location_facility_address: [],
        location_facility_address_index: null,
        location_facility_address_id: "",
        facility_address_country: "",
        facility_address_state: "",
        facility_address_city: "",
        facility_address_zip: "",
        facility_address_lat: "",
        facility_address_lng: "",
        address_facility: "",
        
        location_contact: [],
        location_contact_count: 0,
        location_contact_first_index: "",
        location_contact_index: null,
        location_contact_id: "",
        location_contact_last_name: "",
        location_contact_email: "",
        location_contact_phone: "",
        location_contact_phone_ext: "",
        
        location_contact_backup: [],
        location_contact_backup_index: null,
        location_contact_backup_id: "",
        location_contact_backup_last_name: "",
        location_contact_backup_email: "",
        location_contact_backup_phone: "",
        location_contact_backup_phone_ext: "",
        
        location_investigator: [],
        location_investigator_index: null,
        location_investigator_id: "",
        location_investigator_last_name: "",
        location_investigator_role: "",

    },

    mutations: {
        setLoadingStatus(state,status) {
            state.isLoading = status
        },
        clinicalStudyData(state, data) {
            state.allClinicalStudyData = data.data
            state.clinicalStudyData = data
        },
        searchData(state, data) {
            state.searchDataResult = data.data;
            state.searchData = data
        },
        clinicalStudyCount(state, count) {
            state.clinicalStudyCount = count
        },
        updateLocationData(state, id) {
            state.location_facility.filter(obj => obj.location_id === this.LocationId);
        }
    },

    actions: { 
        async getClinicalStudyData(context) {
            console.log('Api Started !');
            context.commit('setLoadingStatus',true)
            await axios.get('http://iuvax-backend.mashghol.com/api/clinical_study_listing').then(response => {
                if(response.data.code == 200) {
                    context.commit('clinicalStudyData',response.data.data.allClinicalStudies)
                    context.commit('clinicalStudyCount',response.data.data.count)
                    localStorage.setItem('clinicalStudyData',response.data.data.allClinicalStudies)
                } else {
                    console.log('API ERROR :',response.data);
                }
            })
            console.log('Api Done !');
            context.commit('setLoadingStatus',false)
        }
    },

    getters: {
        allSearchData: state => state.searchDataResult,
        searchData: state => state.searchData,
        clinicalStudyCount: state => state.clinicalStudyCount,
        clinicalStudyData: state => state.clinicalStudyData,
    },

    modules:[
        conditions,
        location_facilities,
        location_facilities_addresses,
        locations,
        location_contacts,
        location_contacts_backup,
        location_investigators,
        sponsors
    ]
})

export default store