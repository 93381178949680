<template>
	<div class="bg-grey" id="trialDetail">
		<div class="text-center" v-if="this.isLoading == true">
			<b-spinner medium type="grow" class="mr-1"></b-spinner>
			<b-spinner medium type="grow" class="mr-1"></b-spinner>
			<b-spinner medium type="grow"></b-spinner>
		</div>
		<div class="custom-container pt-5">
			<div class="top-text text-center">
				You can access this <span>clinical trial</span> if you have
				<span v-if="clinicalStudyDetailData[0].conditions.length > 0">
					<span
						v-for="(condition, index) in clinicalStudyDetailData[0].conditions"
						v-bind:key="condition"
					>
						{{ condition.name }}
						<span v-if="index != clinicalStudyDetailData[0].conditions.length - 1">, </span>
					</span>
				</span>
				<div
					v-if="this.clinicalStudyDetailData[0].eligibility.minimum_age != '' && this.clinicalStudyDetailData[0].eligibility.minimum_age != 'N/A' && this.clinicalStudyDetailData[0].eligibility.maximum_age != '' && this.clinicalStudyDetailData[0].eligibility.maximum_age != 'N/A'
					"
				>
					and you are
					<span
						>between
						{{ this.clinicalStudyDetailData[0].eligibility.minimum_age }} and
						{{ this.clinicalStudyDetailData[0].eligibility.maximum_age }}</span>
					old
				</div>
				<div
					v-else-if="this.clinicalStudyDetailData[0].eligibility.maximum_age != '' && this.clinicalStudyDetailData[0].eligibility.maximum_age != 'N/A'
					"
				>
					and you are
					<span
						>under
						{{ this.clinicalStudyDetailData[0].eligibility.maximum_age }}</span
					>
					old
				</div>
				<div
					v-else-if="this.clinicalStudyDetailData[0].eligibility.minimum_age != '' && this.clinicalStudyDetailData[0].eligibility.minimum_age != 'N/A'
					"
				>
					and you are
					<span
						>over
						{{ this.clinicalStudyDetailData[0].eligibility.minimum_age }}</span
					>
					old
				</div>
				<div
					v-else-if="(this.clinicalStudyDetailData[0].eligibility.minimum_age == '' || this.clinicalStudyDetailData[0].eligibility.minimum_age == 'N/A') && (this.clinicalStudyDetailData[0].eligibility.maximum_age == '' || this.clinicalStudyDetailData[0].eligibility.maximum_age == 'N/A')
					"
				>
				</div>
			</div>
			<div v-if="Phasearr.length > 0">
				<div class="d-flex justify-content-center">
					<span v-for="data in Phasearr" v-bind:key="data" style="margin: 0 5px;">
						<div class="phase-icon">{{ data.replaceAll('Phase', "") }}</div>
					</span>
				</div>
			</div>
			<div class="phase-icon mt-5" v-else>
				{{ phase}}
			</div>
			<div class="mt-5">
				<!-- <div class="phase-detail">
					This is a trial in the final phase before the treatment is released on
					the market.
				</div> -->
			</div>
		</div>
		<div class="bg-white mt-4">
			<div class="container-900 pt-5">
				<div class="col-12 d-flex justify-content-end mb-3">
					<router-link :to="{ name: 'AddClinicalTrials', params: { clinicalStudyID: this.clinicalStudyDetailData[0].id }}">
						<button class="moreInfo-btn">Add More Info</button>
					</router-link>
				</div>
				<StudyDescription :data="this.clinicalStudyDetailData[0]" />
				<StudyDesign :data="this.clinicalStudyDetailData[0]" />
				<PrimaryOutcome :data="this.clinicalStudyDetailData[0]"/>
				<SecondaryOutcome :data="this.clinicalStudyDetailData[0]"/>
				<EligibilityCriteria :data="this.clinicalStudyDetailData[0]" />
				<MoreInformation :data="this.clinicalStudyDetailData[0]" />
				<Interventions :data="this.clinicalStudyDetailData[0]" />
				<Arms :data="this.clinicalStudyDetailData[0]" />
				<ResponsibleParty :data="this.clinicalStudyDetailData[0]" />
				<Sponsors :data="this.clinicalStudyDetailData[0]" />
				<OverallOfficials :data="this.clinicalStudyDetailData[0]" />
				<!-- <GroupsAndCohorts :data="this.clinicalStudyDetailData[0]"/> -->
				<div class="pt-5 pb-5">
					<div class="text-center">
						<img :src="'./images/people.png'" alt="" />
					</div>
					<div class="image-box">
						We will connect you to the doctor responsible for this trial and
						help you get more information.
					</div>
				</div>
			</div>
		</div>
		<div
			class="custom-container pt-5"
			v-if="this.clinicalStudyDetailData[0].overall_contacts.length > 0"
		>
			<div class="main-heading">Overall Contact</div>
			<div class="bg-white location-box mt-4">
				<div
					class="row d-flex justify-content-between align-items-center"
					v-for="overallContact in this.clinicalStudyDetailData[0]
						.overall_contacts"
					v-bind:key="overallContact"
				>
					<div class="col-sm-8 col-12">
						<div class="serial-number">
							<span style="font-weight: 500;">Last Name :</span>
							{{ overallContact.last_name }}
						</div>
						<div class="address">
							<span style="font-weight: 500;">Phone Number :</span>
							{{ overallContact.phone }}
						</div>
						<div class="status">
							<span style="font-weight: 500;">Email :</span>
							{{ overallContact.email }}
						</div>
					</div>
					<!-- <div class="col-sm-4 col-12 text--center">
						<button class="location-btn">Put me in touch!</button>
					</div> -->
				</div>
			</div>
		</div>
		<div
			class="custom-container pt-5"
			v-if="this.clinicalStudyDetailData[0].locations.length > 0"
		>
			<div class="main-heading">Locations near you</div>
			<div class="bg-white location-box mt-4">
				<div class="row d-flex justify-content-between align-items-center">
					<div class="col-sm-8 col-12">
						<div class="serial-number">
							{{
								this.clinicalStudyDetailData[0].locations[0].facilities[0]
									.facility_name
							}}
						</div>
						<div class="address">
							{{
								this.clinicalStudyDetailData[0].locations[0].facilities[0]
									.address[0].city
							}},
							{{
								this.clinicalStudyDetailData[0].locations[0].facilities[0]
									.address[0].state
							}},
							{{
								this.clinicalStudyDetailData[0].locations[0].facilities[0]
									.address[0].country
							}},
							{{
								this.clinicalStudyDetailData[0].locations[0].facilities[0]
									.address[0].zip
							}}
						</div>
						<div class="status">
							{{ this.clinicalStudyDetailData[0].locations[0].status }}
						</div>
					</div>
					<!-- <div class="col-sm-4 col-12 text--center">
						<button class="location-btn">Put me in touch!</button>
					</div> -->
				</div>
			</div>
			<div class="row pt-5 pb-5 d-flex text-center">
				<div class="col-12">
					<b-button v-b-modal.all-locations class="eligibility-btn"
						><i class="fa fa-map-marker pr-2"></i>all locations</b-button
					>
					<AllLocations :data="this.clinicalStudyDetailData[0]" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";

import AllLocations from "./Modals/AllLocations";
import StudyDescription from "./StudyDescription";
import StudyDesign from "./StudyDesign";
import PrimaryOutcome from "./PrimaryOutcome";
import SecondaryOutcome from "./SecondaryOutcome";
import EligibilityCriteria from "./EligibilityCriteria";
import MoreInformation from "./MoreInformation";
// import GroupsAndCohorts from "./GroupsAndCohorts";
import Interventions from "./Interventions";
import ResponsibleParty from "./ResponsibleParty";
import Arms from "./Arms";
import Sponsors from "./Sponsors"
import OverallOfficials from "./OverallOfficials";

export default {
	name: "TrialsListingDetail",

	data() {
		return {
			clinicalStudyDetailData: null,
			isLoading: false,
			Phasearr: [],
			phase: "",
		};
	},

	components: {
		AllLocations,
		StudyDescription,
		StudyDesign,
		PrimaryOutcome,
		SecondaryOutcome,
		EligibilityCriteria,
		MoreInformation,
		// GroupsAndCohorts,
		Interventions,
		Arms,
		ResponsibleParty,
		Sponsors,
		OverallOfficials,
	},

	async mounted() {
		this.isLoading = true;
		let result = await axios.post(
			this.$store.state.baseUrl + "clinical_study",
			{
				clinical_study_id: this.$route.params.id,
			},
		);
		if (result.data.code == 200) {
			this.clinicalStudyDetailData = result.data.data;
		}
		this.isLoading = false;

		if (this.clinicalStudyDetailData[0].phase != '' && this.clinicalStudyDetailData[0].phase != 'N/A') {
			if (this.clinicalStudyDetailData[0].phase.includes('/') == true) {
				this.Phasearr = this.clinicalStudyDetailData[0].phase.split("/");
			} else {
				this.phase = this.clinicalStudyDetailData[0].phase.replaceAll('Phase', "")
			}
		} else {
			this.phase = '-';
		}
	},
};
</script>
