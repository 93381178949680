<template>
  <div class="col-12 filter-bar">
    <div class="text-center" v-if="this.isLoading == true">
      <b-spinner medium type="grow" class="mr-1"></b-spinner>
      <b-spinner medium type="grow" class="mr-1"></b-spinner>
      <b-spinner medium type="grow"></b-spinner>
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-6 col-12 pb--md-2">
        <b-form-input
          type="search"
          v-model="condition"
          class="search-input mt-3"
          placeholder="Condition"
        ></b-form-input>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb--md-2">
        <b-form-input
          type="search"
          v-model="itn"
          class="search-input mt-3"
          placeholder="ITN #"
        ></b-form-input>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb--md-2">
        <b-form-input
          type="search"
          v-model="nct"
          class="search-input mt-3"
          placeholder="NCT #"
        ></b-form-input>
      </div>
      <!-- <div class="col-md-3 col-sm-6 col-12 pb--md-2">
        <b-form-input
          type="number"
          v-model="zipcode"
          class="search-input mt-3"
          placeholder="Zip Code"
        ></b-form-input>
      </div> -->
    </div>
    <div class="row mt-4">
      <div class="col-md-3 col-sm-6 col-12 pb--md-2">
        <b-form-checkbox
          disabled
          value="accepted"
          unchecked-value="not_accepted"
          >Show Only New
        </b-form-checkbox>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb--md-2">
        <b-form-checkbox
          disabled
          value="accepted"
          unchecked-value="not_accepted"
          >Show Only Closed
        </b-form-checkbox>
      </div>
      <div class="col-md-3 col-sm-6 col-12 pb--md-2">
        <b-form-checkbox
          v-if="currentRoute != 'ForPatients'"
          v-model="inGermany"
          value="true"
          unchecked-value=""
          >Only In Germany
        </b-form-checkbox>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-4 " id="filter-trials">
        <div class="row">
          <div class="col-12">
            <div class="advance-search">
              <div class="grey pb-2">Advance Search</div>
              <div class="row">
                <!-- <div class="col-md-3 col-12">
                 <select v-model="age" class="about-select form-control">
                    <option value="">Age</option>
                    <option value="child">Child (birth–17)</option>
                    <option value="adult">Adult (18–64)</option>
                    <option value="olderAdult">Older Adult (65+)</option>
                  </select>
                </div>
                <div class="col-md-3 col-12">
                  <select v-model="gender" class="about-select form-control">
                    <option value="">Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="All">All</option>
                  </select>
                </div> -->
                <div class="col-md-3 col-12">
                  <select v-model="phase" class="about-select form-control">
                    <option value="">Phase</option>
                    <option value="Phase 0">Phase 0</option>
                    <option value="Phase 1">Phase 1</option>
                    <option value="Phase 2">Phase 2</option>
                    <option value="Phase 3">Phase 3</option>
                    <option value="Phase 4">Phase 4</option>
                    <option value="Phase 5">Phase 5</option>
                  </select>
                </div>
                <div class="col-md-3 col-12">
                  <b-form-input
                    type="text"
                    class="search-input form-control"
                    placeholder="Purpose of Trial"
                    v-model="purpose"
                  ></b-form-input>
                </div>
              </div>
              <div class="row pt-3">
                <!-- <div class="col-md-3 col-12">
                  <b-form-checkbox
                    v-model="showOpenTrial" 
                    value="true" 
                    unchecked-value=""
                    checked=""
                    >Show Only Open Trials
                  </b-form-checkbox>
                </div> -->
                <div class="col-md-3 col-12">
                  <b-form-checkbox
                    v-model="isHealthyVolunteer"
                    value="Yes"
                    unchecked-value=""
                    >Healthy Volunteer
                  </b-form-checkbox>
                </div>
                <div class="col-md-3 col-12">
                  <b-form-checkbox
                    v-if="currentRoute == 'ForPatients'"
                    v-model="inGermany"
                    value="Yes"
                    unchecked-value=""
                    >Only In Germany
                  </b-form-checkbox>
                </div>
                <div class="col-md-3 col-12"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-12 mt-4" id="filter-trials">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-md-3 col-12">
                <select
                  v-model="subcategory"
                  @change="onChange()"
                  class="about-select form-control"
                >
                  <option value="">Sub-category</option>
                  <option value="breast">Breast Cancer</option>
                  <option value="lungs">Lungs Cancer</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row mt-3"
          id="breast-subcategories"
          v-if="subcategory == 'breast'"
        >
          <div class="col-12">
            <div class="row" style="margin-left: -7px">
              <div class="col-md-3">
                <label>Treatment Approach</label>
              </div>
              <div class="col-md-9">
                <b-form-checkbox
                  v-model="treatmentApproach"
                  class="customCheckbox"
                  value="Neoadiuvant"
                  unchecked-value=""
                  >Neoadiuvant
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="treatmentApproach"
                  class="customCheckbox ml-5"
                  value="Adiuvant"
                  unchecked-value=""
                  >Adiuvant
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="treatmentApproach"
                  class="customCheckbox ml-5"
                  value="Palliative"
                  unchecked-value=""
                  >Palliative
                </b-form-checkbox>
              </div>
            </div>
            <div class="row mt-3" style="margin-left: -7px">
              <div class="col-md-3">
                <label>Receptor Status</label>
              </div>
              <div class="col-md-9">
                <b-form-radio
                  v-model="receptorStatus"
                  value="hormon_postive_her2nue_positive"
                  unchecked-value=""
                  >Hormon receptor positive / Her2neu positive (HR+ / Her2+)
                </b-form-radio>
                <b-form-radio
                  v-model="receptorStatus"
                  value="hormon_postive_her2nue_negative"
                  unchecked-value=""
                  >Hormon receptor positive / Her2neu negative (HR+ / Her2-)
                </b-form-radio>
                <b-form-radio
                  v-model="receptorStatus"
                  value="hormon_negative_her2nue_positive"
                  unchecked-value=""
                  >Hormon receptor negative / Her2neu positive (HR- / Her2+)
                </b-form-radio>
                <b-form-radio
                  v-model="receptorStatus"
                  value="triple_negative"
                  unchecked-value=""
                  >Triple-negative
                </b-form-radio>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row mt-3"
          id="lungs-subcategories"
          v-if="subcategory == 'lungs'"
        >
          <div class="col-12">
            <div class="row" style="margin-left: -7px">
              <div class="col-md-3">
                <label>Treatment Approach</label>
              </div>
              <div class="col-md-9">
                <b-form-checkbox
                  v-model="treatmentApproach"
                  class="customCheckbox"
                  value="Neoadiuvant"
                  unchecked-value=""
                  >Neoadiuvant
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="treatmentApproach"
                  class="customCheckbox ml-5"
                  value="Adiuvant"
                  unchecked-value=""
                  >Adiuvant
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="treatmentApproach"
                  class="customCheckbox ml-5"
                  value="Palliative"
                  unchecked-value=""
                  >Palliative
                </b-form-checkbox>
              </div>
            </div>
            <div class="row mt-3" style="margin-left: -7px">
              <div class="col-md-3">
                <label>Histologic Type</label>
              </div>
              <div class="col-md-9">
                <b-form-radio
                  v-model="histologicType"
                  value="sclc"
                  unchecked-value=""
                  >Small Cell Lung Cancer (SCLC)
                </b-form-radio>
                <b-form-radio
                  v-model="histologicType"
                  value="nsclc_nonsquamous"
                  unchecked-value=""
                  >Non Small Cell Lung Cancer (NSCLC) Non Squamous
                </b-form-radio>
                <b-form-radio
                  v-model="histologicType"
                  value="nscls_squamous"
                  unchecked-value=""
                  >Non Small Cell Lung Cancer (NSCLC) Squamous
                </b-form-radio>
              </div>
            </div>
            <div class="row mt-3" style="margin-left: -7px">
              <div class="col-md-3">
                <label>Known Driver Mutation</label>
              </div>
              <div class="col-md-9">
                <b-form-checkbox
                  v-model="knownDriverMutation"
                  class="customCheckbox"
                  value="KRAS"
                  unchecked-value=""
                  >KRAS
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="knownDriverMutation"
                  class="customCheckbox ml-5"
                  value="EGFR"
                  unchecked-value=""
                  >EGFR
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="knownDriverMutation"
                  class="customCheckbox ml-5"
                  value="ALK"
                  unchecked-value=""
                  >ALK
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="knownDriverMutation"
                  class="customCheckbox ml-5"
                  value="BRAF"
                  unchecked-value=""
                  >BRAF
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="knownDriverMutation"
                  class="customCheckbox ml-5"
                  value="PIK3CA"
                  unchecked-value=""
                  >PIK3CA
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="knownDriverMutation"
                  class="customCheckbox ml-5"
                  value="RET"
                  unchecked-value=""
                  >RET
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="knownDriverMutation"
                  class="customCheckbox ml-5"
                  value="ROS1"
                  unchecked-value=""
                  >ROS1
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row mb-4">
      <div class="col-12 pt-4">
        <button class="moreInfo-btn mr-2" v-on:click="search()">
          <i class="fa fa-search pr-1"></i>Search
        </button>
        <button v-if="currentRoute != 'ForPatients'" class="moreInfo-btn">
          Match
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["store", "page"],
  data() {
    return {
      itn: "",
      nct: "",
      condition: "",
      age: "",
      zipcode: "",
      gender: "",
      phase: "",
      purpose: "",
      subcategory: "",
      // showOpenTrial: "true",
      isHealthyVolunteer: "",
      isShow: false,
      isLoading: false,
      currentRoute: this.$route.name,
      treatmentApproach: [],
      receptorStatus: "",
      histologicType: "",
      knownDriverMutation: [],
      inGermany: "",
    };
  },
  methods: {
    async search() {
      this.isLoading = true;
      await axios
        .post(this.$store.state.baseUrl + "search_nct_identifier", {
          page: this.page,
          condition: this.condition,
          zipcode: this.zipcode,
          itn: this.itn,
          nct: this.nct,
          age: this.age,
          gender: this.gender,
          phase: this.phase,
          purpose: this.purpose,
          subcategory: this.subcategory,
          showOpenTrial: this.showOpenTrial,
          isHealthyVolunteer: this.isHealthyVolunteer,
          inGermany: this.inGermany
        })
        .then((response) => {
          if (response.data.code == 200) {
            console.log("response data", response.data);
            this.$store.commit(
              "clinicalStudyData",
              response.data.data.clinicalStudy
            );
            this.$store.commit("searchData", response.data.data.clinicalStudy);
            this.$store.commit("clinicalStudyCount", response.data.data.count);
          } else {
            this.$store.commit("clinicalStudyData", response.data.data);
            this.$store.commit("searchData", response.data.data);
            this.$store.commit("clinicalStudyCount", 0);
          }
        });
      this.isLoading = false;
    },

    onChange() {
      (this.treatmentApproach = []),
        (this.receptorStatus = ""),
        (this.histologicType = ""),
        (this.knownDriverMutation = []);
    },
  },
};
</script>
