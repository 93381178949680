import Vue from "vue";
import VueRouter from 'vue-router';

import Main from "../pages/Main";
import Home from "../components/Home";
import ForPatients from "../components/ClinicalTrials/ForPatients";
import Search from "../components/ClinicalTrials/SearchPatients";
import TrialsListingDetail from "../components/ClinicalTrials/TrialsListingDetail";
import AddClinicalTrials from "../components/ClinicalTrials/AddClinicalTrials";
import ParticipantMainFlow from "../components/ClinicalTrials/ClinicalTrialForm/ParticipantMainFlow"
import AddLocation from "../components/ClinicalTrials/ClinicalTrialForm/AddLocation";
import PatientsInfo from "../components/ClinicalTrials/PatientsInfo";
import MatchingOne from "../components/ClinicalTrials/MatchingOne";
import MatchingDetail from "../components/ClinicalTrials/MatchingDetail";





Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'hash',
    routes:[
        {
            path:'',
            component:Main,
            children:[
                {
                    path:"/home",
                    name:"Home",
                    component: Home,
                },
                {
                    path:"/search",
                    name:"Search",
                    exact: true,
                    component: Search,
                }, 
                {
                    path:"/for-patients",
                    name:"ForPatients",
                    exact: true,
                    component: ForPatients,
                },
                {
                    path:"/trials-detail/:id",
                    name:"TrialsListingDetail",
                    component: TrialsListingDetail,
                },
                {
                    path:"/add-trial/:clinicalStudyID",
                    name:"AddClinicalTrials",
                    component: AddClinicalTrials,
                },
                {
                    path:"/add-trial",
                    name:"AddClinicalTrialsnew",
                    component: AddClinicalTrials,
                },
                {
                    path:"/participant-flow",
                    name:"ParticipantFlow",
                    component: ParticipantMainFlow
                },
                {
                    path:"/add-location",
                    name:"AddLocation",
                    component: AddLocation,
                    props: true,
                },
                {
                    path:"/add-patient-information",
                    name:"PatientsInfo",
                    component: PatientsInfo,
                },
                {
                    path:"/step-1",
                    name:"MatchingOne",
                    component: MatchingOne,
                },
                {
                    path:"/matching-detail",
                    name:"MatchingDetail",
                    component: MatchingDetail,
                },
            ]
        }
    ]
    
});

export default router;