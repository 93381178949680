import axios from "axios";

const state = {
    locations: []
};
const getters = {
    allLocations: state => state.locations
};

const actions = {
    async getLocations({commit},id){
        const {data} = await axios.post("http://localhost:8000/api/clinical_study", {
            clinical_study_id: id,
        })
        if(data.data.locations.length > 0){
            let filteredLocations = data.data.locations.filter(obj => obj.clinical_study_id == id);
            commit("setLocations", filteredLocations);
        }

        console.log("allLocations", state.locations);

    },
    async addLocation({commit},location){
        commit("newLocation", location)
    },
    async removeLocation({commit},index){
        commit("removeLocation", index)
    }
};

const mutations = {
    setLocations: (state, locations) => (state.locations = locations),
    newLocation: (state, location) => (state.locations.unshift(location)),
    removeLocation: (state, index) => (state.locations.splice(index, 1))
};

export default {
    state,
    getters,
    mutations,
    actions
}

