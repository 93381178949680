import axios from "axios";

const state = {
    contact_backups: []
};
const getters = {
    allContactsBackup: state => state.contact_backups
};

const actions = {
    async getContactsBackup({commit},locationData){
        let contactsData = [];
        if(locationData.locationId){
            if(locationData.locations.length > 0){
                if(locationData.locationId){
                    contactsData = locationData.locations.filter((obj) => obj.id == locationData.locationId)[0]
                    console.log("contacts backup Data", contactsData.contact_backups);
        
                    commit("setContactsBackup", contactsData.contact_backups);
                }
            }
        }
    },
    async addContactBackup({commit},contact_backup){
        console.log("contact backup", contact_backup);
        commit("newContactBackup", contact_backup)
    },
    async removeContactBackup({commit},index){
        commit("removeContactBackup", index)
    }
};

const mutations = {
    setContactsBackup: (state, contact_backups) => (state.contact_backups = contact_backups),
    newContactBackup: (state, contact_backup) => (state.contact_backups.unshift(contact_backup)),
    removeContactBackup: (state, index) => (state.contact_backups.splice(index, 1))
};

export default {
    state,
    getters,
    mutations,
    actions
}

