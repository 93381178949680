<template>
  <tab-content title="Conditions" icon="fa fa-check-circle-o" :before-change="addCondition">
    <div>
      <b-button v-b-modal.condition class="facility-btn"
        >Add Condition</b-button
      >

      <b-modal id="condition" centered title="Add Condition">
         <p v-if="errors.length">
					<b>Please correct the following error(s):</b>
					<ul class="ErrorClass">
						<li v-for="error in errors" v-bind:key="error">{{ error }}</li>
					</ul>
				</p>
        <b-form-group label="Condition:">
          <b-form-textarea
            type="text"
            cols="30"
            rows="5"
            placeholder="Add Condition"
            v-model="condition_name"
            autocomplete="off"
          ></b-form-textarea>
        </b-form-group>


        <b-button
          class="add-facility-btn"
          v-on:click="additem()"
          >ADD</b-button
        >
      </b-modal>
    </div>
    <div class="w-100 overflow-auto">
      <table class="table mt-3 bg-white">
      <tbody>
        <tr>
          <th>Condition</th>
          <th class="w-10">Action</th>
        </tr>
      </tbody>
      <tbody>
        <tr v-for="(condition,index) in allConditions" v-bind:key="index">
          <td class="form-table">
            <div class="inner-data">
              {{ condition.name }}
            </div>
          </td>
          <td class="form-table">
            <div class="d-flex">
              <button
              class="btn btn-danger btn-sm"
              v-on:click="removeRow(index)"
            >
              <i style="color:white;" class="fa fa-trash"></i>
            </button>
            <button
              class="btn btn-secondary btn-sm"
              v-b-modal.condition
              v-on:click="openModal(index, condition)"
            >
              <i style="color:white;" class="fa fa-pencil"></i>
            </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
  </tab-content>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import axios from "axios";

export default {
  props: ["clinicalStudyID"],
  name:"Conditions",
  data() {
    console.log("HEYYY", this.clinicalStudyID);
    return {
      condition_index: null,
      condition_id: "",
      condition_name: "",
      conditions:[],
      errors: [],
      isError: false,
    }
  },
  mounted() {
    if(this.clinicalStudyID){
      this.$store.dispatch("getConditions", this.clinicalStudyID)
    }
  },
  computed: mapGetters(["allConditions"]),
  created(){
    if(this.clinicalStudyID){
      this.$store.dispatch("getConditions", this.clinicalStudyID)
    }
  },
 methods: {
    addCondition: async function() {
      if(this.allConditions.length > 0) {
				await axios.post(this.$store.state.baseUrl + "addCondition", {
						condition: this.allConditions,
            clinical_study_id: this.$store.state.clinicalStudy_id,
					})
					.then((response) => {
            this.$store.dispatch("getConditions", this.clinicalStudyID)
					});
        }
        return true;
      },

      additem() {
        this.errors = [];
        this.isError = false;
        console.log("yes", this.condition_name);
        if(this.condition_name == "") {
          this.errors.push('Condition name Field is required.');
          this.isError = true;
        }
        if(this.isError == false) {
          if (this.condition_index == null && this.condition_id == "") {
              var conditionTempObj = {
                  name: this.condition_name ? this.condition_name : "",
              };
               this.$store.dispatch("addCondition", conditionTempObj)
          } else {
              this.allConditions[this.condition_index] = {
                  id: this.condition_id ? this.condition_id : "",
                  name: this.condition_name ? this.condition_name : "",
              };
          }

          this.condition_index = null;
          this.condition_id = "";
          this.condition_name = "";
          this.$bvModal.hide('condition');
        }
      },
      
      removeRow(index) {
          this.$store.dispatch("removeCondition", index)
      },
      
      openModal(index,data) {
          this.condition_index = index;
          this.condition_id = data.id ? data.id : "";
          this.condition_name = data.name;
      }
	},
};
</script>