import Vue from 'vue'
import axios from 'axios'
import vueaxios from 'vue-axios'
import App from './App.vue'
import VueRouter from 'vue-router'
import store from './store/index'
import addEditStore from './store/addEditForm'
import router from "./router/routes"
import bootstrapvue from "bootstrap-vue"
import { BootstrapVueIcons } from 'bootstrap-vue'
import VueFormWizard from 'vue-form-wizard'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import "font-awesome/css/font-awesome.min.css"
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import './assets/css/style.css'
import './assets/css/responsive.css'
import vuetify from "vuetify";
import i18n from './i18n'


Vue.use(vueaxios, axios);
Vue.use(bootstrapvue);
Vue.use(BootstrapVueIcons);
Vue.use(VueFormWizard);




Vue.component('pagination', require('laravel-vue-pagination'));

Vue.use(VueRouter);
Vue.config.productionTip = false

new Vue({
  axios,
  vueaxios,
  router,
  store,
  addEditStore,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
