<template>
	<div>
		<div class="trial-list">
			<div class="left-trial">
				<div class="trial-title">
					{{ data.brief_title }}
				</div>
				<div>
					<div class="grey">Conditions</div>
					<div class="trial-disease" v-if="data.conditions.length == 1">
						<span>
							{{ data.conditions[0].name }}
						</span>
					</div>
					<div class="trial-disease" v-else-if="data.conditions.length > 1">
						<span
							v-for="(condition, index) in data.conditions.slice(0, 2)"
							v-bind:key="condition.id"
						>
							<span style="display:none">{{ index }}</span>
							{{ condition.name }},
						</span>
						<span v-if="data.conditions.length > 2" style="font-weight:400;font-size:15px;">
							+{{ data.conditions.length - 2 }} other conditions
						</span>
					</div>
				</div>
				<div class="row left-trial-bottom d-flex align-items-start pt-4">
					<div
						class="col-md-3 col-sm-6 col-12 b--r mb-sm-3"
						v-if="
							data.locations.length > 0 &&
								data.locations[0].facilities.length == 0
						"
					>
						<div class="phase-label"></div>
						<div class="d-flex mt-10">
							<i class="fa fa-map-marker pr-2"></i>
						</div>
					</div>
					<div
						class="col-md-3 col-sm-6 col-12 b--r mb-sm-3"
						v-else-if="
							data.locations.length > 1 &&
								data.locations[0].facilities.length > 0
						"
					>
						<div class="phase-label">
							{{ data.locations[0].facilities[0].address[0].country }}
						</div>
						<div class="d-flex mt-10">
							<i class="fa fa-map-marker pr-2"></i> +{{
								data.locations.length - 1
							}}
							other locations
						</div>
					</div>
					<div
						class="col-md-3 col-sm-6 col-12 b--r mb-sm-3"
						v-else-if="
							data.locations.length == 1 &&
								data.locations[0].facilities.length > 0
						"
					>
						<div class="phase-label">
							{{ data.locations[0].facilities[0].address[0].country }}
						</div>
						<div class="d-flex mt-10">
							<i class="fa fa-map-marker pr-2"></i>
						</div>
					</div>
					<div class="col-md-3 col-sm-6 col-12 b--r mb-sm-3" v-else>
						<div class="phase-label"></div>
						<div class="d-flex mt-10">
							<i class="fa fa-map-marker pr-2"></i>
						</div>
					</div>
					<div class="col-md-3 col-sm-6 col-12 b--r mb-xs-3" v-if="Phasearr.length > 0">
						<div class="phase-label text-center">Phase</div>
						<div class="d-flex justify-content-center">
							<span v-for="data in Phasearr" v-bind:key="data" style="margin: 0 5px;">
								<div class="phase-icon">{{ data.replaceAll('Phase', "") }}</div>
							</span>
						</div>
					</div>
					<div class="col-md-3 col-sm-6 col-12 b--r mb-xs-3" v-else>
						<div class="phase-label text-center">Phase</div>
						<div class="phase-icon">{{ phase}}</div>
					</div>
					<div class="col-md-3 col-sm-6 col-12 b--r mb-sm-3">
						<div class="phase-label">Primary purpose</div>
						<div v-if="data.study_design_infos.length > 0 && data.study_design_infos[0].primary_purpose">
							<div class="d-flex mt-10" v-if="data.study_design_infos.length > 0 && data.study_design_infos[0].primary_purpose == 'Treatment' ">
								<i class="fa fa-medkit pr-2"></i>
								{{ data.study_design_infos[0].primary_purpose }}
							</div>
							<div class="d-flex mt-10" v-else-if="data.study_design_infos.length > 0 && data.study_design_infos[0].primary_purpose == 'Prevention' ">
								<i class="fa fa-shield px-2"></i>
								{{ data.study_design_infos[0].primary_purpose }}
							</div>
							<div class="d-flex mt-10" v-else-if="data.study_design_infos.length > 0 && data.study_design_infos[0].primary_purpose == 'Observation' ">
								<i class="fa fa-tripadvisor px-2"></i>
								{{ data.study_design_infos[0].primary_purpose }}
							</div>
							<div class="d-flex mt-10" v-else-if="data.study_design_infos.length > 0 && data.study_design_infos[0].primary_purpose == 'Diagnostic' ">
								<i class="fa fa-line-chart px-2"></i>
								{{ data.study_design_infos[0].primary_purpose }}
							</div>
							<div class="d-flex mt-10" v-else-if="data.study_design_infos.length > 0 && data.study_design_infos[0].primary_purpose == 'Basic science' ">
								<i class="fa fa-universal-access px-2"></i>
								{{ data.study_design_infos[0].primary_purpose }}
							</div>
							<div class="d-flex mt-10" v-else-if="data.study_design_infos.length > 0 && data.study_design_infos[0].primary_purpose == 'Health Service Research' ">
								<i class="fa fa-plus px-2"></i>
								{{ data.study_design_infos[0].primary_purpose }}
							</div>
							<div class="d-flex mt-10" v-else-if="data.study_design_infos.length > 0 && data.study_design_infos[0].primary_purpose == 'Supportive Care' ">
								<i class="fa fa-superpowers px-2"></i>
								{{ data.study_design_infos[0].primary_purpose }}
							</div>
							<div class="d-flex mt-10" v-else-if="data.study_design_infos.length > 0 && data.study_design_infos[0].primary_purpose == 'Device Feasibility' ">
								<i class="fa fa-mobile px-2"></i>
								{{ data.study_design_infos[0].primary_purpose }}
							</div>
							<div class="d-flex mt-10" v-else-if="data.study_design_infos.length > 0 && data.study_design_infos[0].primary_purpose == 'Screening' ">
								<i class="fa fa-stethoscope px-2"></i>
								{{ data.study_design_infos[0].primary_purpose }}
							</div>
							<div class="d-flex mt-10" v-else-if="data.study_design_infos.length > 0 && data.study_design_infos[0].primary_purpose == 'Others' ">
								<i class="fa fa-heartbeat px-2"></i>
								{{ data.study_design_infos[0].primary_purpose }}
							</div>
							<div class="d-flex mt-10" v-else>
								{{ data.study_design_infos[0].primary_purpose }}
							</div>
						</div>
					</div>
					<div class="col-md-3 col-sm-6 col-12">
						<div class="phase-label">Sponsors</div>
						<div class="mt-10" v-if="data.agencies.length > 0 && data.agencies[0].agency != '' ">
							{{ data.agencies[0].agency }}
						</div>
					</div>
				</div>
			</div>
			<div class="right-trial d-flex align-items-end justify-content-center">
				<router-link :to="{ name: 'TrialsListingDetail', params: { id: data.id }}" target="_blank" style="margin-right:10px;">
					<button class="info-btn">More info</button>
				</router-link>
				<router-link v-if="currentRoute != 'ForPatients'" :to="{ name: 'AddClinicalTrials', params: { clinicalStudyID: data.id }}">
					<button class="info-btn">Edit</button>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    console.log("Hye", this.$route.name)
    return {
      Phasearr: [],
      phase: "",
      currentRoute: this.$route.name
    };
  },
  mounted() {
    if (this.data.phase != "" && this.data.phase != "N/A") {
      if (this.data.phase.includes("/") == true) {
        this.Phasearr = this.data.phase.split("/");
      } else {
        this.phase = this.data.phase.replaceAll("Phase", "");
      }
    } else {
      this.phase = "-";
    }
  },
};
</script>
