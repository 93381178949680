<template>
  <div class="col-12 mt-2">
    <b-button v-b-toggle.collapse-21 class="accordion-btn listing-btn"
      >Overall Officials <i class="fa fa-chevron-down ml-3"></i
    ></b-button>
    <b-collapse id="collapse-21" class="mt-3">
      <b-card>
        <table class="table table-striped" v-if="data.over_all_officials && data.over_all_officials.length > 0">
          <tbody v-for="(official,index) in data.over_all_officials" v-bind:key="official">
              <tr v-if="data.over_all_officials.length > 0">
              <!-- <td class="table-data-heading">
                Arm Group Label:
              </td> -->
              <td class="table-data">
               {{ index+1 }}
              </td>
            </tr>
            <tr v-if="data.over_all_officials.length > 0 && official.last_name != '' ">
              <td class="table-data-heading">
                Last Name:
              </td>
              <td class="table-data">
               {{ official.last_name}}
              </td>
            </tr>
            <tr v-if="data.over_all_officials.length > 0 && official.role != '' ">
              <td class="table-data-heading">
                Role:
              </td>
              <td class="table-data">
               {{ official.role}}
              </td>
            </tr>
            <tr v-if="data.over_all_officials.length > 0 && official.affiliation != '' ">
              <td class="table-data-heading">
                Affiliation:
              </td>
              <td class="table-data">
               {{ official.affiliation}}
              </td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </b-collapse>
  </div>
</template>
<script>
export default {
	props: ["data"],
};
</script>