<template>
	<tab-content title="References" icon="fa fa-phone" :before-change="addReferences">
		<div>
			<b-button v-b-modal.reference class="facility-btn"
				>Add Reference</b-button
			>

			<b-modal id="reference" centered title="Add New Reference">
				<p v-if="errors.length">
					<b>Please correct the following error(s):</b>
					<ul class="ErrorClass">
						<li v-for="error in errors" v-bind:key="error">{{ error }}</li>
					</ul>
				</p>
				<input type="hidden" v-model="store.state.reference_index" autocomplete="off" />

				<b-form-group label="Citation:">
					<b-form-input
						type="text"
						placeholder="Add Citation"
						v-model="store.state.reference_citation"
						autocomplete="off"
					></b-form-input>
				</b-form-group>

				<b-form-group label="PMID:">
					<b-form-input
						type="text"
						placeholder="Add PMID"
						v-model="store.state.reference_PMID"
						autocomplete="off"
					></b-form-input>
				</b-form-group>

				<b-button
					class="add-facility-btn"
					v-on:click="additem('reference')"
					>ADD</b-button
				>
			</b-modal>
		</div>
		<div class="overlow-auto w-100">
			<table class="table mt-3 bg-white">
				<tbody>
					<tr>
						<th>Citation</th>
						<th>PMID</th>
						<th class="w-10">Action</th>
					</tr>
				</tbody>
				<tbody>
					<tr v-for="(data, index) in store.state.reference" v-bind:key="data.citation">
						<td class="form-table">
							<div class="inner-data">
								{{ data.citation }}
							</div>
						</td>
						<td class="form-table">
							<div class="inner-data">
								{{ data.PMID }}
							</div>
						</td>
						<td class="form-table">
							<div class="d-flex">
								<button
								class="btn btn-danger btn-sm"
								v-on:click="removeRow(index, 'reference')"
							>
								<i style="color:white;" class="fa fa-trash"></i>
							</button>
							<button
								class="btn btn-secondary btn-sm"
								v-b-modal.reference
								v-on:click="openModal(index, data, 'reference')"
							>
								<i style="color:white;" class="fa fa-pencil"></i>
							</button>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div>
			<b-button v-b-modal.results_reference class="facility-btn"
				>Add Results Reference</b-button
			>

			<b-modal
				id="results_reference"
				centered
				title="Add New Results Reference"
			>
				<p v-if="errors.length">
					<b>Please correct the following error(s):</b>
					<ul class="ErrorClass">
						<li v-for="error in errors" v-bind:key="error">{{ error }}</li>
					</ul>
				</p>
				<input
					type="hidden"
					v-model="store.state.results_reference_index"
					autocomplete="off"
				/>

				<b-form-group label="Citation:">
					<b-form-input
						type="text"
						placeholder="Add Citation"
						v-model="store.state.results_reference_citation"
						autocomplete="off"
					></b-form-input>
				</b-form-group>

				<b-form-group label="PMID:">
					<b-form-input
						type="text"
						placeholder="Add PMID"
						v-model="store.state.results_reference_PMID"
						autocomplete="off"
					></b-form-input>
				</b-form-group>

				<b-button
					class="add-facility-btn"
					v-on:click="additem('results_reference')"
					>ADD</b-button
				>
			</b-modal>
		</div>
		<div class="overflow-auto">
			<table class="table mt-3 bg-white">
				<tbody>
					<tr>
						<th>Citation</th>
						<th>PMID</th>
						<th class="w-10">Action</th>
					</tr>
				</tbody>
				<tbody>
					<tr
						v-for="(data, index) in store.state.results_reference"
						v-bind:key="data.citation"
					>
						<td class="form-table">
							<div class="inner-data">
								{{ data.citation }}
							</div>
						</td>
						<td class="form-table">
							<div class="inner-data">
								{{ data.PMID }}
							</div>
						</td>
						<td class="form-table">
							<div class="d-flex">
								<button
								class="btn btn-danger btn-sm"
								v-on:click="removeRow(index, 'results_reference')"
							>
								<i style="color:white;" class="fa fa-trash"></i>
							</button>
							<button
								class="btn btn-secondary btn-sm"
								v-b-modal.results_reference
								v-on:click="openModal(index, data, 'results_reference')"
							>
								<i style="color:white;" class="fa fa-pencil"></i>
							</button>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</tab-content>
</template>
<script>
import axios from "axios";
export default {
	props:["store"],
	data() {
		return {
			errors: [],
			isError: false,
		}
	},
	methods: {
		addReferences: async function() {
			if(this.$store.state.reference.length > 0 || this.$store.state.results_reference.length > 0) {
				await axios.post(this.$store.state.baseUrl + "addReferences", {
					reference: this.$store.state.reference,
					results_reference: this.$store.state.results_reference,
					clinical_study_id: this.$store.state.clinicalStudy_id,
				})
				.then((response) => {
					console.log("response",response.data.reference);
					this.$store.state.reference = [];
					this.$store.state.results_reference = [];

					this.$store.state.reference = response.data.reference;
					this.$store.state.results_reference = response.data.results_reference;

					// this.$store.state.reference_index = response.data.reference.id;
					// this.$store.state.results_reference_index = response.data.results_reference.id;

				});
			}
			return true;
		},

		additem(type) {
			this.errors = [];
			this.isError = false;
			if (type == 'reference') {
				if(this.$store.state.reference_citation == "" && this.$store.state.reference_PMID == "") {
					this.errors.push('Please fill atleast one field.');
					this.isError = true;
				}
				console.log('additemreference :',this.$store.state.reference_index);
				if(this.isError == false) {
					if (this.$store.state.reference_index == null && this.$store.state.reference_id == "") {
						var referenceTempObj = {
							citation: this.$store.state.reference_citation ? this.$store.state.reference_citation : "",
							PMID: this.$store.state.reference_PMID ? this.$store.state.reference_PMID : "",
						};
						this.$store.state.reference.push(referenceTempObj);
					} else {
						this.$store.state.reference[this.$store.state.reference_index] = {
							id: this.$store.state.reference_id ? this.$store.state.reference_id : "",
							citation: this.$store.state.reference_citation ? this.$store.state.reference_citation : "",
							PMID: this.$store.state.reference_PMID ? this.$store.state.reference_PMID : "",
						};
					}

					this.$store.state.reference_index = null;
					this.$store.state.reference_id = "";
					this.$store.state.reference_citation = "";
					this.$store.state.reference_PMID = "";
					this.$bvModal.hide('reference')
				}
			} else if (type == 'results_reference') {
				if(this.$store.state.results_reference_citation == "" && this.$store.state.results_reference_PMID == "") {
					this.errors.push('Please fill atleast one field.');
					this.isError = true;
				}
				console.log('additemresults_reference :',this.$store.state.results_reference_index);
				if(this.isError == false) {
					if (this.$store.state.results_reference_index == null && this.$store.state.results_reference_id == "") {
						var results_referenceTempObj = {
							citation: this.$store.state.results_reference_citation ? this.$store.state.results_reference_citation : "",
							PMID: this.$store.state.results_reference_PMID ? this.$store.state.results_reference_PMID : "",
						};
						this.$store.state.results_reference.push(results_referenceTempObj);
					} else {
						this.$store.state.results_reference[this.$store.state.results_reference_index] = {
							id: this.$store.state.results_reference_id ? this.$store.state.results_reference_id : "",
							citation: this.$store.state.results_reference_citation ? this.$store.state.results_reference_citation : "",
							PMID: this.$store.state.results_reference_PMID ? this.$store.state.results_reference_PMID : "",
						};
					}

					this.$store.state.results_reference_index = null;
					this.$store.state.results_reference_id = "";
					this.$store.state.results_reference_citation = "";
					this.$store.state.results_reference_PMID = "";
					this.$bvModal.hide('results_reference')
				}
			}
		},

		removeRow(index,type) {
			if (type == 'reference') {
				this.$store.state.reference.splice(index,1);
			} else if (type == 'results_reference') {
				this.$store.state.results_reference.splice(index,1);
			}
		},

		openModal(index,data,type) {
			if (type == 'reference') {
				this.$store.state.reference_index = index;
				this.$store.state.reference_id = data.id ? data.id : "";
				this.$store.state.reference_citation = data.citation;
				this.$store.state.reference_PMID = data.PMID;
			} else if (type == 'results_reference') {
				this.$store.state.results_reference_index = index;
				this.$store.state.results_reference_id = data.id ? data.id : "";
				this.$store.state.results_reference_citation = data.citation;
				this.$store.state.results_reference_PMID = data.PMID;
			}
		}
	},
};
</script>

