<template>
  <div class="col-12">
    <b-button v-b-toggle.collapse-1 class="accordion-btn listing-btn"
      >Study Description <i class="fa fa-chevron-down ml-3"></i
    ></b-button>
    <b-collapse visible id="collapse-1" class="mt-3">
      <b-card>
        <table class="table table-striped">
          <tbody>
            <tr v-if="data.brief_summary != '' ">
              <td class="table-data-heading">Brief Summary:</td>
              <td class="table-data">
                {{ data.brief_summary}}
              </td>
            </tr>
            <tr v-if="data.detailed_description != '' ">
              <td class="table-data-heading">Detailed Description:</td>
              <td class="table-data">
                {{ data.detailed_description}}
              </td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </b-collapse>
  </div>
</template>
<script>
  export default {
    props: ["data"],
  }
</script>

