import axios from "axios";

const state = {
    facilities: []
};
const getters = {
    allFacilities: state => state.facilities
};

const actions = {
    async getFacilities({commit},locationData){
        let facilitiesData = [];
        console.log("locationData", locationData.locationId);
        if(locationData.locationId){
            if(locationData.locations.length > 0){
                console.log("locationData.locationId", locationData.locationId);
                    facilitiesData = locationData.locations.filter((obj) => obj.id == locationData.locationId)[0]
                    console.log("facilitiesData", facilitiesData.facilities);
        
                    commit("setFacilities", facilitiesData.facilities);
            }
        }

    },
    async addFacility({commit},facility){
        commit("newFacility", facility)
    },
    async removeFacility({commit},index){
        commit("removeFacility", index)
    }
};

const mutations = {
    setFacilities: (state, facilities) => (state.facilities = facilities),
    newFacility: (state, facility) => (state.facilities.unshift(facility)),
    removeFacility: (state, index) => (state.facilities.splice(index, 1))
};

export default {
    state,
    getters,
    mutations,
    actions
}

