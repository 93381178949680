<template>
  <div class="bg-grey">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <b-form-group style="width: 50%;" label="Location status:">
            <select class="about-select form-control" v-model="store.state.location_status"></select>
            <select class="about-select form-control mt-2" v-model="location_status">
              <option value="">Select Location Status</option>
              <option value="recruiting">Recruiting</option>
              <option value="Not yet recruiting">Not yet Recruiting</option>
              <option value="Active, not recruiting">Active, not Recruiting</option>
              <option value="Enrolling by invitation">Enrolling by Invitation</option>
            </select>
          </b-form-group>

            <Location-Facility :LocationId="this.LocationId" :Locations="this.$store.getters.allLocations"/>
            <Location-Facility-Address :LocationId="this.LocationId" :Locations="this.$store.getters.allLocations"/>
            <Location-Contact :LocationId="this.LocationId" :Locations="this.$store.getters.allLocations"/>
            <Location-Contact-Backup :LocationId="this.LocationId" :Locations="this.$store.getters.allLocations"/>
            <Location-Investigator :LocationId="this.LocationId" :Locations="this.$store.getters.allLocations"/>
            
          <div class="mb-5">
              <b-button class="facility-btn" v-on:click="submitApi()">Save</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import LocationFacility from "./LocationFacility";
import LocationFacilityAddress from "./LocationFacilityAddress";
import LocationContact from "./LocationContact";
import LocationContactBackup from "./LocationContactBackup";
import LocationInvestigator from "./LocationInvestigator";

export default {
  name: "AddLocation",
  props: ['LocationId','store'],
  data() {
    return {
      errors: [],
      isError: false,
      location_status: "",
      locations: [],
    }
  },
  components:{
    'Location-Facility': LocationFacility,
    'Location-Facility-Address': LocationFacilityAddress,
    'Location-Contact': LocationContact,
    'Location-Contact-Backup': LocationContactBackup,
    'Location-Investigator': LocationInvestigator,
  },
  methods: {
        submitApi: async function(){
            console.log("this", this);
            var locationStatusTempObj = {
                id: this.LocationId ? this.LocationId : "",
                status: this.location_status ? this.location_status : "",
                location_facility: this.$store.getters.allFacilities,
                location_facility_addresses: this.$store.getters.allAddresses,
                location_contact: this.$store.getters.allContacts,
                location_contact_backup: this.$store.getters.allContactsBackup,
                location_investigator: this.$store.getters.allInvestigators,
            };

            this.locations.push(locationStatusTempObj);

            await axios.post(this.$store.state.baseUrl + "addLocation", {
                  location: this.locations,
                  clinical_study_id: this.$store.state.clinicalStudy_id,
                })
                .then((response) => {
                  console.log("response", response);
                  this.$store.dispatch("getLocations", this.$store.state.clinicalStudy_id)
                  this.$router.go(-1);
                });
          return true;
        },
    }
};
</script>