<template>
  <tab-content title="Subcategory" icon="fa fa-cubes">
    <div>
      <b-button v-b-modal.lungs class="facility-btn"
        >Add Lungs Cancer Subcategory</b-button
      >

      <b-modal id="lungs" centered title="Add Lungs Cancer Subcategory">
        <div>Treatment Approach</div>
        <div>
          <b-form-checkbox
            v-model="treatmentApproach"
            class="customCheckbox"
            value="Neoadiuvant"
            unchecked-value=""
            >Neoadiuvant
          </b-form-checkbox>
          <b-form-checkbox
            v-model="treatmentApproach"
            class="customCheckbox ml-5"
            value="Adiuvant"
            unchecked-value=""
            >Adiuvant
          </b-form-checkbox>
          <b-form-checkbox
            v-model="treatmentApproach"
            class="customCheckbox ml-5"
            value="Palliative"
            unchecked-value=""
            >Palliative
          </b-form-checkbox>
        </div>

        <div>Histologic Type</div>
        <div>
          <b-form-radio unchecked-value=""
            >Small Cell Lung Cancer (SCLC)
          </b-form-radio>
          <b-form-radio v-model="histologicType" unchecked-value=""
            >Non Small Cell Lung Cancer (NSCLC) Non Squamous
          </b-form-radio>
          <b-form-radio unchecked-value=""
            >Non Small Cell Lung Cancer (NSCLC) Squamous
          </b-form-radio>
        </div>

          <div>Known Driver Mutation</div>
        <div>
          <b-form-checkbox
            class="customCheckbox"
            value="KRAS"
            unchecked-value=""
            >KRAS
          </b-form-checkbox>
          <b-form-checkbox
            class="customCheckbox ml-5"
            value="EGFR"
            unchecked-value=""
            >EGFR
          </b-form-checkbox>
          <b-form-checkbox
            class="customCheckbox ml-5"
            value="ALK"
            unchecked-value=""
            >ALK
          </b-form-checkbox>
          <b-form-checkbox
            class="customCheckbox ml-5"
            value="BRAF"
            unchecked-value=""
            >BRAF
          </b-form-checkbox>
          <b-form-checkbox
            class="customCheckbox ml-5"
            value="PIK3CA"
            unchecked-value=""
            >PIK3CA
          </b-form-checkbox>
          <b-form-checkbox
            class="customCheckbox ml-5"
            value="RET"
            unchecked-value=""
            >RET
          </b-form-checkbox>
          <b-form-checkbox
            class="customCheckbox ml-5"
            value="ROS1"
            unchecked-value=""
            >ROS1
          </b-form-checkbox>
        </div>

        <b-button class="add-facility-btn">ADD</b-button>
      </b-modal>
    </div>
    <div class="w-100 overflow-auto">
      <table class="table mt-3 bg-white">
        <tbody>
          <tr>
            <th>Subcategory</th>
            <th>Treatment</th>
            <th>Histologic Type</th>
            <th>Known Driver Mutation</th>
            <th class="w-10">Action</th>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td class="form-table">
              <div class="inner-data"></div>
            </td>
            <td class="form-table">
              <div class="inner-data"></div>
            </td>
            <td class="form-table">
              <div class="inner-data"></div>
            </td>
            <td class="form-table">
              <div class="inner-data"></div>
            </td>
            <td class="form-table">
              <div class="d-flex">
                <button class="btn btn-danger btn-sm">
                  <i style="color:white;" class="fa fa-trash"></i>
                </button>
                <button class="btn btn-secondary btn-sm" v-b-modal.lungs>
                  <i style="color:white;" class="fa fa-pencil"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <b-button v-b-modal.breast class="facility-btn"
        >Add Breast Cancer Subcategory</b-button
      >

      <b-modal id="breast" centered title="Add Breast Cancer Subcategory">
        <div class="pb-2">Treatment Approach</div>
        <div>
          <b-form-checkbox
            class="customCheckbox"
            value="Neoadiuvant"
            unchecked-value=""
            >Neoadiuvant
          </b-form-checkbox>
          <b-form-checkbox
            class="customCheckbox ml-5"
            value="Adiuvant"
            unchecked-value=""
            >Adiuvant
          </b-form-checkbox>
          <b-form-checkbox
            class="customCheckbox ml-5"
            value="Palliative"
            unchecked-value=""
            >Palliative
          </b-form-checkbox>
        </div>

        <div class="pt-3 pb-3">
          <div class="pb-2">Receptor Status</div>
          <div>
            <b-form-radio v-model="receptorStatus" unchecked-value=""
              >Hormon receptor positive / Her2neu positive (HR+ / Her2+)
            </b-form-radio>
            <b-form-radio v-model="receptorStatus" unchecked-value=""
              >Hormon receptor positive / Her2neu negative (HR+ / Her2-)
            </b-form-radio>
            <b-form-radio v-model="receptorStatus" unchecked-value=""
              >Hormon receptor negative / Her2neu positive (HR- / Her2+)
            </b-form-radio>
            <b-form-radio v-model="receptorStatus" unchecked-value=""
              >Triple-negative
            </b-form-radio>
          </div>
        </div>

        <b-button class="add-facility-btn">ADD</b-button>
      </b-modal>
    </div>
    <div class="w-100 overflow-auto">
      <table class="table mt-3 bg-white">
        <tbody>
          <tr>
            <th>Subcategory</th>
            <th>Treatment</th>
            <th>Receptor Status</th>
            <th class="w-10">Action</th>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td class="form-table">
              <div class="inner-data"></div>
            </td>
            <td class="form-table">
              <div class="inner-data"></div>
            </td>
            <td class="form-table">
              <div class="inner-data"></div>
            </td>
            <td class="form-table">
              <div class="d-flex">
                <button class="btn btn-danger btn-sm">
                  <i style="color:white;" class="fa fa-trash"></i>
                </button>
                <button class="btn btn-secondary btn-sm" v-b-modal.breast>
                  <i style="color:white;" class="fa fa-pencil"></i>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </tab-content>
</template>

<script>
export default {
  name: "Subcategories",
};
</script>
