<template>
    <tab-content title="Interventions" icon="fa fa-american-sign-language-interpreting" :before-change="addIntervention">
        <div>
            <b-button v-b-modal.add-intervention class="facility-btn"
                >Add Intervention</b-button
            >
            <input type="hidden" v-model="store.state.intervention_index" autocomplete="off" />
            <b-modal id="add-intervention" centered title="Add New Intervention">
                <p v-if="errors.length">
                    <b>Please correct the following error(s):</b>
                    <ul class="ErrorClass">
                        <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                    </ul>
                </p>
                <b-form-group>
                    <label class="mendatoryLabel">Intervention Name: <span class="mendatoryField">*</span></label>
                    <b-form-input
                        type="text"
                        placeholder="Add Name"
                        v-model="store.state.intervention_name"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-form-group>
                    <label class="mendatoryLabel">Intervention Type: <span class="mendatoryField">*</span></label>
                    <b-form-input
                        type="text"
                        placeholder="Add Type"
                        v-model="store.state.intervention_type"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Intervention Description:">
                    <b-form-textarea
                        type="text"
                        cols="30"
                        rows="5"
                        v-model="store.state.intervention_description"
                    ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                    <label class="mendatoryLabel">Intervention Arm label: <span class="mendatoryField">*</span></label>
                    <select class="about-select form-control" v-model="store.state.intervention_arm_group">
                        <option value=""></option>
                        <option v-bind:value="arm.id" v-for="arm in store.state.arm_group" v-bind:key="arm.id">{{ arm.arm_group_label }}</option>
                    </select>
                </b-form-group>

                <b-button
                    class="add-facility-btn"
                    v-on:click="additem('intervention')"
                    >ADD</b-button
                >
            </b-modal>
        </div>
        <div class="overflow-auto w-100">
            <table class="table mt-3 bg-white">
                <tbody>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Description</th>
                        <th>Arm label</th>
                        <th class="w-10">Action</th>
                    </tr>
                </tbody>
                <tbody>
                    <tr v-for="(data, index) in store.state.intervention" v-bind:key="index">
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.intervention_name }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.intervention_type }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.description }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.intervention_arm_group ? store.state.arm_group.filter((obj) => obj.id === data.intervention_arm_group)[0].arm_group_label : ""}}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="d-flex">
                                <button
                                class="btn btn-danger btn-sm"
                                v-on:click="removeRow(index, 'intervention')"
                            >
                                <i style="color:white;" class="fa fa-trash"></i>
                            </button>
                            <button
                                class="btn btn-secondary btn-sm"
                                v-b-modal.add-intervention
                                v-on:click="openModal(index, data, 'intervention')"
                            >
                                <i style="color:white;" class="fa fa-pencil"></i>
                            </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </tab-content>
</template>
<script>
import axios from "axios";
export default {
  props:["store"],
  data() {
      return {
        errors: [],
        isError: false,
      }
  },
    methods: {
        addIntervention: async function() {
            if(this.$store.state.intervention.length > 0) {
                await axios.post(this.$store.state.baseUrl + "addIntervention", {
                        intervention: this.$store.state.intervention,
                        clinical_study_id: this.$store.state.clinicalStudy_id,
                    })
                    .then((response) => {
                        console.log("response",response.data.intervention);
                        this.$store.state.intervention = [];

                        this.$store.state.intervention = response.data.intervention;

                        // this.$store.state.intervention_index = response.data.intervention.id;

                    });
                }
                return true;
        },

        additem(type) {
            this.errors = [];
            this.isError = false;
            if (type == 'intervention') {
                if(this.$store.state.intervention_name == "" && this.$store.state.intervention_type == "" && this.$store.state.intervention_arm_group == "") {
                    this.errors.push('Intervention Name, Type and Arm label Fields are required.');
                    this.isError = true;
                }
                console.log('additemintervention :',this.intervention_index);
                if(this.isError == false) {
                    console.log('DATA CHECK :',this.$store.state.intervention_arm_group);
                    if (this.$store.state.intervention_index == null && this.$store.state.intervention_id == "") {
                        var interventionTempObj = {
                            id: this.$store.state.intervention_id ? this.$store.state.intervention_id : "",
                            intervention_type: this.$store.state.intervention_type ? this.$store.state.intervention_type : "",
                            intervention_name: this.$store.state.intervention_name ? this.$store.state.intervention_name : "",
                            description: this.$store.state.intervention_description ? this.$store.state.intervention_description : "",
                            intervention_arm_group: this.$store.state.intervention_arm_group ? this.$store.state.intervention_arm_group : "",
                        };
                        this.$store.state.intervention.push(interventionTempObj);
                    } else {
                        this.$store.state.intervention[this.$store.state.intervention_index] = {
                            id: this.$store.state.intervention_id ? this.$store.state.intervention_id : "",
                            intervention_type: this.$store.state.intervention_type ? this.$store.state.intervention_type : "",
                            intervention_name: this.$store.state.intervention_name ? this.$store.state.intervention_name : "",
                            description: this.$store.state.intervention_description ? this.$store.state.intervention_description : "",
                            intervention_arm_group: this.$store.state.intervention_arm_group ? this.$store.state.intervention_arm_group : "",
                        };
                    }
                    this.$store.state.intervention_index = null;
                    this.$store.state.intervention_id = "";
                    this.$store.state.intervention_type = "";
                    this.$store.state.intervention_name = "";
                    this.$store.state.intervention_description = "";
                    this.$store.state.intervention_arm_group = "";
                    this.$bvModal.hide('add-intervention')
                }
            }
        },

        openModal(index,data,type) {
            if (type == 'intervention') {
                console.log('OpenModalintervention :',data);
                this.$store.state.intervention_index = index;
                this.$store.state.intervention_id = data.id ? data.id : "";
                this.$store.state.intervention_type = data.intervention_type;
                this.$store.state.intervention_name = data.intervention_name;
                this.$store.state.intervention_description = data.description;
                this.$store.state.intervention_arm_group = data.intervention_arm_group;
            }
        },

        removeRow(index,type) {
            if (type == 'intervention') {
                this.$store.state.intervention.splice(index,1);
            }
        },
    },
};
</script>
