<template>
  <div class="col-12 mt-2">
    <b-button v-b-toggle.collapse-19 class="accordion-btn listing-btn"
      >Sponsors <i class="fa fa-chevron-down ml-3"></i
    ></b-button>
    <b-collapse id="collapse-19" class="mt-3">
      <b-card>
        <table class="table table-striped" v-if="data.sponsors && data.sponsors.length > 0">
          <tbody v-for="(sponsor,index) in data.sponsors" v-bind:key="sponsor">
              <tr v-if="data.sponsors.length > 0">
              <!-- <td class="table-data-heading">
                Arm Group Label:
              </td> -->
              <td class="table-data">
               {{ index+1 }}
              </td>
            </tr>
            <tr v-if="data.sponsors.length > 0 && sponsor.lead_sponsor && sponsor.lead_sponsor.agency != '' ">
              <td class="table-data-heading">
                Agency Name:
              </td>
              <td class="table-data">
               {{ sponsor.lead_sponsor.agency}}
              </td>
            </tr>
            <tr v-if="data.sponsors.length > 0 && sponsor.lead_sponsor && sponsor.lead_sponsor.agency_class != '' ">
              <td class="table-data-heading">
                Agency Class:
              </td>
              <td class="table-data">
               {{ sponsor.lead_sponsor.agency_class}}
              </td>
            </tr>
            <tr v-if="data.sponsors.length > 0 && sponsor.lead_sponsor">
              <td class="table-data-heading">
                Sponsor Type:
              </td>
              <td class="table-data">
               Lead Sponsor
              </td>
            </tr>
            <tr v-if="data.sponsors.length > 0 && sponsor.collaborator_sponsor && sponsor.collaborator_sponsor.agency != '' ">
              <td class="table-data-heading">
                Agency Name:
              </td>
              <td class="table-data">
               {{ sponsor.collaborator_sponsor.agency}}
              </td>
            </tr>
            <tr v-if="data.sponsors.length > 0 && sponsor.collaborator_sponsor && sponsor.collaborator_sponsor.agency_class != '' ">
              <td class="table-data-heading">
                Agency Class:
              </td>
              <td class="table-data">
               {{ sponsor.collaborator_sponsor.agency_class}}
              </td>
            </tr>
            <tr v-if="data.sponsors.length > 0 && sponsor.collaborator_sponsor">
              <td class="table-data-heading">
                Sponsor Type:
              </td>
              <td class="table-data">
               Collaborator Sponsor
              </td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </b-collapse>
  </div>
</template>
<script>
  export default {
    props: ["data"],
  }
</script>