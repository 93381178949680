<template>
	<div class="bg-grey">
		<div class="row">
			<SearchTrials :store="this.$store" page="page"/>
			<div class="col-12 mt-4">
				<div id="per_page" class="d-flex justify-content-between">
					<div id="addClinicalStudyBtn" style="margin-right: auto;">
						
					</div>
					<div class="d-flex align-items-center">
						<div class="grey">Show</div>
						<select
							class="select_per_page float-right mx-1 grey"
							v-model="perPage"
							v-on:change="getResults()"
						>
							<option value="10">10</option>
							<option value="25">25</option>
							<option value="50">50</option>
							<option value="100">100</option>
						</select>
						<div class="grey">enteries</div>
					</div>
				</div>
			</div>
			<div class="col-12 mt-4">
				<div class="text-center" v-if="this.isLoading == true">
					<b-spinner medium type="grow" class="mr-1"></b-spinner>
					<b-spinner medium type="grow" class="mr-1"></b-spinner>
					<b-spinner medium type="grow"></b-spinner>
				</div>
				<h3 class="grey mb-3">
					We have found
					{{ clinicalStudyCount }} clinical trials
				</h3>
				<div
					id="my-table"
					v-for="item in clinicalStudyData.data"
					:key="item.id"
				>
					<TrialsListing :data="item" />
				</div>
			</div>
			<div class="col-12 mt-3">
				<pagination
				:data="clinicalStudyData"
				:limit="2"
				@pagination-change-page="getResults"
			></pagination>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import SearchTrials from "./SearchTrials";
import TrialsListing from "./TrialsListing";
import axios from "axios";

export default {
	name: "ForPatientsDuplicate",
	data() {
		return {
			perPage: 10,
			isLoading: false,
		};
	},

	computed: mapGetters(["clinicalStudyData","clinicalStudyCount"]),

	mounted() {
		this.$store.dispatch("getClinicalStudyData");
	},

	methods: {
		async getResults(page = 1) {
			this.isLoading = true;
			await axios
				.get(
					this.$store.state.baseUrl + "clinical_study_listing?page=" +page+"&nct_identifier="+this.$store.state.SearchNctIdentifier+"&itnIdentifier="+this.$store.state.SearchItnIdentifier)
				.then((response) => {
					if (response.data.code == 200) {
						this.$store.commit("clinicalStudyData", response.data.data.allClinicalStudies,);
					}
				});
			this.isLoading = false;
		},
	},

	components: {
		SearchTrials,
		TrialsListing,
	},
};
</script>
