<template>
    <tab-content
        title="Responsible Parties" icon="fa fa-american-sign-language-interpreting" :before-change="addResponsibleParty">
        <div>
            <b-button v-b-modal.responsibleParties class="facility-btn"
                >Add Responsible Party</b-button
            >

            <b-modal
                id="responsibleParties"
                centered
                title="Add New Responsible Party"
            >
                <p v-if="errors.length">
                    <b>Please correct the following error(s):</b>
                    <ul class="ErrorClass">
                        <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                    </ul>
                </p>
                <input
                    type="hidden"
                    v-model="store.state.responsible_party_type_index"
                    autocomplete="off"
                />

                <b-form-group label="Responsible Party Type:">
                    <b-form-input
                        type="text"
                        placeholder="Add Responsible Party Type"
                        v-model="store.state.responsible_party_type"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Investigator Affiliation:">
                    <b-form-input
                        type="text"
                        placeholder="Add Investigator Affiliation"
                        v-model="store.state.investigator_affiliation"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Investigator Full Name:">
                    <b-form-input
                        type="text"
                        placeholder="Add Investigator Full Name"
                        v-model="store.state.investigator_full_name"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Investigator Title:">
                    <b-form-input
                        type="text"
                        placeholder="Add Investigator Title"
                        v-model="store.state.investigator_title"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Name Title:">
                    <b-form-input
                        type="text"
                        placeholder="Add Name Title"
                        v-model="store.state.name_title"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Organization:">
                    <b-form-input
                        type="text"
                        placeholder="Add Organization"
                        v-model="store.state.organization"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-button
                    class="add-facility-btn"
                    v-on:click="additem('responsibleParties')"
                    >ADD</b-button
                >
            </b-modal>
        </div>
        <div class="overflow-auto w-100">
            <table class="table mt-3 bg-white">
                <tbody>
                    <tr>
                        <th>Responsible Party Type</th>
                        <th>Investigator Affiliation</th>
                        <th>Investigator Full Name</th>
                        <th>Investigator Title</th>
                        <th>Name Title</th>
                        <th>Organization</th>
                        <th class="w-10">Action</th>
                    </tr>
                </tbody>
                <tbody>
                    <tr
                        v-for="(data, index) in store.state.responsible_party"
                        v-bind:key="data.responsible_party_type"
                    >
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.responsible_party_type }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.investigator_affiliation }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.investigator_full_name }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.investigator_title }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.name_title }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.organization }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="d-flex">
                                <button
                                class="btn btn-danger btn-sm"
                                v-on:click="removeRow(index, 'responsible_party')"
                            >
                                <i style="color:white;" class="fa fa-trash"></i>
                            </button>
                            <button
                                class="btn btn-secondary btn-sm"
                                v-b-modal.responsibleParties
                                v-on:click="openModal(index, data, 'responsible_party')"
                            >
                                <i style="color:white;" class="fa fa-pencil"></i>
                            </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </tab-content>
</template>
<script>
import axios from "axios";
export default {
      props:["store"],
    data() {
        return {
            errors: [],
            isError: false,
        }
    },
    methods: {
        addResponsibleParty: async function() {
        if(this.$store.state.responsible_party.length > 0) {
            await axios.post(this.$store.state.baseUrl + "addResponsibleParty", {
                    responsible_party: this.$store.state.responsible_party,
                    clinical_study_id: this.$store.state.clinicalStudy_id,
                })
                .then((response) => {
                    console.log("response",response.data.responsible_party);
                    this.$store.state.responsible_party = [];
                    this.$store.state.responsible_party = response.data.responsible_party;
                    // this.$store.state.responsible_party_type_index = response.data.responsible_party.id;

                });
            }
            return true;
        },

        additem(type) {
            this.errors = [];
            this.isError = false;
            if (type == 'responsibleParties') {
                if(this.$store.state.investigator_affiliation == "" && this.$store.state.responsible_party_type == "" && this.$store.state.investigator_full_name == "" && 
                this.$store.state.investigator_title == "" && this.$store.state.name_title == "" && this.$store.state.organization == "") {
                    this.errors.push('Please fill atleast one field.');
                    this.isError = true;
                }
                console.log('additemresponsibleParties :',this.$store.state.responsible_party_type_index);
                if(this.isError == false) {
                    if (this.$store.state.responsible_party_type_index == null && this.$store.state.responsible_party_id == "") {
                        var responsiblePartiesTempObj = {
                            id: this.$store.state.responsible_party_id ? this.$store.state.responsible_party_id : "",
                            responsible_party_type: this.$store.state.responsible_party_type ? this.$store.state.responsible_party_type : "",
                            investigator_affiliation: this.$store.state.investigator_affiliation ? this.$store.state.investigator_affiliation : "",
                            investigator_full_name: this.$store.state.investigator_full_name ? this.$store.state.investigator_full_name : "",
                            investigator_title: this.$store.state.investigator_title ? this.$store.state.investigator_title : "",
                            name_title: this.$store.state.name_title ? this.$store.state.name_title : "",
                            organization: this.$store.state.organization ? this.$store.state.organization : "",
                        };
                        this.$store.state.responsible_party.push(responsiblePartiesTempObj);
                    } else {
                        this.$store.state.responsible_party[this.$store.state.responsible_party_type_index] = {
                            id: this.$store.state.responsible_party_id ? this.$store.state.responsible_party_id : "",
                            responsible_party_type: this.$store.state.responsible_party_type ? this.$store.state.responsible_party_type : "",
                            investigator_affiliation: this.$store.state.investigator_affiliation ? this.$store.state.investigator_affiliation : "",
                            investigator_full_name: this.$store.state.investigator_full_name ? this.$store.state.investigator_full_name : "",
                            investigator_title: this.$store.state.investigator_title ? this.$store.state.investigator_title : "",
                            name_title: this.$store.state.name_title ? this.$store.state.name_title : "",
                            organization: this.$store.state.organization ? this.$store.state.organization : "",
                        };
                    }
            
                    this.$store.state.responsible_party_type_index = null;
                    this.$store.state.responsible_party_id = "";
                    this.$store.state.responsible_party_type = "";
                    this.$store.state.investigator_affiliation = "";
                    this.$store.state.investigator_full_name = "";
                    this.$store.state.investigator_title = "";
                    this.$store.state.name_title = "";
                    this.$store.state.organization = "";
                    this.$bvModal.hide('responsibleParties')
                }
            }
        },

        removeRow(index,type) {
            if (type == 'responsible_party') {
                this.store.state.responsible_party.splice(index,1);
            }
        },

        openModal(index,data,type) {
            if (type == 'responsible_party') {
                console.log('OpenModalresponsible_party :',index);
                this.store.state.responsible_party_type_index = index;
                this.store.state.responsible_party_id = data.id ? data.id : "";
                this.store.state.responsible_party_type = data.responsible_party_type;
                this.store.state.investigator_affiliation = data.investigator_affiliation;
                this.store.state.investigator_full_name = data.investigator_full_name;
                this.store.state.investigator_title = data.investigator_title;
                this.store.state.name_title = data.name_title;
                this.store.state.organization = data.organization;
            }
        }
    },
};
</script>
