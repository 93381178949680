<template>
  <div class="bg-grey">
    <div class="container">
      <div class="row">
        <PatientInformation />
        <RelevantSearch />
        <SearchResults />
        <PossibleStudies />
        <ExcludedStudies />
      </div>
      <div class="row pt-4 pb-5">
        <div class="col-12 d-flex justify-content-end">
          <button class="moreInfo-btn mr-2">
            Go back to previous step
          </button>
          <button class="moreInfo-btn mr-2">
            Print
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PatientInformation from "../ClinicalTrials/PatientInformation";
import RelevantSearch from "../ClinicalTrials/RelevantSearch";
import SearchResults from "../ClinicalTrials/SearchResults";
import PossibleStudies from "../ClinicalTrials/PossibleStudies";
import ExcludedStudies from "../ClinicalTrials/ExcludedStudies";
export default {
  components: {
    PatientInformation,
    RelevantSearch,
    SearchResults,
    PossibleStudies,
    ExcludedStudies,
  },
};
</script>
