<template>
  <div class="col-12 mt-2">
    <b-button v-b-toggle.collapse-16 class="accordion-btn listing-btn"
      >Interventions <i class="fa fa-chevron-down ml-3"></i
    ></b-button>
    <b-collapse id="collapse-16" class="mt-3">
      <b-card>
        <table class="table table-striped" v-if="data.interventions && data.interventions.length > 0">
          <tbody v-for="(intervention,index) in data.interventions" v-bind:key="intervention">
            <tr v-if="data.interventions.length > 0">
              <!-- <td class="table-data-heading">
                Arm Group Label:
              </td> -->
              <td class="table-data">
               {{ index+1 }}
              </td>
            </tr>
            <tr v-if="data.interventions.length > 0 && intervention.intervention_type != '' ">
              <td class="table-data-heading">
                Intervention Type:
              </td>
              <td class="table-data">
               {{ intervention.intervention_type}}
              </td>
            </tr>
            <tr v-if="data.interventions.length > 0 && intervention.intervention_name != '' ">
              <td class="table-data-heading">
                Intervention Name:
              </td>
              <td class="table-data">
               {{ intervention.intervention_name}}
              </td>
            </tr>
            <tr v-if="data.interventions.length > 0 && intervention.description != '' ">
              <td class="table-data-heading">
                Intervention Description:
              </td>
              <td class="table-data">
               {{ intervention.description}}
              </td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </b-collapse>
  </div>
</template>
<script>
  export default {
    props: ["data"],
  }
</script>