import axios from "axios";

const state = {
    sponsors: []
};
const getters = {
    allSponsors: state => state.sponsors
};

const actions = {
    async getSponsors({commit},id){
        const {data} = await axios.post("http://localhost:8000/api/clinical_study", {
            clinical_study_id: id,
        })
        commit("setSponsors", data.data.agencies)
    },
    async addSponsor({commit},sponsor){
        commit("newSponsor", sponsor)
    },
    async removeSponsor({commit},index){
        console.log("indx",index);
        commit("removeSponsor", index)
    }
};

const mutations = {
    setSponsors: (state, sponsors) => (state.sponsors = sponsors),
    newSponsor: (state, sponsor) => (state.sponsors.unshift(sponsor)),
    removeSponsor: (state, index) => (state.sponsors.splice(index, 1))
};

export default {
    state,
    getters,
    mutations,
    actions
}

