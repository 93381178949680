<template>
  <div class="bg-grey">
    <div class="row">
      <div class="col-12 pb-3">
        <h3 class="grey pb-2">Study Trial Detail</h3>
        <div class="left-trial w-100">
          <div class="trial-title">
            A Volunteer Study to Collect Imaging Data for the Development and
            Validation of ScanNav Anatomy PNB
          </div>
          <div>
            <div class="grey">Conditions</div>
            <div class="trial-disease">
              <span>
                Ultrasound Imaging of Anatomical Structures
              </span>
              <span style="font-weight:400;font-size:15px;">
                +2 other conditions
              </span>
            </div>
          </div>
          <div class="row left-trial-bottom d-flex align-items-start pt-4">
            <div class="col-md-3 col-sm-6 col-12 b--r mb-sm-3">
              <div class="phase-label">
                UNITED KINGDOM
              </div>
              <div class="d-flex mt-10">
                <i class="fa fa-map-marker pr-2"></i>+1 other locations
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12 b--r mb-xs-3">
              <div class="phase-label text-center">Phase</div>
              <div class="phase-icon">1</div>
            </div>
            <div class="col-md-3 col-sm-6 col-12 b--r mb-sm-3">
              <div class="phase-label">Primary purpose</div>
              <div class="mt-10">
                <i class="fa fa-superpowers"></i>
                Supportive Care
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <div class="phase-label">Sponsors</div>
              <div class="mt-10">
                IntelligentUltrasound Limited
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 pb-3">
        <h3 class="grey pb-2">Nearest Location</h3>
        <div class="custom-container">
          <div class="bg-white location-box">
            <div class="row d-flex justify-content-between align-items-center">
              <div class="col-sm-8 col-12">
                <div class="serial-number">
                  LTD Unimedi Kakheti - Caraps Medline
                </div>
                <div class="address">
                  Tbilisi, Georgia, 0159
                </div>
                <div class="status">
                  not yet recruiting
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 pb-3">
        <h3 class="grey pb-2">Inclusion Criteria(s):</h3>
        <div class="left-trial w-100 p-0">
          <div class="criteria-section">
            <div class="w-80"></div>
            <div class="d-flex justify-content-between w-20">
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >Select All
              </b-form-checkbox>
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >Select All
              </b-form-checkbox>
            </div>
          </div>
          <div class="criteria-section">
            <div class="w-80">
              Study participant must be ≥18 years of age, at the time of signing
              the informed consent
            </div>
            <div class="d-flex justify-content-between w-20">
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >True
              </b-form-checkbox>
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >False
              </b-form-checkbox>
            </div>
          </div>
          <div class="criteria-section">
            <div class="w-80">
              Study participant has documented diagnosis of generalized
              myasthenia gravis (gMG) at Visit 1, based on study participant's
              history and supported by previous evaluations
            </div>
            <div class="d-flex justify-content-between w-20">
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >True
              </b-form-checkbox>
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >False
              </b-form-checkbox>
            </div>
          </div>
          <div class="criteria-section">
            <div class="w-80">
              Study participant has a confirmed positive record of
              autoantibodies against acetylcholine receptor (AChR) or
              muscle-specific kinase (MuSK) at Screening (Visit
            </div>
            <div class="d-flex justify-content-between w-20">
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >True
              </b-form-checkbox>
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >False
              </b-form-checkbox>
            </div>
          </div>
          <div class="criteria-section">
            <div class="w-80">
              The presence of autoantibodies may be confirmed with repeat
              testing at Visit 1
            </div>
            <div class="d-flex justify-content-between w-20">
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >True
              </b-form-checkbox>
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >False
              </b-form-checkbox>
            </div>
          </div>
          <div class="criteria-section">
            <div class="w-80">
              Study participant has Myasthenia Gravis Foundation of America
              (MGFA) Class II to IVa at Visit 1
            </div>
            <div class="d-flex justify-content-between w-20">
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >True
              </b-form-checkbox>
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >False
              </b-form-checkbox>
            </div>
          </div>
          <div class="criteria-section">
            <div class="w-80">
              Study participant with a Myasthenia Gravis-Activities of Daily
              Living (MG-ADL) score of at least 3 (with >3 points from
              non-ocular symptom) AND a quantitative myasthenia gravis (QMG)
              score of at least 11 at Visit 1 and at Baseline (Visit 2)
            </div>
            <div class="d-flex justify-content-between w-20">
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >True
              </b-form-checkbox>
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >False
              </b-form-checkbox>
            </div>
          </div>
          <div class="criteria-section">
            <div class="w-80">
              Study participant is considered for additional treatment such as
              intravenous immunoglobulin g (IVIg) or plasma exchange (PEX) by
              the Investigator
            </div>
            <div class="d-flex justify-content-between w-20">
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >True
              </b-form-checkbox>
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >False
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 pb-3">
        <h3 class="grey pb-2">Exclusion Criteria(s):</h3>
        <div class="left-trial w-100 p-0">
          <div class="criteria-section">
            <div class="w-80"></div>
            <div class="d-flex justify-content-between w-20">
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >Select All
              </b-form-checkbox>
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >Select All
              </b-form-checkbox>
            </div>
          </div>
          <div class="criteria-section">
            <div class="w-80">
              Study participant has a known history of hyperprolinemia
            </div>
            <div class="d-flex justify-content-between w-20">
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >True
              </b-form-checkbox>
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >False
              </b-form-checkbox>
            </div>
          </div>
          <div class="criteria-section">
            <div class="w-80">
              Study participant has a clinically relevant active infection (eg,
              sepsis, pneumonia, or abscess) in the opinion of the Investigator,
              or had a serious infection (resulting in hospitalization or
              requiring parenteral antibiotic treatment) within 6 weeks prior to
              the first dose of investigational medicinal product (IMP)
            </div>
            <div class="d-flex justify-content-between w-20">
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >True
              </b-form-checkbox>
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >False
              </b-form-checkbox>
            </div>
          </div>
          <div class="criteria-section">
            <div class="w-80">
              Study participant with a known tuberculosis (TB) infection, at
              high risk of acquiring TB infection, or latent tuberculosis
              infection (LTBI), or current/history of nontuberculous
              mycobacterial infection (NTMBI) will be excluded
            </div>
            <div class="d-flex justify-content-between w-20">
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >True
              </b-form-checkbox>
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >False
              </b-form-checkbox>
            </div>
          </div>
          <div class="criteria-section">
            <div class="w-80">
              Study participant has experienced hypersensitivity reaction after
              exposure to other anti-neonatal Fc receptor (FcRn) drugs
            </div>
            <div class="d-flex justify-content-between w-20">
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >True
              </b-form-checkbox>
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >False
              </b-form-checkbox>
            </div>
          </div>
          <div class="criteria-section">
            <div class="w-80">
              Study participant with severe (defined as Grade 3 on the
              Myasthenia Gravis-Activities of Daily Living (MG-ADL) scale)
              weakness affecting oropharyngeal or respiratory muscles, or who
              has myasthenic crisis or impending crisis at Visit 1 or Visit 2
            </div>
            <div class="d-flex justify-content-between w-20">
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >True
              </b-form-checkbox>
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >False
              </b-form-checkbox>
            </div>
          </div>
          <div class="criteria-section">
            <div class="w-80">
              Study participant with a Myasthenia Gravis-Activities of Daily
              Living (MG-ADL) score of at least 3 (with >3 points from
              non-ocular symptom) AND a quantitative myasthenia gravis (QMG)
              score of at least 11 at Visit 1 and at Baseline (Visit 2)
            </div>
            <div class="d-flex justify-content-between w-20">
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >True
              </b-form-checkbox>
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >False
              </b-form-checkbox>
            </div>
          </div>
          <div class="criteria-section">
            <div class="w-80">
              Study participant has a history of a solid organ transplant or
              hematopoietic stem cell/marrow transplants
            </div>
            <div class="d-flex justify-content-between w-20">
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >True
              </b-form-checkbox>
              <b-form-checkbox value="accepted" unchecked-value="not_accepted"
                >False
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-4 pb-5">
      <div class="col-12">Study <span>1</span> of <span>12</span></div>
      <div class="col-12 d-flex justify-content-end">
        <button class="moreInfo-btn mr-2">
          Skip this trial
        </button>
        <router-link to="/matching-detail">
          <button class="moreInfo-btn mr-2">
          Next
        </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MatchingOne",
};
</script>
