<template>
    <div>
         <b-button
            v-b-modal.location_contact_backup
            centered
            class="facility-btn"
            >Add Location Contact Backup</b-button
          >
          <b-modal
            id="location_contact_backup"
            centered
            title="Add Location Contact Backup"
            ok-only
          >
            <p v-if="errors.length">
                <b>Please correct the following error(s):</b>
                <ul class="ErrorClass">
                    <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                </ul>
            </p>
            <b-form-group label="Last Name:">
              <b-form-input
                type="text"
                placeholder="Last Name"
                v-model="location_contact_backup_last_name"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Email:">
              <b-form-input
                type="email"
                placeholder="Add Email"
                v-model="location_contact_backup_email"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Phone:">
              <b-form-input
                type="number"
                placeholder="Add Phone"
                v-model="location_contact_backup_phone"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Phone Extra:">
              <b-form-input
                type="number"
                placeholder="Add Phone Extra"
                v-model="location_contact_backup_phone_ext"
                autocomplete="off"
              ></b-form-input>
            </b-form-group>
            <b-button
              class="add-facility-btn"
              v-on:click="additem()"
              >ADD</b-button
            >
          </b-modal>
          <div class="overflow-auto w-100">
            <table class="table mt-3 bg-white">
            <tbody>
              <tr>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Phone Extra</th>
                <th class="w-10">Action</th>
              </tr>
            </tbody>
            <tbody>
              <tr v-for="(data,index) in allContactsBackup" v-bind:key="index">
                <td class="form-table">
                    <div class="inner-data">
                      {{ data.last_name}}
                    </div>
                </td>
                <td class="form-table">
                    <div class="inner-data">
                      {{ data.email}}
                    </div>
                </td>
                <td class="form-table">
                    <div class="inner-data">
                      {{ data.phone}}
                    </div>
                </td>
                <td class="form-table">
                    <div class="inner-data">
                      {{ data.phone_ext}}
                    </div>
                </td>
                <td class="form-table">
                    <div class="d-flex">
                      <button class="btn btn-danger btn-sm"
                    v-on:click="removeRow(index)">
                        <i style="color:white;" class="fa fa-trash"></i>
                    </button>
                    <button class="btn btn-secondary btn-sm"
                    v-b-modal.location_contact_backup
                    v-on:click="openModal(index,data)">
                        <i style="color:white;" class="fa fa-pencil"></i>
                    </button>
                    </div>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  props: ["LocationId","Locations"],
  name:"LocationContactBackup",
  data() {
    return {
      location_contact_backup_index: null,
      location_contact_backup_id: "",
      location_contact_backup_last_name: "",
      location_contact_backup_email: "",
      location_contact_backup_phone: "",
      location_contact_backup_phone_ext: "",
      errors: [],
      isError: false,
    }
  },
  mounted() {
    if(this.LocationId){
    console.log("this.LocationId", this.LocationId);

      let tempObj = {
        locationId: this.LocationId,
        locations: this.Locations
      }
      this.$store.dispatch("getContactsBackup", tempObj)
    }
  },
  computed: mapGetters(["allContactsBackup"]),
  created(){
    if(this.LocationId){
      let tempObj = {
        locationId: this.LocationId,
        locations: this.Locations
      }
      this.$store.dispatch("getContactsBackup", tempObj)
    }
  },
 methods: {
      additem() {
        this.errors = [];
        this.isError = false;
        if(this.location_contact_backup_last_name == "" && this.location_contact_backup_email == "" && this.location_contact_backup_phone == "" && this.location_contact_backup_phone_ext == "") {
            this.errors.push('Please fill atleast one field.');
            this.isError = true;
        } else if (this.location_contact_backup_phone != "" && this.location_contact_backup_phone_ext == "") {
            this.errors.push('Phone and Phone Ext fields are required.');
            this.isError = true;
        }
        if(this.isError == false) {
            if (this.location_contact_backup_index == null && this.location_contact_backup_id == "") {
                var location_contactTempObj = {
                    last_name: this.location_contact_backup_last_name ? this.location_contact_backup_last_name : "",
                    phone: this.location_contact_backup_phone ? this.location_contact_backup_phone : "",
                    email: this.location_contact_backup_email ? this.location_contact_backup_email : "",
                    phone_ext: this.location_contact_backup_phone_ext ? this.location_contact_backup_phone_ext : "",
                };
                this.$store.dispatch("addContactBackup", location_contactTempObj);

            } else {
                this.allContactsBackup[this.location_contact_backup_index] = {
                    id: this.location_contact_backup_id ? this.location_contact_backup_id : "",
                    last_name: this.location_contact_backup_last_name ? this.location_contact_backup_last_name : "",
                    phone: this.location_contact_backup_phone ? this.location_contact_backup_phone : "",
                    email: this.location_contact_backup_email ? this.location_contact_backup_email : "",
                    phone_ext: this.location_contact_backup_phone_ext ? this.location_contact_backup_phone_ext : "",
                };
            }

            this.location_contact_backup_index = null;
            this.location_contact_backup_id = "";
            this.location_contact_backup_last_name = "";
            this.location_contact_backup_phone = "";
            this.location_contact_backup_email = "";
            this.location_contact_backup_phone_ext = "";
            this.$bvModal.hide('location_contact_backup')
        }
      },
      
      removeRow(index) {
          this.$store.dispatch("removeContactBackup", index)
      },
      
      openModal(index,data) {
          this.location_contact_backup_index = index;
          this.location_contact_backup_id = data.id ? data.id : "";
          this.location_contact_backup_last_name = data.last_name;
          this.location_contact_backup_phone = data.phone;
          this.location_contact_backup_email = data.email;
          this.location_contact_backup_phone_ext = data.phone_ext;
      }
	},
};
</script>
