<template>
    <tab-content title="Study Design" icon="fa fa-hospital-o" :before-change="addStudyDesignInfo">
        <div>
            <b-button v-b-modal.studyDesign class="facility-btn"
                >Add Study Design</b-button
            >

            <b-modal id="studyDesign" centered title="Add New Study Design">
                <p v-if="errors.length">
                    <b>Please correct the following error(s):</b>
                    <ul class="ErrorClass">
                        <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                    </ul>
                </p>
                <input type="hidden" v-model="store.state.studyDesign_index" autocomplete="off" />
                <b-form-group label="Intervention Model:">
                    <b-form-input
                        type="text"
                        placeholder="Add Intervention Model"
                        v-model="store.state.intervention_model"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Intervention Model Description:">
                    <b-form-textarea
                        type="text"
                        cols="30"
                        rows="5"
                        v-model="store.state.intervention_model_description"
                    ></b-form-textarea>
                </b-form-group>

                <b-form-group label="Primary Purpose:">
                    <select class="about-select form-control" v-model="store.state.primary_purpose">
                        <option value="">Select Primary Purpose</option>
                        <option value="Treatment">Treatment</option>
                        <option value="Prevention">Prevention</option>
                        <option value="Observation">Observation</option>
                        <option value="Diagnostic">Diagnostic</option>
                        <option value="Basic science">Basic Science</option>
                        <option value="Device Feasibility">Device Feasibility</option>
                        <option value="Screening">Screening</option>
                        <option value="Supportive Care">Supportive Care</option>
                        <option value="Others">Other</option>
                    </select>
                </b-form-group>

                <b-form-group label="Masking:">
                    <b-form-input
                        type="text"
                        placeholder="Add Masking"
                        v-model="store.state.masking"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Masking Description:">
                    <b-form-textarea
                        type="text"
                        cols="30"
                        rows="5"
                        v-model="store.state.masking_description"
                    ></b-form-textarea>
                </b-form-group>

                <b-form-group label="Observational Model:">
                    <b-form-input
                        type="text"
                        placeholder="Add Observational Model"
                        v-model="store.state.observational_model"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Time Perspective:">
                    <b-form-input
                        type="text"
                        placeholder="Add Time Perspective"
                        v-model="store.state.time_perspective"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Allocation:">
                    <b-form-input
                        type="text"
                        placeholder="Add Allocation"
                        v-model="store.state.allocation"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-button
                    class="add-facility-btn"
                    v-on:click="additem('studyDesign')"
                    >ADD</b-button
                >
            </b-modal>
        </div>
        <div class="overflow-auto w-100">
            <table class="table mt-3 bg-white">
                <tbody>
                    <tr>
                        <th>Intervention Model</th>
                        <th>Primary Purpose</th>
                        <th>Masking</th>
                        <th>Observational Model</th>
                        <th>Time Perspective</th>
                        <th>Allocation</th>
                        <th>Masking Description</th>
                        <th>Intervention Model Description</th>
                        <th class="w-10">Action</th>
                    </tr>
                </tbody>
                <tbody>
                    <tr
                        v-for="(data, index) in store.state.study_design_info"
                        v-bind:key="data.intervention_model"
                    >
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.intervention_model }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.primary_purpose }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.masking }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.observational_model }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.time_perspective }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.allocation }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.masking_description }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.intervention_model_description }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="d-flex">
                                <button
                                class="btn btn-danger btn-sm"
                                v-on:click.prevent="removeRow(index,'study_design_info')"
                            >
                                <i style="color:white;" class="fa fa-trash"></i>
                            </button>
                            <button
                                class="btn btn-secondary btn-sm"
                                v-b-modal.studyDesign
                                v-on:click="openModal(index, data, 'studyDesign')"
                            >
                                <i style="color:white;" class="fa fa-pencil"></i>
                            </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </tab-content>
</template>
<script>
import axios from "axios";
export default {
      props:["store"],
    data() {
        return {
            errors: [],
            isError: false,
        }
    },
    methods: {
        addStudyDesignInfo: async function() {
        if(this.$store.state.study_design_info.length > 0) {
            await axios.post(this.$store.state.baseUrl + "addStudyDesignInfo", {
                    study_design_info: this.$store.state.study_design_info,
                    clinical_study_id: this.$store.state.clinicalStudy_id,
                })
                .then((response) => {
                    console.log("response",response.data.study_design_info);
                    this.$store.state.study_design_info = [];
                    this.$store.state.study_design_info = response.data.study_design_info;
                    // this.$store.state.studyDesign_index = response.data.study_design_info.id;

                });
            }
            return true;
        },

        additem(type) {
            this.errors = [];
            this.isError = false;
            if (type == 'studyDesign') {
                if(this.$store.state.intervention_model == "" && this.$store.state.primary_purpose == "" && this.$store.state.masking == "" && this.$store.state.time_perspective == "" && 
                this.$store.state.allocation == "" && this.$store.state.masking_description == "" && this.$store.state.intervention_model_description == "" && this.$store.state.observational_model == "") {
                    this.errors.push('Please fill atleast one field.');
                    this.isError = true;
                }
                if(this.isError == false) {
                    if (this.$store.state.studyDesign_index == null && this.$store.state.studyDesign_id == "") {
                console.log('additemstudyDesign :',this.$store.state.studyDesign_index);
                        var studyDesignTempObj = {
                            id: this.$store.state.studyDesign_id ? this.$store.state.studyDesign_id : "",
                            intervention_model: this.$store.state.intervention_model ? this.$store.state.intervention_model : "",
                            primary_purpose: this.$store.state.primary_purpose ? this.$store.state.primary_purpose : "",
                            masking: this.$store.state.masking ? this.$store.state.masking : "",
                            time_perspective: this.$store.state.time_perspective ? this.$store.state.time_perspective : "",
                            allocation: this.$store.state.allocation ? this.$store.state.allocation : "",
                            masking_description: this.$store.state.masking_description ? this.$store.state.masking_description : "",
                            intervention_model_description: this.$store.state.intervention_model_description ? this.$store.state.intervention_model_description : "",
                            observational_model: this.$store.state.observational_model ? this.$store.state.observational_model : "",
                        };
                        this.$store.state.study_design_info.push(studyDesignTempObj);
                    } else {
                        this.$store.state.study_design_info[this.$store.state.studyDesign_index] = {
                            id: this.$store.state.studyDesign_id ? this.$store.state.studyDesign_id : "",
                            intervention_model: this.$store.state.intervention_model ? this.$store.state.intervention_model : "",
                            primary_purpose: this.$store.state.primary_purpose ? this.$store.state.primary_purpose : "",
                            masking: this.$store.state.masking ? this.$store.state.masking : "",
                            time_perspective: this.$store.state.time_perspective ? this.$store.state.time_perspective : "",
                            allocation: this.$store.state.allocation ? this.$store.state.allocation : "",
                            masking_description: this.$store.state.masking_description ? this.$store.state.masking_description : "",
                            intervention_model_description: this.$store.state.intervention_model_description ? this.$store.state.intervention_model_description : "",
                            observational_model: this.$store.state.observational_model ? this.$store.state.observational_model : "",
                        };
                    }
                
                    this.$store.state.studyDesign_index = null;
                    this.$store.state.studyDesign_id = "";
                    this.$store.state.intervention_model = "";
                    this.$store.state.primary_purpose = "";
                    this.$store.state.masking = "";
                    this.$store.state.time_perspective = "";
                    this.$store.state.allocation = "";
                    this.$store.state.masking_description = "";
                    this.$store.state.intervention_model_description = "";
                    this.$store.state.observational_model = "";
                    this.$bvModal.hide('studyDesign')
                }
            }
        },

        removeRow(index,type) {
            if (type == 'study_design_info') {
                this.$store.state.study_design_info.splice(index,1);
            }
        },

        openModal(index,data,type) {
            if (type == 'studyDesign') {
                console.log('OpenModalstudyDesign :',index);
                this.$store.state.studyDesign_index = index;
                this.$store.state.studyDesign_id = data.id ? data.id : "";
                this.$store.state.intervention_model = data.intervention_model;
                this.$store.state.primary_purpose = data.primary_purpose;
                this.$store.state.masking = data.masking;
                this.$store.state.time_perspective = data.time_perspective;
                this.$store.state.allocation = data.allocation;
                this.$store.state.masking_description = data.masking_description;
                this.$store.state.intervention_model_description = data.intervention_model_description;
                this.$store.state.observational_model = data.observational_model;
            }
        }
    },
};
</script>