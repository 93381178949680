<template>
  <tab-content
    title="More Information"
    icon="fa fa-info-circle"
    :before-change="addClinicalStudy"
  >
    <table class="table table-striped">
      <tbody>
        <tr>
          <td class="table-data-heading">
            Organization Study ID:
          </td>
          <td class="table-description">
            <b-form-input
              name=""
              class="trial-textarea"
              v-model="store.state.org_study_id"
            ></b-form-input>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Brief Title:
          </td>
          <td class="table-description">
            <b-form-textarea
              name=""
              id=""
              cols="30"
              rows="2"
              class="trial-textarea"
              v-model="store.state.brief_title"
            ></b-form-textarea>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Source:
          </td>
          <td class="table-description">
            <b-form-input
              name=""
              class="trial-textarea"
              v-model="store.state.source"
            ></b-form-input>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Verification Date:
          </td>
          <td class="table-description">
            <b-form-datepicker
              placeholder="Select Date"
              class="trial-textarea"
              v-model="store.state.verification_date"
            ></b-form-datepicker>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Study First Submitted:
          </td>
          <td class="table-description">
            <b-form-datepicker
              placeholder="Select Date"
              class="trial-textarea"
              v-model="store.state.study_first_submitted"
            ></b-form-datepicker>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Study First Submitted QC:
          </td>
          <td class="table-description">
            <b-form-datepicker
              placeholder="Select Date"
              class="trial-textarea"
              v-model="store.state.study_first_submitted_qc"
            ></b-form-datepicker>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Study First Posted:
          </td>
          <td class="table-description">
            <b-form-datepicker
              placeholder="Select Date"
              class="trial-textarea"
              v-model="store.state.study_first_posted"
            ></b-form-datepicker>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Last Update Submitted:
          </td>
          <td class="table-description">
            <b-form-datepicker
              placeholder="Select Date"
              class="trial-textarea"
              v-model="store.state.last_update_submitted"
            ></b-form-datepicker>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Last Update Submitted QC:
          </td>
          <td class="table-description">
            <b-form-datepicker
              placeholder="Select Date"
              v-model="store.state.last_update_submitted_qc"
              class="trial-textarea"
            ></b-form-datepicker>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Last Update Posted:
          </td>
          <td class="table-description">
            <b-form-datepicker
              placeholder="Select Date"
              v-model="store.state.last_update_posted"
              class="trial-textarea"
            ></b-form-datepicker>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Offical Title:
          </td>
          <td class="table-description">
            <b-form-textarea
              name=""
              id=""
              cols="30"
              rows="2"
              class="trial-textarea"
              v-model="store.state.official_title"
            ></b-form-textarea>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Brief Summary:
          </td>
          <td class="table-description">
            <b-form-textarea
              name=""
              id=""
              cols="30"
              rows="5"
              class="trial-textarea"
              v-model="store.state.brief_summary"
            ></b-form-textarea>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Detailed Description:
          </td>
          <td class="table-description">
            <b-form-textarea
              name=""
              id=""
              cols="30"
              rows="5"
              class="trial-textarea"
              v-model="store.state.detailed_description"
            ></b-form-textarea>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Overall Status:
          </td>
          <td class="table-description">
            <select
              style="width: 100%; height: 100%"
              class="about-select form-control"
              v-model="store.state.overall_status"
            >
              <option value="">Select Overall Status</option>
              <option value="Terminated">Terminated</option>
              <option value="Completed">Completed</option>
              <option value="Unknown status">Unknown status</option>
              <option value="Active, not recruiting"
                >Active, not recruiting</option
              >
              <option value="Withdrawn">Withdrawn</option>
              <option value="Enrolling by invitation"
                >Enrolling by invitation</option
              >
              <option value="Recruiting">Recruiting</option>
              <option value="Not yet recruiting">Not yet recruiting</option>
            </select>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Phase:
          </td>
          <td class="table-description">
            <select
              style="width: 100%; height: 100%"
              class="about-select form-control"
              v-model="store.state.phase"
            >
              <option value="">Select Overall Status</option>
              <option value="Phase 1">Phase 1</option>
              <option value="Phase 2">Phase 2</option>
              <option value="Phase 3">Phase 3</option>
              <option value="Phase 4">Phase 4</option>
            </select>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Study Type:
          </td>
          <td class="table-description">
            <select
              style="width: 100%; height: 100%"
              class="about-select form-control"
              v-model="store.state.study_type"
            >
              <option value="">Select Study Type</option>
              <option value="Observational">Observational</option>
              <option value="Interventional">Interventional</option>
            </select>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Has Expanded Access:
          </td>
          <td class="table-description">
            <select
              style="width: 100%; height: 100%"
              class="about-select form-control"
              v-model="store.state.has_expanded_access"
            >
              <option value="">Select Expanded Access</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Why Stopped:
          </td>
          <td class="table-description">
            <b-form-textarea
              name=""
              id=""
              rows="2"
              class="trial-textarea"
              v-model="store.state.why_stopped"
            ></b-form-textarea>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Started Date:
          </td>
          <td class="table-description">
            <b-form-datepicker
              placeholder="Select Date"
              class="trial-textarea"
              v-model="store.state.start_date"
            ></b-form-datepicker>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Completion Date:
          </td>
          <td class="table-description">
            <b-form-datepicker
              placeholder="Select Date"
              class="trial-textarea"
              v-model="store.state.completion_date"
            ></b-form-datepicker>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Primary Completion Date:
          </td>
          <td class="table-description">
            <b-form-datepicker
              placeholder="Select Date"
              class="trial-textarea"
              v-model="store.state.primary_completion_date"
            ></b-form-datepicker>
          </td>
        </tr>
        <!-- <tr>
                    <td class="table-data-heading">
                        Number of Groups:
                    </td>
                    <td class="table-description">
                        <input
                            name=""
                            type="number"
                            id=""
                            rows="1"
                            class="trial-textarea numnberOnly"
                            v-model="store.state.number_of_groups"
                            @keypress="isNumber($event)"
                        />
                    </td>
                </tr> -->
        <tr>
          <td class="table-data-heading">
            Enrollment:
          </td>
          <td class="table-description">
            <b-form-input
              name=""
              type="number"
              id=""
              class="trial-textarea numnberOnly"
              v-model="store.state.enrollment"
              @keypress="isNumber($event)"
            />
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Biospec Retention:
          </td>
          <td class="table-description">
            <b-form-textarea
              name=""
              id=""
              cols="30"
              rows="5"
              class="trial-textarea"
              v-model="store.state.biospec_retention"
            ></b-form-textarea>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Biospec Description:
          </td>
          <td class="table-description">
            <b-form-textarea
              name=""
              id=""
              rows="5"
              cols="30"
              class="trial-textarea"
              v-model="store.state.biospec_description"
            ></b-form-textarea>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Last Know Status:
          </td>
          <td class="table-description">
            <select
              style="width: 100%; height: 100%"
              class="about-select form-control"
              v-model="store.state.last_known_status"
            >
              <option value="">Select Last Know Status</option>
              <option value="Terminated">Terminated</option>
              <option value="Completed">Completed</option>
              <option value="Unknown status">Unknown status</option>
              <option value="Active, not recruiting"
                >Active, not recruiting</option
              >
              <option value="Withdrawn">Withdrawn</option>
              <option value="Enrolling by invitation"
                >Enrolling by invitation</option
              >
              <option value="Recruiting">Recruiting</option>
              <option value="Not yet recruiting">Not yet recruiting</option>
            </select>
          </td>
        </tr>
        <!-- <tr>
                    <td class="table-data-heading">
                        Number of Arms:
                    </td>
                    <td class="table-description">
                        <input
                            name=""
                            id=""
                            rows="1"
                            class="trial-textarea numnberOnly"
                            v-model="store.state.number_of_arms"
                            @keypress="isNumber($event)"
                        />
                    </td>
                </tr> -->
        <tr>
          <td class="table-data-heading">
            Acronym:
          </td>
          <td class="table-description">
            <b-form-textarea
              name=""
              id=""
              rows="3"
              class="trial-textarea"
              v-model="store.state.acronym"
            ></b-form-textarea>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Results first submitted:
          </td>
          <td class="table-description">
            <b-form-datepicker
              class="trial-textarea"
              v-model="store.state.results_first_submitted"
              placeholder="Select Date"
            ></b-form-datepicker>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Results first submitted QC:
          </td>
          <td class="table-description">
            <b-form-datepicker
              class="trial-textarea"
              v-model="store.state.results_first_submitted_qc"
              placeholder="Select Date"
            ></b-form-datepicker>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Results first posted:
          </td>
          <td class="table-description">
            <b-form-datepicker
              class="trial-textarea"
              v-model="store.state.results_first_posted"
              placeholder="Select Date"
            ></b-form-datepicker>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Disposition first submitted:
          </td>
          <td class="table-description">
            <b-form-datepicker
              class="trial-textarea"
              v-model="store.state.disposition_first_submitted"
              placeholder="Select Date"
            ></b-form-datepicker>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Disposition first submitted QC:
          </td>
          <td class="table-description">
            <b-form-datepicker
              class="trial-textarea"
              v-model="store.state.disposition_first_submitted_qc"
              placeholder="Select Date"
            ></b-form-datepicker>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Disposition first posted:
          </td>
          <td class="table-description">
              <b-form-datepicker
              class="trial-textarea"
              v-model="store.state.disposition_first_posted"
              placeholder="Select Date"
            ></b-form-datepicker>
          </td>
        </tr>
        <tr>
          <td class="table-data-heading">
            Target Duration:
          </td>
          <td class="table-description">
            <b-form-textarea
              name=""
              id=""
              rows="1"
              class="trial-textarea"
              v-model="store.state.target_duration"
            ></b-form-textarea>
          </td>
        </tr>
      </tbody>
    </table>
  </tab-content>
</template>
<script>
import { additem, removeRow, OpenModal } from "../../../util/function";
import axios from "axios";
export default {
  props: ["store", "clinicalStudyID"],
  data() {
    return {
      clinical_study: [],
      clinicalStudy_id: null,
    };
  },
  methods: {
    addClinicalStudy: async function() {
      var clinicalStudyTemp = {
        id: this.clinicalStudyID ? this.clinicalStudyID : this.clinicalStudy_id,
        org_study_id: this.$store.state.org_study_id
          ? this.$store.state.org_study_id
          : "",
        brief_title: this.$store.state.brief_title
          ? this.$store.state.brief_title
          : "",
        source: this.$store.state.source ? this.$store.state.source : "",
        verification_date: this.$store.state.verification_date
          ? this.$store.state.verification_date
          : "",
        study_first_submitted: this.$store.state.study_first_submitted
          ? this.$store.state.study_first_submitted
          : "",
        study_first_submitted_qc: this.$store.state.study_first_submitted_qc
          ? this.$store.state.study_first_submitted_qc
          : "",
        study_first_posted: this.$store.state.study_first_posted
          ? this.$store.state.study_first_posted
          : "",
        last_update_submitted: this.$store.state.last_update_submitted
          ? this.$store.state.last_update_submitted
          : "",
        last_update_submitted_qc: this.$store.state.last_update_submitted_qc
          ? this.$store.state.last_update_submitted_qc
          : "",
        last_update_posted: this.$store.state.last_update_posted
          ? this.$store.state.last_update_posted
          : "",
        official_title: this.$store.state.official_title
          ? this.$store.state.official_title
          : "",
        brief_summary: this.$store.state.brief_summary
          ? this.$store.state.brief_summary
          : "",
        detailed_description: this.$store.state.detailed_description
          ? this.$store.state.detailed_description
          : "",
        overall_status: this.$store.state.overall_status
          ? this.$store.state.overall_status
          : "",
        phase: this.$store.state.phase ? this.$store.state.phase : "",
        study_type: this.$store.state.study_type
          ? this.$store.state.study_type
          : "",
        has_expanded_access: this.$store.state.has_expanded_access
          ? this.$store.state.has_expanded_access
          : "",
        why_stopped: this.$store.state.why_stopped
          ? this.$store.state.why_stopped
          : "",
        start_date: this.$store.state.start_date
          ? this.$store.state.start_date
          : "",
        completion_date: this.$store.state.completion_date
          ? this.$store.state.completion_date
          : "",
        primary_completion_date: this.$store.state.primary_completion_date
          ? this.$store.state.primary_completion_date
          : "",
        enrollment: this.$store.state.enrollment
          ? this.$store.state.enrollment
          : "",
        biospec_retention: this.$store.state.biospec_retention
          ? this.$store.state.biospec_retention
          : "",
        biospec_description: this.$store.state.biospec_description
          ? this.$store.state.biospec_description
          : "",
        last_known_status: this.$store.state.last_known_status
          ? this.$store.state.last_known_status
          : "",
        acronym: this.$store.state.acronym ? this.$store.state.acronym : "",
        results_first_submitted: this.$store.state.results_first_submitted
          ? this.$store.state.results_first_submitted
          : "",
        results_first_submitted_qc: this.$store.state.results_first_submitted_qc
          ? this.$store.state.results_first_submitted_qc
          : "",
        results_first_posted: this.$store.state.results_first_posted
          ? this.$store.state.results_first_posted
          : "",
        disposition_first_submitted: this.$store.state
          .disposition_first_submitted
          ? this.$store.state.disposition_first_submitted
          : "",
        disposition_first_submitted_qc: this.$store.state
          .disposition_first_submitted_qc
          ? this.$store.state.disposition_first_submitted_qc
          : "",
        disposition_first_posted: this.$store.state.disposition_first_posted
          ? this.$store.state.disposition_first_posted
          : "",
        target_duration: this.$store.state.target_duration
          ? this.$store.state.target_duration
          : "",
      };
      this.clinical_study.push(clinicalStudyTemp);
      await axios
        .post(this.$store.state.baseUrl + "addClinicalStudy", {
          clinical_study: this.clinical_study,
          eligibility_id: this.$store.state.eligibility_id
            ? this.$store.state.eligibility_id
            : null,
        })
        .then((response) => {
          console.log("response", response.data.clinical_study.message);
          this.clinical_study = [];
          this.clinicalStudy_id = response.data.clinical_study.id;
          this.$store.state.clinicalStudy_id = response.data.clinical_study.id;
        });
      return true;
    },
    additem,
    removeRow,
    OpenModal,
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
