<template>
  <div>
    <tab-content title="Location" icon="fa fa-map-marker" :before-change="addLocation">
    <router-link :to="{ name: 'AddLocation', params: { store: store }}">
        <b-button class="moreInfo-btn" style="background: #00557e; color: #fff;">Add Location Facility</b-button>
    </router-link>
    <div>
        <b-modal
        id="delete_record"
        centered
        title="Delete Record"
        ok-only
      >
        <div>This will delete all the associated record with this Location</div>
        <div class="d-flex justify-content-end pt-4">
          <b-button class="btn btn-success" v-on:click="deleteRecord()" @click="$bvModal.hide('delete_record')">OK</b-button>
        </div>
      </b-modal>
    </div>
      <!-- <b-button class="facility-btn" to="/add-location">Add Location Facility</b-button> -->
      <table class="table mt-3 bg-white">
        <tbody>
          <tr>
            <th>Location Status</th>
            <th>Location Facilities</th>
            <th>Location Contact</th>
            <th>Action</th>
          </tr>
        </tbody>
        <tbody>
          <tr v-for="(data, index) in allLocations" v-bind:key="index">
            <td class="form-table">
              {{ data.status}}
            </td>
            <td class="form-table">{{data.facilities.length > 1 ? (data.facilities[0].facility_name + "... +"+(data.facilities.length-1) + " More") : data.facilities.length == 1 ? data.facilities[0].facility_name : ""}}</td>
            <td class="form-table">{{data.contacts.length > 1 ? (data.contacts[0].last_name + "... +"+(data.contacts.length-1) + " More") : data.contacts.length == 1 ? data.contacts[0].last_name : ""}}</td>
            <td class="form-table">
              <button class="btn btn-danger btn-sm"
                v-on:click="removeRow(index,data)"
              >
                <i style="color:white;" class="fa fa-trash"></i>
              </button>
              <router-link :to="{ name: 'AddLocation', params: { LocationId: data.id, store: store }}">
                <button class="btn btn-secondary btn-sm">
                  <i style="color:white;" class="fa fa-pencil"></i>
                </button>
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </tab-content>
  </div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import axios from "axios";

export default {
  props: ["store","clinicalStudyID"],
  name:"Location",
  data() {
    return {
      location_index: null,
      location_id: "",
      location_status: "",
      locations:[],
      errors: [],
      isError: false,
    }
  },
  mounted() {
    if(this.clinicalStudyID){
      console.log("clinicalStudyID", this.clinicalStudyID);
      this.$store.dispatch("getLocations", this.clinicalStudyID)
    }
  },
  computed: mapGetters(["allLocations"]),
  created(){
    if(this.clinicalStudyID){
      this.$store.dispatch("getLocations", this.clinicalStudyID)
    }
  },
 methods: {
      addLocation: async function() {
        if(this.$store.getters.allLocations.length > 0) {
          await axios.post(this.$store.state.baseUrl + "addLocation", {
              location: this.$store.getters.allLocations,
              clinical_study_id: this.$store.state.clinicalStudy_id,
            })
            .then((response) => {
                this.$store.dispatch("getLocations", this.clinicalStudyID)
            });
        }
        return true;
      },
      removeRow(index) {
          this.$store.dispatch("removeLocation", index)
      },
      
      openModal(index,data) {
          this.location_index = index;
          this.location_id = data.id ? data.id : "";
          this.location_status = data.location_status;
      }
	},
};
</script>
