<template>
    <div>
        <b-button v-b-modal.location_facility centered class="facility-btn">Add Location Facility</b-button>

        <b-modal id="location_facility" centered title="Add Location Facility" ok-only>
        <p v-if="errors.length">
            <b>Please correct the following error(s):</b>
            <ul class="ErrorClass">
                <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
            </ul>
        </p>

        <b-form-group label="Location Facility:">
            <b-form-input type="text" placeholder="Add Location Facility" v-model="facility_name" autocomplete="off"></b-form-input>
        </b-form-group>
        <b-button
            class="add-facility-btn"
            v-on:click="additem()"
            >ADD</b-button
        >
        </b-modal>
        <div class="overflow-auto w-100">
          <table class="table mt-3 bg-white">
        <tbody>
            <tr>
            <th>Location Facility</th>
            <th class="w-10">Action</th>
            </tr>
        </tbody>
        <tbody>
            <tr v-for="(data,index) in allFacilities" v-bind:key="index">
            <td class="form-table">
                <div class="inner-data">
                  {{data.facility_name}}
                </div>
            </td>
            <td class="form-table">
                <div class="d-flex">
                  <b-button
                v-b-modal.delete_record
                centered
                class="btn btn-danger btn-sm"
                v-on:click="removeRow(index)"
                ><i style="color:white;" class="fa fa-trash"></i
                ></b-button>
                <button class="btn btn-secondary btn-sm"
                v-b-modal.location_facility
                v-on:click="openModal(index, data)"
                >
                <i style="color:white;" class="fa fa-pencil"></i>
                </button>
                </div>
            </td>
            </tr>
        </tbody>
        </table>
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  props: ["LocationId","Locations"],
  name:"LocationFacility",
  data() {
    console.log("HEYYYYyyy", this);
    return {
      facility_index: null,
      facility_id: "",
      facility_name: "",
      errors: [],
      isError: false,
    }
  },
  mounted() {
    if(this.LocationId){
    console.log("this.LocationId", this.LocationId);

      let tempObj = {
        locationId: this.LocationId,
        locations: this.Locations
      }
      this.$store.dispatch("getFacilities", tempObj)
    }
  },
  computed: mapGetters(["allFacilities"]),
  created(){
    if(this.LocationId){
      let tempObj = {
        locationId: this.LocationId,
        locations: this.Locations
      }
      this.$store.dispatch("getFacilities", tempObj)
    }
  },
 methods: {
      additem() {
        this.errors = [];
        this.isError = false;
        if(this.facility_name == "") {
          this.errors.push('facility name Field is required.');
          this.isError = true;
        }
        if(this.isError == false) {
          if (this.facility_index == null && this.facility_id == "") {
              var facilityTempObj = {
                  facility_name: this.facility_name ? this.facility_name : "",
              };
               this.$store.dispatch("addFacility", facilityTempObj)
          } else {
              this.allFacilities[this.facility_index] = {
                  id: this.facility_id ? this.facility_id : "",
                  facility_name: this.facility_name ? this.facility_name : "",
              };
          }

          this.facility_index = null;
          this.facility_id = "";
          this.facility_name = "";
          this.$bvModal.hide('location_facility');
        }
      },
      
      removeRow(index) {
          this.$store.dispatch("removeFacility", index)
      },
      
      openModal(index,data) {
          this.facility_index = index;
          this.facility_id = data.id ? data.id : "";
          this.facility_name = data.facility_name;
      }
	},
};
</script>
