import axios from "axios";

const state = {
    addresses: []
};
const getters = {
    allAddresses: state => state.addresses
};

const actions = {
    async getAddresses({commit},locationData){
        let locationsData = [];
        let addressesData = [];
        if(locationData.locationId){
            if(locationData.locations.length > 0){
                console.log("locationData.locationId", locationData.locationId);
                    locationsData = locationData.locations.filter((obj) => obj.id == locationData.locationId)[0];
                    let facilitesData = locationsData.facilities;
                    if(facilitesData.length > 0){
                        for(let facility of facilitesData){
                            if(facility.address.length > 0){
                                for(let address of facility.address){
                                    addressesData.push(address) 
                                }   
                            }
                        }
                        console.log("addressesData", addressesData);

                        commit("setAddresses", addressesData);
                    }
            }
        }

    },
    async addAddress({commit},address){
        commit("newAddress", address)
    },
    async removeAddress({commit},index){
        commit("removeaddress", index)
    }
};

const mutations = {
    setAddresses: (state, addresses) => (state.addresses = addresses),
    newAddress: (state, address) => (state.addresses.unshift(address)),
    removeAddress: (state, index) => (state.addresses.splice(index, 1))
};

export default {
    state,
    getters,
    mutations,
    actions
}

