

export function additem(type) {
    if (type == 'eligibilityModel') {
        console.log('additemeligibility :',this.eligibility_index);
        if(this.eligibility_index == null) {
            var eligibilityModelTempObj = {
                id: this.eligibility_id ? this.eligibility_id : "",
                criteria: this.criteria ? this.criteria : "",
                gender: this.gender ? this.gender : "",
                maximum_age: this.maximum_age ? this.maximum_age : "",
                minimum_age: this.minimum_age ? this.minimum_age : "",
                healthy_volunteers: this.healthy_volunteers ? this.healthy_volunteers : "",
                study_pop: this.study_pop ? this.study_pop : "",
                sampling_method: this.sampling_method ? this.sampling_method : "",
            };
            this.eligibility.push(eligibilityModelTempObj);
        } else {
            this.eligibility[this.eligibility_index] = {
                id: this.eligibility[this.eligibility_index].id ? this.eligibility[this.eligibility_index].id : "",
                criteria: this.criteria ? this.criteria : "",
                gender: this.gender ? this.gender : "",
                maximum_age: this.maximum_age ? this.maximum_age : "",
                minimum_age: this.minimum_age ? this.minimum_age : "",
                healthy_volunteers: this.healthy_volunteers ? this.healthy_volunteers : "",
                study_pop: this.study_pop ? this.study_pop : "",
                sampling_method: this.sampling_method ? this.sampling_method : "",
            };
        }

        this.eligibility_index = null;
        this.criteria = "";
        this.gender = "";
        this.maximum_age = "";
        this.minimum_age = "";
        this.healthy_volunteers = "";
        this.study_pop = "";
        this.sampling_method = "";
    } else if (type == 'participant_flow') {
            console.log('additemparticipant_flow :',this.$store.state.participant_flow_index);
            if (this.$store.state.participant_flow_index == null) {
                var participant_flowTempObj = {
                    recruitment_details: this.$store.state.recruitment_details ? this.$store.state.recruitment_details : "",
                    pre_assignment_details: this.$store.state.pre_assignment_details ? this.$store.state.pre_assignment_details : "",
                };
                this.$store.state.participant_flow.push(participant_flowTempObj);
            } else {
                this.$store.state.participant_flow[this.$store.state.participant_flow_index] = {
                    id: this.$store.state.participant_flow[this.$store.state.participant_flow_index].id ? this.$store.state.participant_flow[this.$store.state.participant_flow_index].id : "",
                    recruitment_details: this.$store.state.recruitment_details ? this.$store.state.recruitment_details : "",
                    pre_assignment_details: this.$store.state.pre_assignment_details ? this.$store.state.pre_assignment_details : "",
                };
            }

            this.$store.state.participant_flow_index = null;
            this.$store.state.recruitment_details = "";
            this.$store.state.pre_assignment_details = "";
        } else if (type == 'reported_event') {
            console.log('additemreported_event :',this.$store.state.reported_event_index);
            if (this.$store.state.reported_event_index == null) {
                var reported_eventTempObj = {
                    description: this.$store.state.reported_event_description ? this.$store.state.reported_event_description : "",
                    time_frame: this.$store.state.reported_event_time_frame ? this.$store.state.reported_event_time_frame : "",
                };
                this.$store.state.reported_event.push(reported_eventTempObj);
            } else {
                this.$store.state.reported_event[this.$store.state.reported_event_index] = {
                    id: this.$store.state.reported_event[this.$store.state.reported_event_index].id ? this.$store.state.reported_event[this.$store.state.reported_event_index].id : "",
                    description: this.$store.state.reported_event_description ? this.$store.state.reported_event_description : "",
                    time_frame: this.$store.state.reported_event_time_frame ? this.$store.state.reported_event_time_frame : "",
                };
            }

            this.$store.state.reported_event_index = null;
            this.$store.state.reported_event_description = "";
            this.$store.state.reported_event_time_frame = "";
        } else if (type == 'serious_event') {
            console.log('additemserious_event :',this.$store.state.serious_event_reported_event);
            if (this.$store.state.serious_event_index == null) {
                var serious_eventTempObj = {
                    default_assessment: this.$store.state.serious_event_default_assessment ? this.$store.state.serious_event_default_assessment : "",
                    default_vocabulary: this.$store.state.serious_event_default_vocabulary ? this.$store.state.serious_event_default_vocabulary : "",
                    serious_event_reported_event: this.$store.state.serious_event_reported_event ? this.$store.state.serious_event_reported_event : "",
                };
                this.$store.state.serious_event.push(serious_eventTempObj);
            } else {
                this.$store.state.serious_event[this.$store.state.serious_event_index] = {
                    id: this.$store.state.serious_event[this.$store.state.serious_event_index].id ? this.$store.state.serious_event[this.$store.state.serious_event_index].id : "",
                    default_assessment: this.$store.state.serious_event_default_assessment ? this.$store.state.serious_event_default_assessment : "",
                    default_vocabulary: this.$store.state.serious_event_default_vocabulary ? this.$store.state.serious_event_default_vocabulary : "",
                    serious_event_reported_event: this.$store.state.serious_event_reported_event ? this.$store.state.serious_event_reported_event : "",
                };
            }

            this.$store.state.serious_event_index = null;
            this.$store.state.serious_event_default_assessment = "";
            this.$store.state.serious_event_default_vocabulary = "";
            this.$store.state.serious_event_reported_event = "";
        } else if (type == 'other_event') {
            console.log('additemother_event :',this.$store.state.other_event_reported_event);
            if (this.$store.state.other_event_index == null) {
                var other_eventTempObj = {
                    default_assessment: this.$store.state.other_event_default_assessment ? this.$store.state.other_event_default_assessment : "",
                    default_vocabulary: this.$store.state.other_event_default_vocabulary ? this.$store.state.other_event_default_vocabulary : "",
                    frequency_threshold: this.$store.state.other_event_frequency_threshold ? this.$store.state.other_event_frequency_threshold : "",
                    other_event_reported_event: this.$store.state.other_event_reported_event ? this.$store.state.other_event_reported_event : "",
                };
                this.$store.state.other_event.push(other_eventTempObj);
            } else {
                this.$store.state.other_event[this.$store.state.other_event_index] = {
                    id: this.$store.state.other_event[this.$store.state.other_event_index].id ? this.$store.state.other_event[this.$store.state.other_event_index].id : "",
                    default_assessment: this.$store.state.other_event_default_assessment ? this.$store.state.other_event_default_assessment : "",
                    default_vocabulary: this.$store.state.other_event_default_vocabulary ? this.$store.state.other_event_default_vocabulary : "",
                    frequency_threshold: this.$store.state.other_event_frequency_threshold ? this.$store.state.other_event_frequency_threshold : "",
                    other_event_reported_event: this.$store.state.other_event_reported_event ? this.$store.state.other_event_reported_event : "",
                };
            }

            this.$store.state.other_event_index = null;
            this.$store.state.other_event_default_assessment = "";
            this.$store.state.other_event_default_vocabulary = "";
            this.$store.state.other_event_frequency_threshold = "";
            this.$store.state.other_event_reported_event = "";
        } else if (type == 'location') {
            console.log('additemlocation :',this.$store.state.location_index);
            if (this.$store.state.location_index == null) {
                var locationTempObj = {
                    status: this.$store.state.location_status ? this.$store.state.location_status : "",
                };
                this.$store.state.location.push(locationTempObj);
            } else {
                this.$store.state.location[this.$store.state.location_index] = {
                    id: this.$store.state.location[this.$store.state.location_index].id ? this.$store.state.location[this.$store.state.location_index].id : "",
                    status: this.$store.state.location_status ? this.$store.state.location_status : "",
                };
            }

            this.$store.state.location_index = null;
            this.$store.state.location_status = "";
        }
}

export function customAgencyfun() {
    document.getElementById("agencySelect").style.display = "none";
    document.getElementById("customAgency").style.display = "block";

    document.getElementById("selectAgencybtn").style.display = "block";
    document.getElementById("customAgencybtn").style.display = "none";
}

export function selectAgencyfun() {
    document.getElementById("agencySelect").style.display = "block";
    document.getElementById("customAgency").style.display = "none";

    document.getElementById("selectAgencybtn").style.display = "none";
    document.getElementById("customAgencybtn").style.display = "block";
}

export function removeRow(index,type) {
    if(type == 'eligibility') {
        this.eligibility.splice(index,1);
    } else if (type == 'participant_flow') {
        this.$store.state.participant_flow.splice(index,1);
    } else if (type == 'reported_event') {
        this.$store.state.reported_event.splice(index,1);
    } else if (type == 'serious_event') {
        this.$store.state.serious_event.splice(index,1);
    } else if (type == 'other_event') {
        this.$store.state.other_event.splice(index,1);
    } else if (type == 'location') {
        this.$store.state.location.splice(index,1);
    } else if (type == 'location_facility') {
        this.$store.state.location_facility.splice(index,1);
    } else if (type == 'location_investigator') {
        this.$store.state.location_investigator.splice(index,1);
    }
    
}

export function OpenModal(index,data,type) {
    if (type == 'eligibility') {
        console.log('OpenModaleligibility :',index);
        this.eligibility_index = index;
        this.maximum_age = data.maximum_age;
        this.minimum_age = data.minimum_age;
        this.gender = data.gender;
        this.healthy_volunteers = data.healthy_volunteers;
        this.sampling_method = data.sampling_method;
        this.study_pop = data.study_pop;
        this.criteria = data.criteria;
    } else if (type == 'participant_flow') {
        console.log('OpenModalparticipant_flow :',data);
        this.$store.state.participant_flow_index = index;
        this.$store.state.recruitment_details = data.recruitment_details;
        this.$store.state.pre_assignment_details = data.pre_assignment_details;
    } else if (type == 'reported_event') {
        console.log('OpenModalreported_event :',data);
        this.$store.state.reported_event_index = index;
        this.$store.state.reported_event_description = data.description;
        this.$store.state.reported_event_time_frame = data.time_frame;
    } else if (type == 'serious_event') {
        console.log('OpenModalserious_event :',data);
        this.$store.state.serious_event_index = index;
        this.$store.state.serious_event_default_assessment = data.default_assessment;
        this.$store.state.serious_event_default_vocabulary = data.default_vocabulary;
        this.$store.state.serious_event_reported_event = data.serious_event_reported_event;
    } else if (type == 'other_event') {
        console.log('OpenModalother_event :',data);
        this.$store.state.other_event_index = index;
        this.$store.state.other_event_default_assessment = data.default_assessment;
        this.$store.state.other_event_default_vocabulary = data.default_vocabulary;
        this.$store.state.other_event_frequency_threshold = data.frequency_threshold;
        this.$store.state.other_event_reported_event = data.reported_event;
    } else if (type == 'location_investigator') {
        console.log('OpenModallocation_investigator :',index);
        this.$store.state.location_investigator_index = index;
        this.$store.state.location_investigator_last_name = data.last_name;
        this.$store.state.location_investigator_role = data.role;
    }
}

export function addLocationObj(id) {
    var locationStatusTempObj = {
        status: this.$store.state.location_status ? this.$store.state.location_status : "",
        location_facility: this.$store.state.location_facility ? this.$store.state.location_facility : [],
        location_contact: this.$store.state.location_contact ? this.$store.state.location_contact : [],
        location_contact_backup: this.$store.state.location_contact_backup ? this.$store.state.location_contact_backup : [],
        location_investigator: this.$store.state.location_investigator ? this.$store.state.location_investigator : [],
    }
    if(id == "") {
        this.$store.state.location.push(locationStatusTempObj);
    } else {
        this.$store.state.location[id].push(locationStatusTempObj);
    }
}
