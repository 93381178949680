<template>
  <div class="col-12 mt-2">
    <b-button v-b-toggle.collapse-17 class="accordion-btn listing-btn"
      >Arm Groups <i class="fa fa-chevron-down ml-3"></i
    ></b-button>
    <b-collapse id="collapse-17" class="mt-3">
      <b-card>
        <table class="table table-striped" v-if="data.arm_groups && data.arm_groups.length > 0">
          <tbody v-for="(arm,index) in data.arm_groups" v-bind:key="arm">
              <tr v-if="data.arm_groups.length > 0">
              <!-- <td class="table-data-heading">
                Arm Group Label:
              </td> -->
              <td class="table-data">
               {{ index+1 }}
              </td>
            </tr>
            <tr v-if="data.arm_groups.length > 0 && arm.arm_group_label != '' ">
              <td class="table-data-heading">
                Arm Group Label:
              </td>
              <td class="table-data">
               {{ arm.arm_group_label}}
              </td>
            </tr>
            <tr v-if="data.arm_groups.length > 0 && arm.arm_group_type != '' ">
              <td class="table-data-heading">
                Arm Group Name:
              </td>
              <td class="table-data">
               {{ arm.arm_group_type}}
              </td>
            </tr>
            <tr v-if="data.arm_groups.length > 0 && arm.description != '' ">
              <td class="table-data-heading">
                Arm Group Description:
              </td>
              <td class="table-data">
               {{ arm.description}}
              </td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </b-collapse>
  </div>
</template>
<script>
  export default {
    props: ["data"],
  }
</script>