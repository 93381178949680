import axios from "axios";

const state = {
    contacts: []
};
const getters = {
    allContacts: state => state.contacts
};

const actions = {
    async getContacts({commit},locationData){
        let contactsData = [];
        if(locationData.locationId){
            if(locationData.locations.length > 0){
                if(locationData.locationId){
                    contactsData = locationData.locations.filter((obj) => obj.id == locationData.locationId)[0]
                    console.log("contactsData", contactsData.contacts);
        
                    commit("setContacts", contactsData.contacts);
                }
            }
        }
    },
    async addContact({commit},contact){
        console.log("contact", contact);
        commit("newContact", contact)
    },
    async removeContact({commit},index){
        commit("removeContact", index)
    }
};

const mutations = {
    setContacts: (state, contacts) => (state.contacts = contacts),
    newContact: (state, contact) => (state.contacts.unshift(contact)),
    removeContact: (state, index) => (state.contacts.splice(index, 1))
};

export default {
    state,
    getters,
    mutations,
    actions
}

