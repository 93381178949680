<template>
  <div class="col-12 mt-2">
    <b-button v-b-toggle.collapse-2 class="accordion-btn listing-btn"
      >Study Design <i class="fa fa-chevron-down ml-3"></i
    ></b-button>
    <b-collapse id="collapse-2" class="mt-3">
      <b-card>
        <table class="table table-striped">
          <tbody>
            <tr v-if="data.study_type != '' ">
              <td class="table-data-heading">
                Study Type:
              </td>
              <td class="table-data">
                {{ data.study_type}}
              </td>
            </tr>
            <tr v-if="data.enrollment != '' ">
              <td class="table-data-heading">
                Estimated Enrollment: 
              </td>
              <td class="table-data">
                {{ data.enrollment}} participants
              </td>
            </tr>
            <tr v-if="data.study_design_infos.length > 1 && data.study_design_infos[0].observational_model != '' ">
              <td class="table-data-heading">
                Observational Model:
              </td>
              <td class="table-data">
                {{ data.study_design_infos[0].observational_model}}
              </td>
            </tr>
            <tr v-if="data.study_design_infos.length > 1 && data.study_design_infos[0].time_perspective != '' ">
              <td class="table-data-heading">
                Time Perspective:
              </td>
              <td class="table-data">
                {{ data.study_design_infos[0].time_perspective}}
              </td>
            </tr>
            <tr v-if="data.official_title != '' ">
              <td class="table-data-heading">
                Official Title:
              </td>
              <td class="table-data">
                {{ data.official_title}}
              </td>
            </tr>
            <tr v-if="data.start_date != '' ">
              <td class="table-data-heading">
                Actual Study Start Date:
              </td>
              <td class="table-data">
                {{ data.start_date}}
              </td>
            </tr>
            <tr v-if="data.primary_completion_date != '' ">
              <td class="table-data-heading">
                Estimated Primary Completion Date:
              </td>
              <td class="table-data">
                {{ data.primary_completion_date}}
              </td>
            </tr>
            <tr v-if="data.completion_date != '' ">
              <td class="table-data-heading">
                Estimated Study Completion Date:
                
              </td>
              <td class="table-data">
                {{ data.completion_date}}
              </td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </b-collapse>
  </div>
</template>
<script>
  export default {
    props: ["data"],
  }
</script>