<template>
    <div>
        <b-button
          v-b-modal.location_facility_address
          centered
          class="facility-btn"
          >Add Location Facility Address</b-button
        >
        <b-modal
          id="location_facility_address"
          centered
          title="Add Location Facility Address"
          ok-only>
          <p v-if="errors.length">
              <b>Please correct the following error(s):</b>
              <ul class="ErrorClass">
                  <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
              </ul>
          </p>
          <select class="about-select form-control" v-model="address_facility">
            <option value="">Select Facility</option>
            <option v-bind:value="facility.id" v-for="facility in allFacilities" v-bind:key="facility.id">{{ facility.facility_name }}</option>
          </select>
          <div class="row pt-3">
            <div class="col-md-6 col-12">
              <b-form-group label="Country:">
                <b-form-input
                  type="text"
                  placeholder="Add Country"
                  v-model="facility_address_country"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 col-12">
              <b-form-group label="State:">
                <b-form-input
                  type="text"
                  v-model="facility_address_state"
                  placeholder="Add State"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-12">
              <b-form-group label="City:">
                <b-form-input
                  type="text"
                  v-model="facility_address_city"
                  placeholder="Add City"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 col-12">
              <b-form-group label="Zip Code:">
                <b-form-input
                  type="text"
                  v-model="facility_address_zip"
                  placeholder="Add Zip Code"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-12">
              <b-form-group label="Lat:">
                <b-form-input
                  type="text"
                  v-model="facility_address_lat"
                  placeholder="Add Lat"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 col-12">
              <b-form-group label="Long:">
                <b-form-input
                  type="text"
                  v-model="facility_address_lng"
                  placeholder="Add Long"
                  autocomplete="off"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <b-button
            class="add-facility-btn"
            v-on:click="additem()"
            >ADD</b-button
          >
        </b-modal>
        <div class="overflow-auto w-100">
          <table class="table mt-3 bg-white">
            <tbody>
              <tr>
                <th>Facility</th>
                <th>Country</th>
                <th>State</th>
                <th>City</th>
                <th>Zip Code</th>
                <th class="w-10">Action</th>
              </tr>
            </tbody>
            <tbody>
              <tr v-for="(data,index) in allAddresses" v-bind:key="index">
                <td class="form-table">
                    <div class="inner-data">
                      {{ data.country}}
                    </div>
                </td>
                <td class="form-table">
                    <div class="inner-data">
                      {{data.state}}
                    </div>
                </td>
                <td class="form-table">
                    <div class="inner-data">
                      {{ data.city}}
                    </div>
                </td>
                <td class="form-table">
                    <div class="inner-data">
                      {{ data.zip}}
                    </div>
                  </td>
                <td class="form-table">
                    <div class="inner-data">
                      {{ allFacilities.length > 0 ? allFacilities.filter(obj=>obj.id == data.facility_id)[0].facility_name : ""}}
                    </div>
                  </td>
                <td class="form-table">
                  <div class="d-flex">
                    <button class="btn btn-danger btn-sm"
                  v-on:click="removeRow(index)">
                    <i style="color:white;" class="fa fa-trash"></i>
                  </button>
                  <button class="btn btn-secondary btn-sm"
                  v-b-modal.location_facility_address
                  v-on:click="openModal(index,data)">
                    <i style="color:white;" class="fa fa-pencil"></i>
                  </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  props: ["LocationId","Locations"],
  name:"LocationFacilityAddress",
  data() {
    return {
      facility_address_index: null,
      facility_address_id: "",
      address_facility: "",
      facility_address_country: "",
      facility_address_state: "",
      facility_address_city: "",
      facility_address_zip: "",
      facility_address_lat: "",
      facility_address_lng: "",
      errors: [],
      isError: false,
    }
  },
  mounted() {
    if(this.LocationId){
    console.log("this.LocationId", this.LocationId);

      let tempObj = {
        locationId: this.LocationId,
        locations: this.Locations
      }
      this.$store.dispatch("getAddresses", tempObj)
    }
  },
  computed: mapGetters(["allAddresses","allFacilities"]),
  created(){
    if(this.LocationId){
      let tempObj = {
        locationId: this.LocationId,
        locations: this.Locations
      }
      this.$store.dispatch("getAddresses", tempObj)
    }
  },
 methods: {
      additem() {
        this.errors = [];
        this.isError = false;
        if(this.address_facility == "" && this.facility_address_country == "") {
          this.errors.push('Facility and Country Fields are required.');
          this.isError = true;
        }
        if(this.isError == false) {
          if (this.facility_address_index == null && this.facility_address_id == "") {
              var facilityTempObj = {
                  country: this.facility_address_country ? this.facility_address_country : "",
                  state: this.facility_address_state ? this.facility_address_state : "",
                  city: this.facility_address_city ? this.facility_address_city : "",
                  zip: this.facility_address_zip ? this.facility_address_zip : "",
                  lat: this.facility_address_lat ? this.facility_address_lat : "",
                  lng: this.facility_address_lng ? this.facility_address_lng : "",
                  facility_name: this.address_facility ? this.address_facility : "",
              };
               this.$store.dispatch("addAddress", facilityTempObj)
          } else {
              this.allAddresses[this.facility_address_index] = {
                  id: this.facility_address_id ? this.facility_address_id : "",
                  country: this.facility_address_country ? this.facility_address_country : "",
                  state: this.facility_address_state ? this.facility_address_state : "",
                  city: this.facility_address_city ? this.facility_address_city : "",
                  zip: this.facility_address_zip ? this.facility_address_zip : "",
                  lat: this.facility_address_lat ? this.facility_address_lat : "",
                  lng: this.facility_address_lng ? this.facility_address_lng : "",
                  facility_name: this.address_facility ? this.address_facility : "",
              };
          }

          this.facility_address_index = null;
          this.facility_address_id = "";
          this.address_facility = "";
          this.facility_address_country = "";
          this.facility_address_state = "";
          this.facility_address_city = "";
          this.facility_address_zip = "";
          this.facility_address_lat = "";
          this.facility_address_lng = "";
          this.$bvModal.hide('location_facility_address')
        }
      },
      
      removeRow(index) {
          this.$store.dispatch("removeAddress", index)
      },
      
      openModal(index,data) {
          this.facility_address_index = index;
          this.address_facility = data.facility_id ? data.facility_id : "";
          this.facility_address_id = data.id ? data.id : "";
          this.facility_address_country = data.country;
          this.facility_address_state = data.state;
          this.facility_address_city = data.city;
          this.facility_address_zip = data.zip;
          this.facility_address_lat = data.lat;
          this.facility_address_lng = data.lng;
      }
	},
};
</script>