import axios from "axios";

const state = {
    conditions: []
};
const getters = {
    allConditions: state => state.conditions
};

const actions = {
    async getConditions({commit},id){
        const {data} = await axios.post("http://localhost:8000/api/clinical_study", {
            clinical_study_id: id,
        })
        console.log("Data Conditions", data);
        commit("setConditions", data.data.conditions)
    },
    async addCondition({commit},condition){
        commit("newCondition", condition)
    },
    async removeCondition({commit},index){
        console.log("indx",index);
        commit("removeCondition", index)
    }
};

const mutations = {
    setConditions: (state, conditions) => (state.conditions = conditions),
    newCondition: (state, condition) => (state.conditions.unshift(condition)),
    removeCondition: (state, index) => (state.conditions.splice(index, 1))
};

export default {
    state,
    getters,
    mutations,
    actions
}

