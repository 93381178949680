<template>
  <div class="col-12 mt-2">
    <b-button v-b-toggle.collapse-4 class="accordion-btn listing-btn"
      >Eligibility Criteria <i class="fa fa-chevron-down ml-3"></i
    ></b-button>
    <b-collapse id="collapse-4" class="mt-3">
      <b-card>
        <table class="table table-striped">
          <tbody>
            <tr v-if="data.eligibility.minimum_age != '' && data.eligibility.minimum_age != 'N/A' && data.eligibility.maximum_age != '' && data.eligibility.maximum_age != 'N/A' ">
              <td class="table-data-heading">
                <i class="fa fa-birthday-cake mr-2"></i>Ages eligible for Study:
              </td>
              <td class="table-data">
                {{ data.eligibility.minimum_age}} to {{ data.eligibility.maximum_age}}
              </td>
            </tr>
            <tr v-else-if="data.eligibility.maximum_age != '' && data.eligibility.maximum_age != 'N/A' ">
              <td class="table-data-heading">
                <i class="fa fa-birthday-cake mr-2"></i>Ages eligible for Study:
              </td>
              <td class="table-data">
                under {{ data.eligibility.maximum_age}} old
              </td>
            </tr>
            <tr v-else-if="data.eligibility.minimum_age != '' && data.eligibility.minimum_age != 'N/A' ">
              <td class="table-data-heading">
                <i class="fa fa-birthday-cake mr-2"></i>Ages eligible for Study:
              </td>
              <td class="table-data">
                over {{ data.eligibility.minimum_age}} old
              </td>
            </tr>
            <tr v-if="data.eligibility.gender != '' ">
              <td class="table-data-heading">
                <i class="fa fa-venus-mars mr-2"></i>Genders eligible for Study:
              </td>
              <td class="table-data">{{ data.eligibility.gender}}</td>
            </tr>
            <tr v-if="data.eligibility.healthy_volunteers != '' ">
              <td class="table-data-heading">
                <i class="fa fa-male mr-2"></i>Accepts Healthy Volunteers:
              </td>
              <td class="table-data">{{ data.eligibility.healthy_volunteers}}</td>
            </tr>
            <tr v-if="data.eligibility.sampling_method != '' ">
              <td class="table-data-heading">
                <i class="fa fa-male mr-2"></i>Sampling Method:
              </td>
              <td class="table-data">{{ data.eligibility.sampling_method}}</td>
            </tr>
            <tr v-if="data.eligibility.study_pop != '' ">
              <td class="table-data-heading">Study Population</td>
              <td class="table-data">
                {{ data.eligibility.study_pop}}
              </td>
            </tr>
            <tr v-if="data.eligibility.criteria != '' ">
              <td class="table-data-heading">Criteria:</td>
              <td class="table-data">
                {{ data.eligibility.criteria}}
              </td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </b-collapse>
  </div>
</template>
<script>
  export default {
    props: ["data"],
  }
</script>