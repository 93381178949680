import Vue from 'vue'
// import axios from 'axios'
import Vuex from 'vuex'

Vue.use(Vuex)

const addEditStore = new Vuex.Store({
    state:  {
        study_doc_index: null,
        study_doc: "",
        doc_type: "",
        doc_url: "",
        doc_comment: "",
        study_docs: [],
    },

    mutations: {

    },

    actions: {

    },

    getters: {

    }
});

export default addEditStore