<template>
  <b-navbar toggleable="md" type="dark" variant="dark" animated animation="2">
    <div class="container navbar-container">
      <b-navbar-brand href="#"
        ><img :src="'./images/Logo.png'" alt=""
      /></b-navbar-brand>

      <b-navbar-toggle target="nav-collapse">
        <template #default="{ expanded }">
          <b-icon v-if="expanded" icon="x"></b-icon>
          <b-icon v-else icon="justify-right"></b-icon>
        </template>
      </b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item class="pr-2" to="/search">Search</b-nav-item>
          <b-nav-item class="pr-2" to="/for-patients">For Patients</b-nav-item>
          <b-nav-item class="pr-2">For Industry</b-nav-item>
          <b-nav-item class="pr-2">About Us</b-nav-item>
          <b-nav-item class="pr-2">Foundation</b-nav-item>
          <b-nav-item class="pr-2">Log In</b-nav-item>

          <b-nav-item-dropdown text="" right>
            <template v-slot:button-content>
              <i class="fa fa-globe pr-1"></i>
            </template>
            <b-dropdown-item href="#">English</b-dropdown-item>
            <b-dropdown-item href="#">Deutsch</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
export default {
  name: "Navbar",
};
</script>
