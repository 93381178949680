<template>
    <tab-content title="Contact" icon="fa fa-phone" :before-change="addContact">
        <div>
            <b-button v-b-modal.overall_contact class="facility-btn"
                >Add Overall Contact</b-button
            >

            <b-modal id="overall_contact" centered title="Add New Overall Contact">
                <p v-if="errors.length">
                    <b>Please correct the following error(s):</b>
                    <ul class="ErrorClass">
                        <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                    </ul>
                </p>
                <input
                    type="hidden"
                    v-model="store.state.overall_contact_index"
                    autocomplete="off"
                />

                <b-form-group label="Last Name:">
                    <b-form-input
                        type="text"
                        placeholder="Add Last Name"
                        v-model="store.state.overall_contact_last_name"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Email:">
                    <input
                        type="email"
                        placeholder="Add Email"
                        class="form-control"
                        v-model="store.state.overall_contact_email"
                        autocomplete="off"
                    />
                </b-form-group>

                <b-form-group label="Phone:">
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Add Phone"
                        v-model="store.state.overall_contact_phone"
                        autocomplete="off"
                    />
                </b-form-group>

                <b-form-group label="Phone Ext:">
                    <b-form-input
                        type="text"
                        placeholder="Add Phone Ext"
                        v-model="store.state.overall_contact_phone_ext"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-button
                    class="add-facility-btn"
                    v-on:click="additem('overall_contact')"
                    >ADD</b-button
                >
            </b-modal>
        </div>
        <div class="overflow-auto w-100">
            <table class="table mt-3 bg-white">
                <tbody>
                    <tr>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Phone Ext</th>
                        <th class="w-10">Action</th>
                    </tr>
                </tbody>
                <tbody>
                    <tr
                        v-for="(data, index) in store.state.overall_contact"
                        v-bind:key="data.measure"
                    >
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.last_name }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.email }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.phone }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.phone_ext }}
                            </div>
                        </td>
                        <td class="form-table">
                            <button
                                class="btn btn-danger btn-sm"
                                v-on:click="removeRow(index, 'overall_contact')"
                            >
                                <i style="color:white;" class="fa fa-trash"></i>
                            </button>
                            <button
                                class="btn btn-secondary btn-sm"
                                v-b-modal.overall_contact
                                v-on:click="openModal(index, data, 'overall_contact')"
                            >
                                <i style="color:white;" class="fa fa-pencil"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div>
            <b-button v-b-modal.overall_contact_backup class="facility-btn"
                >Add Overall Contact Backup</b-button
            >

            <b-modal
                id="overall_contact_backup"
                centered
                title="Add New Overall Contact Backup"
            >
                <p v-if="errors.length">
                    <b>Please correct the following error(s):</b>
                    <ul class="ErrorClass">
                        <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                    </ul>
                </p>
                <input
                    type="hidden"
                    v-model="store.state.overall_contact_backup_index"
                    autocomplete="off"
                />

                <b-form-group label="Last Name:">
                    <b-form-input
                        type="text"
                        placeholder="Add Last Name"
                        v-model="store.state.overall_contact_backup_last_name"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Email:">
                    <input
                        type="email"
                        class="form-control"
                        placeholder="Add Email"
                        v-model="store.state.overall_contact_backup_email"
                        autocomplete="off"
                    />
                </b-form-group>

                <b-form-group label="Phone:">
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Add Phone"
                        v-model="store.state.overall_contact_backup_phone"
                        autocomplete="off"
                    />
                </b-form-group>

                <b-form-group label="Phone Ext:">
                    <b-form-input
                        type="text"
                        placeholder="Add Phone Ext"
                        v-model="store.state.overall_contact_backup_phone_ext"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-button
                    class="add-facility-btn"
                    v-on:click="additem('overall_contact_backup')"
                    >ADD</b-button
                >
            </b-modal>
        </div>
        <div class="overflow-auto w-100">
            <table class="table mt-3 bg-white">
                <tbody>
                    <tr>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Phone Ext</th>
                        <th class="w-10">Action</th>
                    </tr>
                </tbody>
                <tbody>
                    <tr
                        v-for="(data, index) in store.state.overall_contact_backup"
                        v-bind:key="data.measure"
                    >
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.last_name }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.last_name }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.phone }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.phone_ext }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                <button
                                class="btn btn-danger btn-sm"
                                v-on:click="removeRow(index, 'overall_contact_backup')"
                            >
                                <i style="color:white;" class="fa fa-trash"></i>
                            </button>
                            <button
                                class="btn btn-secondary btn-sm"
                                v-b-modal.overall_contact_backup
                                v-on:click="openModal(index, data, 'overall_contact_backup')"
                            >
                                <i style="color:white;" class="fa fa-pencil"></i>
                            </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </tab-content>
</template>
<script>
import axios from "axios";
export default {
    props:["store"],
    data() {
        return {
            errors: [],
            isError: false,
        }
    },
    methods: {
        addContact: async function() {
            if(this.$store.state.overall_contact.length > 0 || this.$store.state.overall_contact_backup.length > 0) {
                await axios.post(this.$store.state.baseUrl + "addContact", {
                    overall_contact: this.$store.state.overall_contact,
                    overall_contact_backup: this.$store.state.overall_contact_backup,
                    clinical_study_id: this.$store.state.clinicalStudy_id,
                })
                .then((response) => {
                    this.$store.state.overall_contact = [];
                    this.$store.state.overall_contact_backup = [];

                    this.$store.state.overall_contact = response.data.overall_contact;
                    this.$store.state.overall_contact_backup = response.data.overall_contact_backup;

                    // this.$store.state.overall_contact_index = response.data.overall_contact.id;
                    // this.$store.state.overall_contact_backup_index = response.data.overall_contact_backup.id;

                });
            }
            return true;
        },

        additem(type) {
            this.errors = [];
            this.isError = false;
            if (type == 'overall_contact') {
                if(this.$store.state.overall_contact_last_name == "" && this.$store.state.overall_contact_email == "" && this.$store.state.overall_contact_phone == ""
                && this.$store.state.overall_contact_phone_ext == "") {
                    this.errors.push('Please fill atleast one field.');
                    this.isError = true;
                }
                if(this.isError == false) {
                    if (this.$store.state.overall_contact_index == null && this.$store.state.overall_contact_id == "") {
                        var overall_contactTempObj = {
                            last_name: this.$store.state.overall_contact_last_name ? this.$store.state.overall_contact_last_name : "",
                            phone: this.$store.state.overall_contact_phone ? this.$store.state.overall_contact_phone : "",
                            email: this.$store.state.overall_contact_email ? this.$store.state.overall_contact_email : "",
                            phone_ext: this.$store.state.overall_contact_phone_ext ? this.$store.state.overall_contact_phone_ext : "",
                        };
                        this.$store.state.overall_contact.push(overall_contactTempObj);
                    } else {
                        this.$store.state.overall_contact[this.$store.state.overall_contact_index] = {
                            id: this.$store.state.overall_contact_id ? this.$store.state.overall_contact_id : "",
                            last_name: this.$store.state.overall_contact_last_name ? this.$store.state.overall_contact_last_name : "",
                            phone: this.$store.state.overall_contact_phone ? this.$store.state.overall_contact_phone : "",
                            email: this.$store.state.overall_contact_email ? this.$store.state.overall_contact_email : "",
                            phone_ext: this.$store.state.overall_contact_phone_ext ? this.$store.state.overall_contact_phone_ext : "",
                        };
                    }

                    this.$store.state.overall_contact_index = null;
                    this.$store.state.overall_contact_last_name = "";
                    this.$store.state.overall_contact_id = "";
                    this.$store.state.overall_contact_phone = "";
                    this.$store.state.overall_contact_email = "";
                    this.$store.state.overall_contact_phone_ext = "";
                    this.$bvModal.hide('overall_contact')
                }
            } else if (type == 'overall_contact_backup') {
                if(this.$store.state.overall_contact_backup_last_name == "" && this.$store.state.overall_contact_backup_email == "" && this.$store.state.overall_contact_backup_phone == ""
                && this.$store.state.overall_contact_backup_phone_ext == "") {
                    this.errors.push('Please fill atleast one field.');
                    this.isError = true;
                }
                if(this.isError == false) {
                    if (this.$store.state.overall_contact_backup_index == null && this.$store.state.overall_contact_backup_id == "") {
                        var overall_contact_backupTempObj = {
                            last_name: this.$store.state.overall_contact_backup_last_name ? this.$store.state.overall_contact_backup_last_name : "",
                            phone: this.$store.state.overall_contact_backup_phone ? this.$store.state.overall_contact_backup_phone : "",
                            email: this.$store.state.overall_contact_backup_email ? this.$store.state.overall_contact_backup_email : "",
                            phone_ext: this.$store.state.overall_contact_backup_phone_ext ? this.$store.state.overall_contact_backup_phone_ext : "",
                        };
                        this.$store.state.overall_contact_backup.push(overall_contact_backupTempObj);
                    } else {
                        this.$store.state.overall_contact_backup[this.$store.state.overall_contact_backup_index] = {
                            id: this.$store.overall_contact_backup_id ? this.$store.overall_contact_backup_id : "",
                            last_name: this.$store.state.overall_contact_backup_last_name ? this.$store.state.overall_contact_backup_last_name : "",
                            phone: this.$store.state.overall_contact_backup_phone ? this.$store.state.overall_contact_backup_phone : "",
                            email: this.$store.state.overall_contact_backup_email ? this.$store.state.overall_contact_backup_email : "",
                            phone_ext: this.$store.state.overall_contact_backup_phone_ext ? this.$store.state.overall_contact_backup_phone_ext : "",
                        };
                    }

                    this.$store.state.overall_contact_backup_index = null;
                    this.$store.state.overall_contact_backup_id = "";
                    this.$store.state.overall_contact_backup_last_name = "";
                    this.$store.state.overall_contact_backup_phone = "";
                    this.$store.state.overall_contact_backup_email = "";
                    this.$store.state.overall_contact_backup_phone_ext = "";
                    this.$bvModal.hide('overall_contact_backup')
                }
            }
        },

        removeRow(index,type) {
            if (type == 'overall_contact') {
                this.$store.state.overall_contact.splice(index,1);
            } else if (type == 'overall_contact_backup') {
                this.$store.state.overall_contact_backup.splice(index,1);
            }
        },

        openModal(index,data,type) {
            if (type == 'overall_contact') {
                this.$store.state.overall_contact_index = index;
                this.$store.state.overall_contact_id = data.id ? data.id : "";
                this.$store.state.overall_contact_last_name = data.last_name;
                this.$store.state.overall_contact_phone = data.phone;
                this.$store.state.overall_contact_email = data.email;
                this.$store.state.overall_contact_phone_ext = data.phone_ext;
            } else if (type == 'overall_contact_backup') {
                this.$store.state.overall_contact_backup_index = index;
                this.$store.state.overall_contact_backup_id = data.id ? data.id : "";
                this.$store.state.overall_contact_backup_last_name = data.last_name;
                this.$store.state.overall_contact_backup_phone = data.phone;
                this.$store.state.overall_contact_backup_email = data.email;
                this.$store.state.overall_contact_backup_phone_ext = data.phone_ext;
            }
        }
    },
};
</script>
