<template>
	<tab-content title="Sponsors" icon="fa fa-signing" :before-change="addSponsor">
		<div>
			<b-button v-b-modal.sponsors class="facility-btn">Add Sponsor</b-button>

			<b-modal id="sponsors" centered title="Add New Sponsor">
				<p v-if="errors.length">
					<b>Please correct the following error(s):</b>
					<ul class="ErrorClass">
						<li v-for="error in errors" v-bind:key="error">{{ error }}</li>
					</ul>
				</p>
				<div>
					<b-form-group label="Agency Name:">
						<b-form-input
							type="text"
							placeholder="Add Agency Name"
							v-model="agency_name"
							autocomplete="off"
						></b-form-input>
					</b-form-group>
					<b-form-group label="Agency Class:">
						<b-form-input
							type="text"
							placeholder="Add Agency Class"
							v-model="agency_class"
							autocomplete="off"
						></b-form-input>
					</b-form-group>
                    <b-form-group label="Select Sponsor Type:">
                        <select
                            class="about-select form-control"
                            v-model="type"
                            required
                        >
                            <option value="lead_sponsor">Lead Sponsor</option>
                            <option value="colaborator_sponsor">Colaborator Sponsor</option>
                        </select>
                    </b-form-group>
				</div>
				<b-button
					class="add-facility-btn"
					v-on:click="additem()"
					>ADD</b-button
				>
			</b-modal>
		</div>
		<div class="overflow-auto w-100">
			<table class="table mt-3 bg-white">
			<tbody>
				<tr>
					<th>Agency Name</th>
					<th>Agency Class</th>
					<th>Type</th>
					<th class="w-10">Action</th>
				</tr>
			</tbody>
			<tbody>
				<tr v-for="(data, index) in allSponsors" v-bind:key="index">
					<td class="form-table">
						<div class="inner-data">
							{{ data.agency }}
						</div>
					</td>
					<td class="form-table">
						<div class="inner-data">
							{{ data.agency_class }}
						</div>
					</td>
					<td class="form-table">
						<div class="inner-data">
							{{ data.type }}
						</div>
					</td>
					<td class="form-table">
						<div class="d-flex">
							<button
							class="btn btn-danger btn-sm"
							v-on:click="removeRow(index)"
						>
							<i style="color:white;" class="fa fa-trash"></i>
						</button>
						<button
							class="btn btn-secondary btn-sm"
							v-b-modal.sponsors
							v-on:click="openModal(index, data)"
						>
							<i style="color:white;" class="fa fa-pencil"></i>
						</button>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		</div>
	</tab-content>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  props: ["store","clinicalStudyID"],
  name:"Sponsors",
  data() {
    console.log("HEYYY", this.clinicalStudyID);
    return {
		sponsor_index: null,
		sponsor_id: "",
		agency_name: "",
		agency_class: "",
		type: "",
		errors: [],
		isError: false,
    }
  },
  mounted() {
    if(this.clinicalStudyID){
      this.$store.dispatch("getSponsors", this.clinicalStudyID)
    }
  },
  computed: mapGetters(["allSponsors"]),
  created(){
    if(this.clinicalStudyID){
      this.$store.dispatch("getSponsors", this.clinicalStudyID)
    }
  },
 methods: {
    addSponsor: async function() {
      if(this.allSponsors.length > 0) {
				await axios.post(this.$store.state.baseUrl + "addSponsor", {
						sponsor: this.allSponsors,
            clinical_study_id: this.$store.state.clinicalStudy_id,
					})
					.then((response) => {
            this.$store.dispatch("getSponsors", this.clinicalStudyID)
					});
        }
        return true;
      },

      additem() {
        this.errors = [];
        this.isError = false;
        console.log("yes", this.agency_name);
        if(this.agency_name == "") {
          this.errors.push('Agency name Field is required.');
          this.isError = true;
        }
        if(this.isError == false) {
          if (this.sponsor_index == null && this.sponsor_id == "") {
              var sponsorTempObj = {
				agency: this.agency_name ? this.agency_name : "",
				agency_class: this.agency_class ? this.agency_class : "",
				type: this.type ? this.type : "",
              };
               this.$store.dispatch("addSponsor", sponsorTempObj)
          } else {
              this.allSponsors[this.sponsor_index] = {
					id: this.sponsor_id ? this.sponsor_id : "",
					agency: this.agency_name ? this.agency_name : "",
					agency_class: this.agency_class ? this.agency_class : "",
					type: this.type ? this.type : "",
              };
          }

          this.sponsor_index = null;
          this.sponsor_id = "";
          this.agency_name = "";
          this.agency_class = "";
          this.type = "";
          this.$bvModal.hide('sponsors');
        }
      },
      
      removeRow(index) {
          this.$store.dispatch("removeSponsor", index)
      },
      
      openModal(index,data) {
          this.sponsor_index = index;
          this.sponsor_id = data.id ? data.id : "";
          this.agency_name = data.agency;
          this.agency_class = data.agency_class;
          this.agency_name = data.agency;
          this.type = data.type;
      }
	},
};
</script>
