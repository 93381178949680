import axios from "axios";
const state = {
    investigators: []
};
const getters = {
    allInvestigators: state => state.investigators
};

const actions = {
    async getInvestigators({commit},locationData){
        let contactsData = [];
        if(locationData.locationId){
            if(locationData.locations.length > 0){
                if(locationData.locationId){
                    contactsData = locationData.locations.filter((obj) => obj.id == locationData.locationId)[0]
                    console.log("contacts backup Data", contactsData.investigators);
        
                    commit("setInvestigators", contactsData.investigators);
                }
            }
        }
    },
    async addInvestigator({commit},investigator){
        commit("newInvestigator", investigator)
    },
    async removeInvestigator({commit},index){
        commit("removeInvestigator", index)
    }
};

const mutations = {
    setInvestigators: (state, investigators) => (state.investigators = investigators),
    newInvestigator: (state, investigator) => (state.investigators.unshift(investigator)),
    removeInvestigator: (state, index) => (state.investigators.splice(index, 1))
};

export default {
    state,
    getters,
    mutations,
    actions
}

