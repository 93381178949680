<template>
    <tab-content title="Arm" icon="fa fa-american-sign-language-interpreting" :before-change="addArmGroup">
        <div>
            <b-button v-b-modal.add-arm-group class="facility-btn"
                >Add Arm Group</b-button
            >
                <div>
                    <b-modal
                    id="delete_record"
                    centered
                    title="Delete Record"
                    ok-only
                  >
                    <div>You can not delete this record as you have associated data with this record</div>
                    <div class="d-flex justify-content-end pt-4">
                      <b-button class="btn btn-success" v-on:click="closeModel()" @click="$bvModal.hide('delete_record')">OK</b-button>
                    </div>
                  </b-modal>
                </div>

            <b-modal id="add-arm-group" centered title="Add New Arm Group">
                <p v-if="errors.length">
                    <b>Please correct the following error(s):</b>
                    <ul class="ErrorClass">
                        <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                    </ul>
                </p>
                <input type="hidden" v-model="store.state.arm_group_index" autocomplete="off" />

                <b-form-group>
                    <label class="mendatoryLabel">Arm Group Label: <span class="mendatoryField">*</span></label>
                    <b-form-input
                        type="text"
                        placeholder="Add Label"
                        v-model="store.state.arm_group_label"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-form-group>
                    <label class="mendatoryLabel">Arm Group Type: <span class="mendatoryField">*</span></label>
                    <b-form-input
                        type="text"
                        placeholder="Add Type"
                        v-model="store.state.arm_group_type"
                        autocomplete="off"
                    ></b-form-input>
                </b-form-group>

                <b-form-group label="Arm Group Description:">
                    <b-form-textarea
                        type="text"
                        cols="30"
                        rows="5"
                        v-model="store.state.arm_group_description"
                    ></b-form-textarea>
                </b-form-group>

                <b-button
                    class="add-facility-btn"
                    v-on:click="additem('arm_group')"
                    >ADD</b-button
                >
            </b-modal>
        </div>
        <div class="overflow-auto w-100">
            <table class="table mt-3 bg-white">
                <tbody>
                    <tr>
                        <th>Arm Group Label</th>
                        <th>Arm Group Type</th>
                        <th>Arm Group Description</th>
                        <th class="w-10">Action</th>
                    </tr>
                </tbody>
                <tbody>
                    <tr
                        v-for="(data, index) in store.state.arm_group"
                        v-bind:key="data.arm_group_label"
                    >
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.arm_group_label }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.arm_group_type }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="inner-data">
                                {{ data.description }}
                            </div>
                        </td>
                        <td class="form-table">
                            <div class="d-flex">
                                <button
                                class="btn btn-danger btn-sm"
                                v-on:click="removeRow(index, data,'arm_group')"
                            >
                                <i style="color:white;" class="fa fa-trash"></i>
                            </button>
                            <button
                                class="btn btn-secondary btn-sm"
                                v-b-modal.add-arm-group
                                v-on:click="openModal(index, data, 'arm_group')"
                            >
                                <i style="color:white;" class="fa fa-pencil"></i>
                            </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </tab-content>
</template>
<script>
import axios from "axios";
export default {
    props:["store"],
    data() {
        return {
            errors: [],
            isError: false,
            isDelError: false,
            DelErrors: [],
        }
    },
    methods: {
        addArmGroup: async function() {
            if(this.$store.state.arm_group.length > 0) {
                await axios.post(this.$store.state.baseUrl + "addArmGroup", {
                        arm_group: this.$store.state.arm_group,
                        clinical_study_id: this.$store.state.clinicalStudy_id,
                    })
                    .then((response) => {
                        this.$store.state.arm_group = [];

                        this.$store.state.arm_group = response.data.arm_group;

                        // this.$store.state.arm_group_index = response.data.arm_group.id;

                    });
                }
                return true;
        },

        additem(type) {
            this.errors = [];
            this.isError = false;
            this.DelErrors = [];
            this.isDelError = false;
            if (type == 'arm_group') {
                if(this.$store.state.arm_group_label === "" || this.$store.state.arm_group_type === "") {
                    this.errors.push('Both Arm Group Label and Type Fields are required.');
                    this.isError = true;
                }
                if(this.isError == false) {
                    if (this.$store.state.arm_group_index == null && this.$store.state.arm_group_id == "") {
                        var armTempObj = {
                            id: this.$store.state.arm_group_id ? this.$store.state.arm_group_id : "",
                            arm_group_label: this.$store.state.arm_group_label ? this.$store.state.arm_group_label : "",
                            arm_group_type: this.$store.state.arm_group_type ? this.$store.state.arm_group_type : "",
                            description: this.$store.state.arm_group_description ? this.$store.state.arm_group_description : "",
                        };
                        this.$store.state.arm_group.push(armTempObj);
                    } else {
                        this.$store.state.arm_group[this.$store.state.arm_group_index] = {
                            id: this.$store.state.arm_group_id ? this.$store.state.arm_group_id : "",
                            arm_group_label: this.$store.state.arm_group_label ? this.$store.state.arm_group_label : "",
                            arm_group_type: this.$store.state.arm_group_type ? this.$store.state.arm_group_type : "",
                            description: this.$store.state.arm_group_description ? this.$store.state.arm_group_description : "",
                        };
                    }
            
                    this.$store.state.arm_group_index = null;
                    this.$store.state.arm_group_id = "";
                    this.$store.state.arm_group_label = "";
                    this.$store.state.arm_group_type = "";
                    this.$store.state.arm_group_description = "";
                    this.$bvModal.hide('add-arm-group')
                }
            }
        },

        openModal(index,data,type) {
            if (type == 'arm_group') {
                this.$store.state.arm_group_index = index;
                this.$store.state.arm_group_id = data.id ? data.id : "";
                this.$store.state.arm_group_label = data.arm_group_label;
                this.$store.state.arm_group_type = data.arm_group_type;
                this.$store.state.arm_group_description = data.description;
            }
        },

        removeRow(index,data,type) {
            this.DelErrors = [];
            this.isDelError = false;
            if (type == 'arm_group') {
                if (data.id && data.id != "") {
                    if(this.$store.state.intervention.length > 0) {
                        var armGroupCount = this.$store.state.intervention.filter(obj => obj.intervention_arm_group === data.id).length;
                        if(armGroupCount > 0) {
                            this.isDelError = true;
                            this.$bvModal.show('delete_record');
                        } else {
                            this.$store.state.arm_group.splice(index,1);
                        }
                    } else {
                        this.$store.state.arm_group.splice(index,1);
                    }
                } else {
                    this.$store.state.arm_group.splice(index,1);
                }
            }
        },

        closeModel() {
            this.isDelError = false;
        }
    },
};
</script>
