<template>
	<div class="bg-grey">
		<div class="text-center" v-if="isLoading == true">
			<b-spinner medium type="grow" class="mr-1"></b-spinner>
			<b-spinner medium type="grow" class="mr-1"></b-spinner>
			<b-spinner medium type="grow"></b-spinner>
		</div>
		<div class="row">
			<div class="col-12">
				
				<form-wizard
					:title="PageTitle"
					subtitle=""
					color="#00557e"
					@on-complete="onComplete"
					ref="wizard"
					:start-index="stepIndex"
				>
					<b-button  @click="gotToLastStep" class="facility-btn" style="margin-bottom: 20px;float: right;">Go To Last</b-button>
					
					<Location :store="this.$store" :clinicalStudyID="this.$route.params.clinicalStudyID"/>

					<Subcategories />

					<Eligibility :store="this.$store" />

					<MoreInformation :store="this.$store" :clinicalStudyID="this.$route.params.clinicalStudyID"/>

					<Condition :store="this.$store" :clinicalStudyID="this.$route.params.clinicalStudyID" />

					<Sponsors :store="this.$store" :clinicalStudyID="this.$route.params.clinicalStudyID" />

					<StudyDesign :store="this.$store" />

					<ResponsibleParty :store="this.$store" />

					<Contacts :store="this.$store" />

					<Arms :store="this.$store" />

					<Interventions :store="this.$store" />

					<References :store="this.$store" />


				</form-wizard>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import {
	additem,
	removeRow,
	OpenModal,
} from "../../util/function";
import Eligibility from "../ClinicalTrials/ClinicalTrialForm/Eligibility";
import MoreInformation from "../ClinicalTrials/ClinicalTrialForm/MoreInformation";
import Condition from "../ClinicalTrials/ClinicalTrialForm/ConditionNew";
import StudyDesign from "../ClinicalTrials/ClinicalTrialForm/StudyDesign";
import ResponsibleParty from "../ClinicalTrials/ClinicalTrialForm/ResponsibleParty";
import Contacts from "../ClinicalTrials/ClinicalTrialForm/Contacts";
import Arms from "../ClinicalTrials/ClinicalTrialForm/Arms";
import Interventions from "../ClinicalTrials/ClinicalTrialForm/Interventions";
import References from "../ClinicalTrials/ClinicalTrialForm/References";
import Sponsors from "../ClinicalTrials/ClinicalTrialForm/Sponsors";
import Location from "../ClinicalTrials/ClinicalTrialForm/Location";
import Subcategories from "../ClinicalTrials/ClinicalTrialForm/Subcategories";

export default {
	props: ["clinicalStudyID"],
	name: "AddClinicalTrials",

	data() {
		return {
			PageTitle: "Add",
			isLoading: false,

			eligibility: [],
			clinical_study: [],

			stepIndex:0
		};
	},

	mounted() {
		this.$refs.wizard.maxStep = 18;
		this.$refs.wizard.navigateToTab(0);

		if (!this.$route.params.clinicalStudyID || this.$route.params.clinicalStudyID == "") {
			this.PageTitle = "Add Clinical Trial";
			// this.$store.replaceState({});
		} else {
			this.PageTitle = "Edit Clinical Trial";
		}
		this.isLoading = true;
		axios
			.get(this.$store.state.baseUrl + "get_all_agencies")
			.then((response) => {
				if (response.data.code == 200) {
					this.$store.state.allAgencies = response.data.data;
				} else {
					this.$store.state.allAgencies = [];
				}
			});

		if (
			this.$route.params.clinicalStudyID &&
			this.$route.params.clinicalStudyID != ""
		) {
			axios
				.post(this.$store.state.baseUrl + "clinical_study", {
					clinical_study_id: this.$route.params.clinicalStudyID,
				})
				.then((response) => {
					if (response.data.code == 200) {
						(this.$store.state.eligibility_id = response.data.data
							.eligibility.id
							? response.data.data.eligibility.id
							: ""),
							(this.$store.state.criteria = response.data.data.eligibility
								.criteria
								? response.data.data.eligibility.criteria
								: ""),
							(this.$store.state.gender = response.data.data.eligibility
								.gender
								? response.data.data.eligibility.gender
								: ""),
							(this.$store.state.maximum_age = response.data.data.eligibility
								.maximum_age
								? response.data.data.eligibility.maximum_age
								: ""),
							(this.$store.state.minimum_age = response.data.data.eligibility
								.minimum_age
								? response.data.data.eligibility.minimum_age
								: ""),
							(this.$store.state.healthy_volunteers = response.data.data
								.eligibility.healthy_volunteers
								? response.data.data.eligibility.healthy_volunteers
								: ""),
							(this.$store.state.study_pop = response.data.data.eligibility
								.study_pop
								? response.data.data.eligibility.study_pop
								: ""),
							(this.$store.state.sampling_method = response.data.data
								.eligibility.sampling_method
								? response.data.data.eligibility.sampling_method
								: ""),
							(this.$store.state.clinicalStudy_id = response.data.data.id);
						this.$store.state.org_study_id = response.data.data.org_study_id;
						this.$store.state.brief_title = response.data.data.brief_title;
						this.$store.state.source = response.data.data.source;
						this.$store.state.verification_date = response.data.data.verification_date;
						this.$store.state.study_first_submitted =
							response.data.data.study_first_submitted;
						this.$store.state.study_first_submitted_qc =
							response.data.data.study_first_submitted_qc;
						this.$store.state.study_first_posted = response.data.data.study_first_posted;
						this.$store.state.last_update_submitted =
							response.data.data.last_update_submitted;
						this.$store.state.last_update_submitted_qc =
							response.data.data.last_update_submitted_qc;
						this.$store.state.last_update_posted = response.data.data.last_update_posted;
						this.$store.state.official_title = response.data.data.official_title;
						this.$store.state.brief_summary = response.data.data.brief_summary;
						this.$store.state.detailed_description =
							response.data.data.detailed_description;
						this.$store.state.overall_status = response.data.data.overall_status;
						this.$store.state.phase = response.data.data.phase;
						this.$store.state.study_type = response.data.data.study_type;
						this.$store.state.has_expanded_access =
							response.data.data.has_expanded_access;
						this.$store.state.why_stopped = response.data.data.why_stopped;
						this.$store.state.start_date = response.data.data.start_date;
						this.$store.state.completion_date = response.data.data.completion_date;
						this.$store.state.primary_completion_date =
							response.data.data.primary_completion_date;
						this.$store.state.number_of_arms = response.data.data.number_of_arms;
						this.$store.state.number_of_groups = response.data.data.number_of_groups;
						this.$store.state.enrollment = response.data.data.enrollment;
						this.$store.state.biospec_retention = response.data.data.biospec_retention;
						this.$store.state.biospec_description =
							response.data.data.biospec_description;
						this.$store.state.last_known_status = response.data.data.last_known_status;
						this.$store.state.acronym = response.data.data.acronym;
						this.$store.state.results_first_submitted =
							response.data.data.results_first_submitted;
						this.$store.state.results_first_submitted_qc =
							response.data.data.results_first_submitted_qc;
						this.$store.state.results_first_posted =
							response.data.data.results_first_posted;
						this.$store.state.disposition_first_submitted =
							response.data.data.disposition_first_submitted;
						this.$store.state.disposition_first_submitted_qc =
							response.data.data.disposition_first_submitted_qc;
						this.$store.state.disposition_first_posted =
							response.data.data.disposition_first_posted;
						this.$store.state.target_duration = response.data.data.target_duration;

						this.$store.state.study_design_info = response.data.data.study_design_infos;
						this.$store.state.arm_group = response.data.data.arm_groups;

						// this,this.intervention = response.data.data.interventions

						response.data.data.interventions.forEach((element) => {
							if (element.intervention_arm_groups.length > 0) {
								element.intervention_arm_groups.forEach((elem) => {
									var interventionTempObj = {
										id: element.id ? element.id : "",
										intervention_name: element.intervention_name
											? element.intervention_name
											: "",
										intervention_type: element.intervention_type
											? element.intervention_type
											: "",
										description: element.description ? element.description : "",
										intervention_arm_group: elem.id
											? elem.id
											: ""	
									};
									this.$store.state.intervention.push(interventionTempObj);
								});
							}
						});

						this.$store.state.oversight_info = response.data.data.oversight_infos;
						this.$store.state.condition = response.data.data.conditions;
						this.$store.state.overall_official = response.data.data.over_all_officials;
						this.$store.state.responsible_party = response.data.data.responsible_parties;
						this.$store.state.primary_outcome = response.data.data.primary_outcomes;
						this.$store.state.secondary_outcome = response.data.data.secondary_outcomes;
						(this.$store.state.other_outcome = response.data.data.other_outcomes),
							(this.$store.state.overall_contact = response.data.data.overall_contacts);
						(this.$store.state.overall_contact_backup =
							response.data.data.overall_contact_backups),
							(this.$store.state.reference = response.data.data.references),
							(this.$store.state.results_reference =
								response.data.data.results_references);
						this.$store.state.study_docs = response.data.data.study_docs;
						this.$store.state.expanded_access_info =
							response.data.data.expanded_access_infos;
						// this.$store.state.patient_data = response.data.data.patient_data
						response.data.data.patient_data.forEach((element) => {
							var patient_dataTempObj = {
								id: element.id ? element.id : "",
								ipd_description: element.ipd_description
									? element.ipd_description
									: "",
								ipd_time_frame: element.ipd_time_frame
									? element.ipd_time_frame
									: "",
								ipd_url: element.ipd_url ? element.ipd_url : "",
								ipd_access_criteria: element.ipd_access_criteria
									? element.ipd_access_criteria
									: "",
							};
							this.$store.state.patient_data.push(patient_dataTempObj);
							if (element.ipd_info_types.length > 0) {
								element.ipd_info_types.forEach((elem) => {
									var temp = {
										id: elem.id ? elem.id : "",
										ipd_info_type: elem.ipd_info_type ? elem.ipd_info_type : "",
										ipd_info_type_patient: element.ipd_description
											? element.ipd_description
											: "",
										patient_data_id: element.id ? element.id : ""
									};
									this.$store.state.ipd_info_type.push(temp);
								});
							}
						});
						response.data.data.provided_document_sections.forEach(
							(element) => {
								if (element.provided_documents.length > 0) {
									element.provided_documents.forEach((elem) => {
										this.$store.state.provided_document.push(elem);
									});
								}
							},
						);
						this.$store.state.participant_flow =
							response.data.data.participant_flows;
						this.$store.state.limitations_and_caveat =
							response.data.data.limitations_and_caveats;
						this.$store.state.point_of_contact =
							response.data.data.point_of_contacts;
						// this.$store.state.reported_event = response.data.data.reported_events
						if (response.data.data.reported_events.length > 0) {
							response.data.data.reported_events.forEach((element) => {
								var reported_eventsTempObj = {
									id: element.id ? element.id : "",
									description: element.description ? element.description : "",
									time_frame: element.time_frame ? element.time_frame : "",
								};
								this.$store.state.reported_event.push(reported_eventsTempObj);

								if (element.serious_events.length > 0) {
									element.serious_events.forEach((elem) => {
										var serious_eventsTemp = {
											id: elem.id ? elem.id : "",
											default_assessment: elem.default_assessment
												? elem.default_assessment
												: "",
											default_vocabulary: elem.default_vocabulary
												? elem.default_vocabulary
												: "",
											serious_event_reported_event: element.description
												? element.description
												: "",
										};
										this.$store.state.serious_event.push(serious_eventsTemp);
									});
								}

								if (element.other_events.length > 0) {
									element.other_events.forEach((elem) => {
										var other_eventsTemp = {
											id: elem.id ? elem.id : "",
											default_assessment: elem.default_assessment
												? elem.default_assessment
												: "",
											default_vocabulary: elem.default_vocabulary
												? elem.default_vocabulary
												: "",
											frequency_threshold: elem.frequency_threshold
												? elem.frequency_threshold
												: "",
											other_event_reported_event: element.description
												? element.description
												: "",
										};
										this.$store.state.other_event.push(other_eventsTemp);
									});
								}
							});
						}
						this.$store.state.certain_agreement =
							response.data.data.certain_agreements;
						this.$store.state.condition = response.data.data.conditions;
					}

				});
		}
		this.isLoading = false;
	},

	methods: {
		gotToLastStep: function(){
            this.$refs.wizard.changeTab(0,18)
        },
		async onComplete() {
			this.$router.push("/for-patients");
		},
		additem,
		removeRow,
		OpenModal,
	},

	components: {
		Subcategories,
		Eligibility,
		MoreInformation,
		Condition,
		StudyDesign,
		ResponsibleParty,
		Contacts,
		Arms,
		Interventions,
		References,
		Location,
		Sponsors,
	},
};
</script>
