<template>
  <div class="col-12 mt-2">
    <b-button v-b-toggle.collapse-3 class="accordion-btn listing-btn"
      >Primary Outcomes <i class="fa fa-chevron-down ml-3"></i
    ></b-button>
    <b-collapse id="collapse-3" class="mt-3">
      <b-card>
        <table class="table table-striped" v-if="data.primary_outcomes && data.primary_outcomes.length > 0">
          <tbody v-for="(outcome,index) in data.primary_outcomes" v-bind:key="outcome">
              <tr v-if="data.primary_outcomes.length > 0">
              <!-- <td class="table-data-heading">
                Arm Group Label:
              </td> -->
              <td class="table-data">
               {{ index+1 }}
              </td>
            </tr>
            <tr v-if="data.primary_outcomes.length > 0 && outcome.measure != '' ">
              <td class="table-data-heading">
                Measure:
              </td>
              <td class="table-data">
               {{ outcome.measure}}
              </td>
            </tr>
            <tr v-if="data.primary_outcomes.length > 0 && outcome.time_frame != '' ">
              <td class="table-data-heading">
                Time Frame:
              </td>
              <td class="table-data">
               {{ outcome.time_frame}}
              </td>
            </tr>
            <tr v-if="data.primary_outcomes.length > 0 && outcome.description != '' ">
              <td class="table-data-heading">
                Description:
              </td>
              <td class="table-data">
               {{ outcome.description}}
              </td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </b-collapse>
  </div>
</template>
<script>
export default {
	props: ["data"],
};
</script>