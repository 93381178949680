<template>
	<div>
		<b-modal id="all-locations" scrollable centered size="lg">
			<div class="header-title">All locations for {{ data.itn_id }}</div>
			<div class="total-locations pt-3">
				{{ data.locations.length }} locations
			</div>
			<div class="mt-2" v-for="(country,index) in countries" v-bind:key="index">
        <b-button v-b-toggle.collapse-10 class="location-accordion"
            ><i class="fa fa-map-marker mr-2"></i>{{ country }} ({{ allLocations.length}}) <i class="fa fa-chevron-down ml-3"></i
        ></b-button>
				<span v-for="(locationData,index2) in allLocations" v-bind:key="index2">
					<span v-if="locationData.country == country">
					
						<b-collapse id="collapse-10" class="mt-3">
							<b-card class="location-collapse">
								<div
									class="row d-flex justify-content-between align-items-center"
								>
									<div class="col-md-12 col-12">
										<div class="serial-number">
											{{ locationData.facility_name }}
										</div>
										<div class="address">
											{{ locationData.city }},
											{{ locationData.state }},
											{{ locationData.country }},
											{{ locationData.zip }}
										</div>
										<div class="status">{{ locationData.status }}</div>
										<div v-if="locationData.locationContactName != '' && locationData.locationContactPhone != '' && locationData.locationContactEmail != ''">
											<h6>Contact : </h6>
											<span>- </span>
											<span>{{ locationData.locationContactName}} ,</span>
											<span>{{ locationData.locationContactPhone}} ,</span>
											<span>{{ locationData.locationContactEmail}}</span>
										</div>
										<div v-if="locationData.locationInvestigatorName != '' && locationData.locationInvestigatorRole != '' ">
											<h6>Investigator : </h6>
											<span>- </span>
											<span>{{ locationData.locationInvestigatorName}} ,</span>
											<span>{{ locationData.locationInvestigatorRole}} ,</span>
										</div>
									</div>
									<!-- <div class="col-md-4 col-12 text--center" v-if="locationData.status == 'Recruiting'">
										<button class="location-btn">Put me in touch!</button>
									</div> -->
								</div>
							</b-card>
						</b-collapse>
					</span>
				</span>
			</div>
		</b-modal>
	</div>
</template>

<script>
export default {
	props: ["data"],
	name: "allLocations",

	data() {
		return {
			countries: [],
			allLocations: [],
			status: "",
			facility_name: "",
			subObj: {},
			tempObj: {},
		};
	},

	mounted() {
		if (this.data.locations.length > 0) {
			for (var i = 0; i < this.data.locations.length; i++) {
				let element = this.data.locations[i];
				let locationContact = this.data.locations[i].contacts;
				let locationInvestigator = this.data.locations[i].investigators;
				for (var j = 0; j < element.facilities.length; j++) {
					let facilities = element.facilities[j];
					for (var k = 0; k < facilities.address.length; k++) {
						let addresses = facilities.address[k];
						let temp_obj = {
							status: element.status,
							facility_name: facilities.facility_name,
							zip: addresses.zip,
							city: addresses.city,
							state: addresses.state,
							country: addresses.country,
							locationContactName: locationContact[j] ? locationContact[j].last_name : "",
							locationContactPhone: locationContact[j] ? locationContact[j].phone : "",
							locationContactEmail: locationContact[j] ? locationContact[j].email : "",
							locationInvestigatorName: locationInvestigator[j] ? locationContact[j].name : "",
							locationInvestigatorRole: locationInvestigator[j] ? locationContact[j].role : "",
						};
						this.allLocations.push(temp_obj);

						if (!this.countries.includes(addresses.country)) {
							this.countries.push(addresses.country);
						}
					}
				}
			}
		}
	},
};
</script>

<style scoped></style>
