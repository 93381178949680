<template>
	<div class="bg-grey">
		<div class="row">
			<div class="col-12">
				<div>
					<form-wizard
						title="Participant Flow"
						subtitle=""
						color="#00557e"
						@on-complete="onComplete"
					>
						<tab-content title="Prticipant Flow" icon="fa fa-file-excel-o">
						</tab-content>
						<tab-content title="Group" icon="fa fa-object-group"> </tab-content>
						<tab-content title="Group" icon="fa fa-legal"> </tab-content>
					</form-wizard>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ParticipantFlow",
};
</script>
