<template>
	<tab-content
		title="Eligibility"
		icon="fa fa-info-circle"
		:before-change="addEligibility"
	>
		<table class="table table-striped">
			<tbody>
				<tr>
					<td class="table-data-heading">
						Maximum Age eligible for Study:
					</td>
					<td class="table-description">
						<b-form-input
							name=""
							id=""
							type="number"
							class="trial-textarea"
							v-model="store.state.maximum_age"
						/>
					</td>
				</tr>
				<tr>
					<td class="table-data-heading">
						Minimum Age eligible for Study:
					</td>
					<td class="table-description">
						<b-form-input
							name=""
							id=""
							type="number"
							class="trial-textarea"
							v-model="store.state.minimum_age"

						/>
					</td>
				</tr>
				<tr>
					<td class="table-data-heading">
						Genders eligible for Study:
					</td>
					<td class="table-description">
						<select style="width: 100%; height: 100%" class="about-select form-control" v-model="store.state.gender">
							<option value="">Select Gender</option>
							<option value="Male">Male</option>
							<option value="Female">Female</option>
							<option value="All">All</option>
						</select>
					</td>
				</tr>
				<tr>
					<td class="table-data-heading">
						Accepts Healthy Volunteers:
					</td>
					<td class="table-description">
						<select style="width: 100%; height: 100%" class="about-select form-control" v-model="store.state.healthy_volunteers">
							<option value="">Select Option</option>
							<option value="Yes">Yes</option>
							<option value="No">No</option>
						</select>
					</td>
				</tr>
				<tr>
					<td class="table-data-heading">
						Sampling Method:
					</td>
					<td class="table-description">
						<b-form-textarea
							name=""
							id=""
							cols="30"
							rows="5"
							class="trial-textarea"
							v-model="store.state.sampling_method"
						></b-form-textarea>
					</td>
				</tr>
				<tr>
					<td class="table-data-heading">
						Study Population:
					</td>
					<td class="table-description">
						<b-form-textarea
							name=""
							id=""
							cols="30"
							rows="5"
							class="trial-textarea"
							v-model="store.state.study_pop"
						></b-form-textarea>
					</td>
				</tr>
				<tr>
					<td class="table-data-heading">
						Criteria:
					</td>
					<td class="table-description">
						<b-form-textarea
							name=""
							id=""
							cols="30"
							rows="5"
							class="trial-textarea"
							v-model="store.state.criteria"
						></b-form-textarea>
					</td>
				</tr>
			</tbody>
		</table>
	</tab-content>
</template>
<script>
import { additem, removeRow, OpenModal } from "../../../util/function";
import axios from "axios";
export default {
	props: ["store"],
	data() {
		return {
			eligibility: [],
			eligibility_id: null,
		};
	},
	methods: {
		addEligibility: async function() {
			var eligibiltyTemObj = {
				id: this.$store.state.eligibility_id
					? this.$store.state.eligibility_id
					: this.eligibility_id,
				criteria: this.$store.state.criteria ? this.$store.state.criteria : "",
				gender: this.$store.state.gender ? this.$store.state.gender : "",
				maximum_age: this.$store.state.maximum_age
					? this.$store.state.maximum_age
					: "",
				minimum_age: this.$store.state.minimum_age
					? this.$store.state.minimum_age
					: "",
				healthy_volunteers: this.$store.state.healthy_volunteers
					? this.$store.state.healthy_volunteers
					: "",
				study_pop: this.$store.state.study_pop
					? this.$store.state.study_pop
					: "",
				sampling_method: this.$store.state.sampling_method
					? this.$store.state.sampling_method
					: "",
			};
			if (
				eligibiltyTemObj.id != "" ||
				eligibiltyTemObj.criteria != "" ||
				eligibiltyTemObj.criteria != "" ||
				eligibiltyTemObj.criteria != "" ||
				eligibiltyTemObj.criteria != "" ||
				eligibiltyTemObj.criteria != "" ||
				eligibiltyTemObj.criteria != "" ||
				eligibiltyTemObj.criteria != ""
			) {
				this.eligibility.push(eligibiltyTemObj);
				await axios
					.post(this.$store.state.baseUrl + "addEligibility", {
						eligibility: this.eligibility,
					})
					.then((response) => {
						console.log("response", response.data.eligibility.message);
						this.eligibility = [];
						this.eligibility_id = response.data.eligibility.id;
						this.$store.state.eligibility_id = response.data.eligibility.id;
					});
			}
			return true;
		},
		additem,
		removeRow,
		OpenModal,
	},
};
</script>
